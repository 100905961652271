import {
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	inject,
	Injector,
	Input,
	OnChanges,
	SimpleChanges,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { ComponentRegistry, Tag } from './component-registry';
import { Statement } from '../model/statements/statement';
import { AbstractStatementComponent } from '../statement/shared/statement-template/abstract-statement.component';
import { TOKEN_STATEMENT_ID } from './statement-node-registry.service';
import { TOKEN_TAGS } from './argument-node-registry.service';
import { TOKEN_COMPONENT_CONFIG } from '../statement/shared/statement-template/abstract-statement-edit.component';
import { first, map, of } from 'rxjs';
import { selectStatement } from '../store/statement/statement-selectors';
import { Store } from '@ngrx/store';

export interface StatementComponentConfig {
	collapseArguments?: boolean;
	/**If embedded then the save action is provided by the parent component*/
	embedded?: boolean;
	showArgumentBar?: boolean;
	argumentBarSelectedChild?: string;
	createType?: 'StatementDefault' | string;
}

const defaultStatementConfig: StatementComponentConfig = {
	collapseArguments: true,
	embedded: false,
	showArgumentBar: true,
	argumentBarSelectedChild: undefined,
};

@Component({
	selector: 'dynamic-statement-component',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicStatementComponent implements OnChanges {
	@Input() statementId: string;
	@Input() tags: Tag[] = [];
	@Input() config: StatementComponentConfig;
	private viewContainerRef = inject(ViewContainerRef);
	private injector = inject(Injector);
	private store = inject(Store);

	private componentRef: ComponentRef<AbstractStatementComponent<Statement>>;

	/**Recreate component on the rout changes*/
	ngOnChanges(changes: SimpleChanges): void {
		this.viewContainerRef.clear();
		this.createComponent();
	}

	/**See https://angular.io/guide/dynamic-component-loader
	 *
	 */
	private createComponent() {
		(this.statementId
			? this.store.select(selectStatement(this.statementId)).pipe(
					first(),
					map((a) => a.type)
			  )
			: of(this.config.createType)
		).subscribe((type) => {
			const componentClass: Type<AbstractStatementComponent<Statement>> =
				ComponentRegistry.getComponent(type, this.tags) as Type<
					AbstractStatementComponent<Statement>
				>;

			if (componentClass) {
				const newInjector = Injector.create({
					providers: [
						{
							provide: TOKEN_STATEMENT_ID,
							useValue: this.statementId,
						},
						{ provide: TOKEN_TAGS, useValue: this.tags },
						{
							provide: TOKEN_COMPONENT_CONFIG,
							useValue: {
								...defaultStatementConfig,
								...this.config,
							},
						},
					],
					parent: this.injector,
				});
				this.componentRef = this.viewContainerRef.createComponent<
					AbstractStatementComponent<Statement>
				>(componentClass, {
					injector: newInjector,
				});
			} else {
				throw Error(
					`DynamicComponent: cannot find component for "${type}" with tags [${this.tags}]`
				);
			}
		});
	}

	/**@returns underlying StatementComponent*/
	getComponent(): AbstractStatementComponent<Statement> {
		return this.componentRef.instance;
	}
}
