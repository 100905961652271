import { Injectable } from '@angular/core';
import { filter, first, map, Observable, shareReplay, switchMap } from 'rxjs';
import {
	ArgumentDefinition,
	TermPremise,
} from '../../model/arguments/argument-definition';
import {
	ArgumentComponentState,
	ArgumentComponentStore,
} from '../argument-template/store/argument-component-store';
import {
	argumentActions,
	OnStatementIds,
} from '../../store/argument/argument-actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EntitySelectors, UpdateStr } from '@ngrx/entity/src/models';
import { Utils } from '../../utils/utils';
import { ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Statement } from '../../model/statements/statement';
import { ModeTags } from '../../services/component-registry';

export interface TableTermItem extends TermPremise {
	updatedStatement?: Statement;
}

export interface ArgumentDefinitionState
	extends ArgumentComponentState<ArgumentDefinition> {
	premises: EntityState<TableTermItem>;
}

@UntilDestroy()
@Injectable()
export class ArgumentDefinitionComponentStore extends ArgumentComponentStore<
	ArgumentDefinition,
	ArgumentDefinitionState
> {
	private entityAdapter: EntityAdapter<TableTermItem>;
	private entitySelectors: EntitySelectors<
		TableTermItem,
		ArgumentDefinitionState
	>;

	constructor() {
		super();

		this.entityAdapter = createEntityAdapter<TableTermItem>({
			selectId: (item) => item.term,
		});
		this.entitySelectors = this.entityAdapter.getSelectors(
			(state) => state.premises
		);
	}

	override initStore(argument: ArgumentDefinition, mode: ModeTags) {
		this.setState({
			initArgument: argument,
			argument,
			mode,
			premises: this.entityAdapter.getInitialState(),
		});
		this.setPremisesAll(argument.premises.map((item) => ({ ...item })));
	}

	//**** Selectors
	premises$ = this.select((state) => this.entitySelectors.selectAll(state));
	premiseMap$ = this.select((s) => this.entitySelectors.selectEntities(s));
	premiseByTerm$ = (term: string) =>
		this.select(this.premiseMap$, (premises) => premises[term]);
	premiseStatement$ = (term: string) =>
		this.select(this.premiseByTerm$(term), (p) => p.updatedStatement);
	snapshot$ = this.select(
		this.argument$,
		this.premises$,
		(argument, premises) => ({ argument, premises })
	);
	termPremises$: Observable<TermPremise[]> = this.select(
		this.premises$,
		(premises) =>
			premises
				.filter(({ updatedStatement }) => Boolean(updatedStatement))
				.map(({ updatedStatement, ...rest }) => ({
					...rest,
					premiseId: updatedStatement?.id,
				}))
	);
	definition$ = this.select(this.argument$, (arg) => arg.definition);
	/**Combines $argument and premises$*/
	argumentDefinition$: Observable<ArgumentDefinition> = this.select(
		this.argument$,
		this.termPremises$,
		(argument, premises) => ({ ...argument, premises })
	);

	//**** Updaters
	readonly updateDefinition = this.updater((state, definition: string) => ({
		...state,
		argument: { ...state.argument, definition },
	}));
	readonly setPremisesAll = this.updater(
		(state, premises: TableTermItem[]) => ({
			...state,
			premises: this.entityAdapter.setAll(
				premises,
				state.premises || this.entityAdapter.getInitialState()
			),
		})
	);
	readonly addPremisesMany = this.updater(
		(state, premises: TableTermItem[]) => ({
			...state,
			premises: this.entityAdapter.addMany(premises, state.premises),
		})
	);
	readonly updatePremise = this.updater(
		(state, premise: UpdateStr<TableTermItem>) => ({
			...state,
			premises: this.entityAdapter.updateOne(premise, state.premises),
		})
	);
	readonly removePremise = this.updater((state, term: string) => ({
		...state,
		premises: this.entityAdapter.removeOne(term, state.premises),
	}));
	readonly removePremiseMany = this.updater((state, terms: string[]) => ({
		...state,
		premises: this.entityAdapter.removeMany(terms, state.premises),
	}));

	readonly updatePremiseStatement = this.updater(
		(state, update: { term: string; updatedStatement: Statement }) => ({
			...state,
			premises: this.entityAdapter.updateOne(
				{
					id: update.term,
					changes: { updatedStatement: update.updatedStatement },
				},
				state.premises
			),
		})
	);

	//**** Effects

	override save(): Observable<ArgumentDefinition> {
		return this.snapshot$.pipe(
			first(),
			switchMap(({ argument, premises }) => {
				// empty premises are ignored
				const nonEmptyPremises = premises.filter(
					({ updatedStatement }) => Boolean(updatedStatement)
				);

				const newItems = nonEmptyPremises.filter(
					(t) => !t.updatedStatement.id
				);
				const newPremises = new Map(
					newItems.map((t) => [t.term, t.updatedStatement])
				);
				argument = {
					...argument,
					premises: nonEmptyPremises.map(
						({ updatedStatement, ...rest }) => ({
							...rest,
							premiseId: updatedStatement?.id,
						})
					),
				};
				if (newPremises.size > 0) {
					const actionId = Utils.generateId();
					//register listener before dispatch
					const listener = this.storeActions.pipe(
						untilDestroyed(this),
						ofType(
							argumentActions.addSuccess,
							argumentActions.updateSuccess
						),
						filter((action) => action.actionId == actionId),
						map(({ argument }) => argument as ArgumentDefinition),
						shareReplay(1)
					);
					listener.subscribe(); //make it hot
					const updateFn: OnStatementIds<ArgumentDefinition> = (
						arg,
						updated
					) => {
						updated.forEach((updatedStatement, term: string) => {
							this.updatePremise({
								id: term,
								changes: {
									updatedStatement,
								},
							});
						});
						const premises = arg.premises.map((p) =>
							updated.has(p.term)
								? { ...p, premiseId: updated.get(p.term).id }
								: p
						);
						return { ...arg, premises };
					};
					if (argument.id)
						this.store.dispatch(
							argumentActions.update({
								argument,
								actionId,
								updateFn,
								newPremises,
							})
						);
					else
						this.store.dispatch(
							argumentActions.add({
								argument,
								actionId,
								updateFn,
								newPremises,
							})
						);
					return listener;
				} else return this.saveWhenPremisesHasIds();
			})
		);
	}

	saveWhenPremisesHasIds(): Observable<ArgumentDefinition> {
		return this.argumentDefinition$.pipe(
			untilDestroyed(this),
			filter((arg) => arg.premises.every((p) => p.premiseId)),
			first(),
			switchMap((argument) => {
				const actionId = Utils.generateId();
				// listen for success
				const listener = this.storeActions.pipe(
					untilDestroyed(this),
					ofType(
						argumentActions.addSuccess,
						argumentActions.updateSuccess
					),
					filter((action) => action.actionId == actionId),
					map(({ argument }) => argument as ArgumentDefinition),
					shareReplay(1)
				);
				listener.subscribe(); //make it hot
				if (argument.id)
					this.store.dispatch(
						argumentActions.update({ argument, actionId })
					);
				else
					this.store.dispatch(
						argumentActions.add({ argument, actionId })
					);
				return listener;
			})
		);
	}
}
