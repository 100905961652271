import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { BehaviorSubject, first, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { settingsActions } from '../../store/settings/settings-actions';
import { openaiActions } from '../../store/openai/openai-actions';
import { selectSettingsOpenAi } from '../../store/settings/settings-selectors';
import { OpenaiService } from '../../services/openai/openai.service';
import { prompts } from 'src/app/services/openai/openai-prompts';

@Component({
	selector: 'dt-open-ai-play',
	templateUrl: './open-ai-play.component.html',
	styleUrls: ['./open-ai-play.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenAiPlayComponent implements OnInit {
	prompts = prompts;
	private openAiService = inject(OpenaiService);
	private store = inject(Store);
	queryControl = new FormControl('');
	response$ = new BehaviorSubject<string>('');
	loadingTrigger$ = new Subject();

	ngOnInit(): void {
		this.store
			.select(selectSettingsOpenAi)
			.pipe(first())
			.subscribe(({ defaultPrompt }) =>
				this.queryControl.setValue(defaultPrompt)
			);
	}

	async onSubmit(key: string) {
		this.loadingTrigger$.next(undefined);
		this.store.dispatch(openaiActions.loadFromOpenai());
		this.store.dispatch(
			settingsActions.updateOpenAi({ settings: { defaultResponse: '' } })
		);
		this.response$.next('');
		const response = await this.openAiService.query(
			this.queryControl.value,
			key
		);
		this.response$.next(response);
		this.store.dispatch(openaiActions.loadFromOpenaiCompleted());
	}

	makeDefaultResponse() {
		const defaultResponse = this.response$.value;
		this.store.dispatch(
			settingsActions.updateOpenAi({
				settings: { defaultResponse },
			})
		);
	}
}
