import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { globalActions } from './store/shared/actions';
import { firestoreActions } from './store/firestore/firestore-actions';
import { LocalStorageService } from './services/local-storage-service';
import { selectRootStatement } from './store/statement/statement-selectors';
import { combineLatest, map, Observable } from 'rxjs';
import { FirestoreService } from './services/firebase/firestore-service';
import { settingsActions } from './store/settings/settings-actions';
import { URLConst } from './core/routes';
import packageJson from '../../package.json';

@Component({
	selector: 'debatt-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	URLConst = URLConst;
	enableSaving$: Observable<boolean>;
	enableSavingFirestore$: Observable<boolean>;
	version: string;
	private store = inject(Store);
	private localStorageService = inject(LocalStorageService);
	private firestoreService = inject(FirestoreService);

	constructor() {
		this.store.dispatch(settingsActions.loadFromLocalStorage());
		this.localStorageService.autoloadIfEnabled();
		this.enableSaving$ = this.store
			.select(selectRootStatement)
			.pipe(map(Boolean));
		this.enableSavingFirestore$ = combineLatest([
			this.enableSaving$,
			this.firestoreService.canSaveDebate(),
		]).pipe(map(([one, two]) => one && two));
		this.version = packageJson.version;
	}

	saveToLocalStorage() {
		this.store.dispatch(globalActions.saveToLocalStorage());
	}

	saveToFirestore() {
		this.store.dispatch(firestoreActions.saveToFirestore());
	}

	clear() {
		this.store.dispatch(globalActions.reset());
	}
}
