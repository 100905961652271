import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { opinionActions } from './opinion-actions';
import { OpinionsEnum } from '../../model/descriptions/schema/opinion.configs.schema';
import { globalActions } from '../shared/actions';
import { firestoreActions } from '../firestore/firestore-actions';

export interface Opinion {
	id: string;
	votes: { [key in OpinionsEnum]?: number };
}

const EMPTY_OPTION: Omit<Opinion, 'id'> = { votes: {} };

export type OpinionState = EntityState<Opinion>;
export const opinionAdapter: EntityAdapter<Opinion> =
	createEntityAdapter<Opinion>();
export const opinionInitialState: OpinionState =
	opinionAdapter.getInitialState();

export const opinionReducer = createReducer(
	opinionInitialState,
	on(opinionActions.voteUp, (state, { statementId, opinion }) => {
		let entity: Opinion = state.entities[statementId];
		if (!entity) entity = { ...EMPTY_OPTION, id: statementId };
		const newValue = (entity.votes[opinion] ?? 0) + 1;
		const newEntity = {
			...entity,
			votes: {
				...entity.votes,
				[opinion]: newValue,
			},
		};
		console.log(`new vote for ${statementId}: ${newEntity.votes}`);
		return opinionAdapter.upsertOne(newEntity, state);
	}),
	on(opinionActions.voteDown, (state, { statementId, opinion }) => {
		let entity: Opinion = state.entities[statementId];
		if (!entity) entity = { ...EMPTY_OPTION, id: statementId };
		entity.votes[opinion] = (entity.votes[opinion] ?? 0) - 1;
		return opinionAdapter.upsertOne(entity, state);
	}),

	on(
		globalActions.loadFromLocalStorageCompleted,
		globalActions.loadFile,
		(state, { newState }) => {
			return newState.opinions;
		}
	),
	on(firestoreActions.loadFromFirestoreCompleted, (state, { document }) => {
		return document.state.opinions;
	})
);
