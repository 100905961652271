import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	BehaviorSubject,
	EMPTY,
	filter,
	map,
	Observable,
	switchMap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectRootStatementId } from '../../store/statement/statement-selectors';
import { SelectStatementComponent } from '../../statement/shared/select-statement/select-statement.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { statementActions } from '../../store/statement/statement-actions';

// @ts-ignore
@UntilDestroy()
@Component({
	selector: 'dt-top-view-component',
	templateUrl: './top-view.component.html',
	styleUrls: ['./top-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopViewComponent implements OnInit, AfterViewInit {
	/**The root statement*/
	public statementIds$: Observable<string[]>;
	private selectComponent$ = new BehaviorSubject<SelectStatementComponent>(
		undefined
	);

	@ViewChild('selectStatement', {
		read: SelectStatementComponent,
		static: false,
	})
	set selectComponent(component: SelectStatementComponent) {
		this.selectComponent$.next(component);
	}

	private store = inject(Store);

	ngOnInit(): void {
		this.statementIds$ = this.store
			.select(selectRootStatementId)
			.pipe(map((st) => (st ? [st] : [])));
	}

	ngAfterViewInit(): void {
		this.selectComponent$
			.pipe(
				switchMap((c) => {
					if (c) return c.getStatement$();
					else return EMPTY;
				}),
				filter((st) => !!st?.id),
				map((st) => st.id)
			)
			.subscribe((stId) =>
				this.store.dispatch(
					statementActions.setRootStatement({ rootStatementId: stId })
				)
			);
	}
}
