import {
	ChangeDetectionStrategy,
	Component,
	ErrorHandler,
	inject,
	Inject,
	Injectable,
	NgZone,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBar,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { globalActions } from '../../store/shared/actions';
import { settingsActions } from '../../store/settings/settings-actions';

/**
 * Not used right now. Can be removed.
 */
@Component({
	selector: 'dt-error-snack-bar',
	templateUrl: './error-snack-bar.component.html',
	styleUrls: ['./error-snack-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorSnackBarComponent implements OnInit, OnDestroy {
	static isActive = false;
	private store = inject(Store);

	constructor(
		public sbRef: MatSnackBarRef<ErrorSnackBarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any
	) {}

	ngOnInit(): void {
		ErrorSnackBarComponent.isActive = true;
	}

	reset() {
		this.store.dispatch(globalActions.reset());
		this.store.dispatch(
			settingsActions.set({ settings: { autoload: undefined } })
		);
		this.sbRef.dismiss();
	}

	close() {
		this.sbRef.dismiss();
	}

	ngOnDestroy() {
		ErrorSnackBarComponent.isActive = false;
	}
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private _snackBar: MatSnackBar, private zone: NgZone) {}

	handleError(error) {
		console.error(error);
		if (!ErrorSnackBarComponent.isActive)
			this.zone.run(() => {
				this._snackBar.openFromComponent(ErrorSnackBarComponent, {
					data: error.message ?? error,
					panelClass: ['message'],
					duration: 5000,
				});
			});
	}
}
