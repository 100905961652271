import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { ArgumentAnalogy } from '../../model/arguments/argumentAnalogy';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';

/**
 * See {@link https://medium.com/angular-in-depth/building-interactive-lists-with-the-new-angular-7-drag-and-drop-tool-5f2402f8cb27}
 * See {@link https://material.angular.io/cdk/drag-drop/overview}
 */
@RegisterDynamicComponent({
	dataType: 'ArgumentAnalogy',
	title: 'Analogy',
	tags: ['argument', 'support'],
})
@Component({
	selector: 'debatt-argument-analogy',
	templateUrl: './argument-analogy.component.html',
	styleUrls: ['./argument-analogy.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentAnalogyComponent extends ArgumentAbstractComponent<
	any,
	ArgumentAnalogy
> {}

@RegisterDynamicComponent({
	dataType: 'ArgumentAnalogy',
	tags: ['label'],
})
@Component({
	selector: 'argument-analogy-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'darkblue'"
			[icon]="'done_all'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentAnalogyLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
