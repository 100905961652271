import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class RouterEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);

	/*
	navigate$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ROUTER_NAVIGATION),
				tap(action =>{
					console.log(action);
				})
			);
		}, {dispatch: false}
	);
*/
}
