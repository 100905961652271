import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRouteStatementId } from '../../store/router/router.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'debatt-edit-statement',
	templateUrl: './edit-statement.component.html',
	styleUrls: ['./edit-statement.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStatementComponent implements OnInit {
	statementId$;
	private store = inject(Store);

	ngOnInit(): void {
		this.statementId$ = this.store
			.select(selectRouteStatementId)
			.pipe(untilDestroyed(this));
	}
}
