import { createSelector } from '@ngrx/store';
import {
	selectStatement,
	selectStatementState,
} from '../statement/statement-selectors';
import {
	selectArgumentEntities,
	selectArgumentState,
} from '../argument/argument-selectors';
import { FirestoreDebateDocument } from '../../model/firestore/firestore';
import { selectOpinionState } from '../opinions/opinion-selectors';
import {
	selectFirestoreDescription,
	selectFirestoreLoading,
	selectFirestoreState,
	selectFirestoreTitle,
} from '../firestore/firestore-selectors';
import { selectOpenaiLoading } from '../openai/openai-selectors';
import { Argument } from '../../model/arguments/argument';

export const selectStatementWithArguments = (id) =>
	createSelector(
		selectStatement(id),
		selectArgumentEntities,
		(statement, argumentEntities) => ({
			statement,
			arguments: statement.args.map((id) => argumentEntities[id]),
		})
	);

export const selectArgumentsForStatementId = (statementId) =>
	createSelector(
		selectStatement(statementId),
		selectArgumentEntities,
		(statement, argumentEntities): Argument[] =>
			statement?.args.map((id) => argumentEntities[id])
	);

export const selectArgumentsForStatement = (statement) =>
	createSelector(selectArgumentEntities, (argumentEntities): Argument[] =>
		statement.arguments.map((id) => argumentEntities[id])
	);

export const selectFirestoreDocument = createSelector(
	// (state: DebattState) => state,
	selectStatementState,
	selectArgumentState,
	selectOpinionState,
	selectFirestoreState,
	(statements, args, opinions, firestore): FirestoreDebateDocument => {
		const owner = firestore.owner;
		const access = firestore.access;
		const rootId = statements.rootStatementId;
		const title = firestore.title ?? statements.entities[rootId]?.title;
		const description =
			firestore.description ?? statements.entities[rootId]?.description;
		const document = {
			owner,
			access,
			title,
			description,
			state: {
				statements,
				args,
				opinions,
			},
		};
		return document;
	}
);

/**
 * Excludes firestore.docId and loading. So the changes only to emitted when title or description or
 * arg/statements/opinions change
 */
export const selectFirestoreDocumentForAutosave = createSelector(
	// (state: DebattState) => state,
	selectStatementState,
	selectArgumentState,
	selectOpinionState,
	selectFirestoreTitle,
	selectFirestoreDescription,
	(statements, args, opinions, title, description) => {
		const document = {
			title,
			description,
			state: {
				statements,
				args,
				opinions,
			},
		};
		return document;
	}
);

/**Any of firestore or opeanai operation in progress*/
export const selectLoading = createSelector(
	selectFirestoreLoading,
	selectOpenaiLoading,
	(s1, s2) => s1 || s2
);
