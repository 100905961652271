import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../../services/firebase/user-service';
import { URLConst } from '../../core/routes';

@Component({
	selector: 'dt-profile-menu',
	templateUrl: './profile-menu.component.html',
	styleUrls: ['./profile-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit {
	protected readonly URLConst = URLConst;
	// @HostBinding('style.display')
	// private display = 'inline-block';
	loading$ = new BehaviorSubject<boolean>(false);
	private userService = inject(UserService);
	private auth = inject(Auth);
	public user$: Observable<User>;

	ngOnInit(): void {
		this.user$ = this.userService.user$;
	}

	async login() {
		this.loading$.next(true);
		await this.userService.login();
		this.loading$.next(false);
	}

	async logout() {
		await this.userService.logout();
	}
}
