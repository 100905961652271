import { Argument } from '../../../model/arguments/argument';
import { Injectable } from '@angular/core';
import { filter, first, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Statement } from '../../../model/statements/statement';
import {
	ArgumentComponentState,
	ArgumentComponentStore,
} from './argument-component-store';
import { argumentActions } from '../../../store/argument/argument-actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Utils } from '../../../utils/utils';
import { ofType } from '@ngrx/effects';
import { statementActions } from '../../../store/statement/statement-actions';

type ArgumentWithPremise = Argument & { premise?: string };

export interface ArgumentSinglePremiseComponentState<
	T extends ArgumentWithPremise
> extends ArgumentComponentState<T> {
	premiseSt?: Statement;
}

/**
 * A component store that can save a premise before saving an argument.
 */
@UntilDestroy()
@Injectable()
export class ArgumentSinglePremiseComponentStore<
	A extends ArgumentWithPremise
> extends ArgumentComponentStore<A, ArgumentSinglePremiseComponentState<A>> {
	//**** Selectors
	premise$ = this.select(this.argument$, (arg) => arg.premise);
	premiseStatement$ = this.select((state) => state.premiseSt);
	snapshot$ = this.select(
		this.argument$,
		this.premiseStatement$,
		(argument: A, premise) => ({ argument, premise })
	);
	prepareArgument$ = this.select(
		this.argument$,
		this.premiseStatement$,
		(argument: A, premise) =>
			({
				...argument,
				premise: premise.id,
			} as A)
	);
	//**** Updaters
	readonly updatePremise = this.updater((state, premise: string) => ({
		...state,
		argument: { ...state.argument, premise },
	}));

	readonly updatePremiseStatement = this.updater(
		(state, premiseSt: Statement) => ({
			...state,
			argument: { ...state.argument, premise: premiseSt?.id },
			premiseSt,
		})
	);

	//**** Effects
	override save(): Observable<A> {
		return this.snapshot$.pipe(
			first(),
			switchMap(({ argument, premise }) => {
				if (!premise.id) {
					//first save the premise
					const addActionId = Utils.generateId();
					//register listener before dispatch
					const listener = this.storeActions.pipe(
						untilDestroyed(this),
						ofType(statementActions.addSuccess),
						filter(({ actionId }) => actionId === addActionId),
						switchMap(({ statement }) => {
							this.updatePremiseStatement(statement);
							// when premise saved, save the argument
							return this.saveWhenPremisesHasIds();
						}),
						shareReplay(1)
					);
					listener.subscribe(); //make it hot
					this.store.dispatch(
						statementActions.add({
							statement: premise,
							actionId: addActionId,
						})
					);
					return listener;
				} else {
					this.store.dispatch(
						statementActions.update({
							update: {
								id: premise.id,
								changes: premise,
							},
						})
					);
					return this.saveWhenPremisesHasIds();
				}
			})
		);
	}

	saveWhenPremisesHasIds(): Observable<A> {
		return this.prepareArgument$.pipe(
			untilDestroyed(this),
			filter((arg) => Boolean(arg.premise)),
			first(),
			switchMap((argument) => {
				const actionId = Utils.generateId();
				// listen for success
				const listener = this.storeActions.pipe(
					untilDestroyed(this),
					ofType(
						argumentActions.addSuccess,
						argumentActions.updateSuccess
					),
					filter((action) => action.actionId == actionId),
					map(({ argument }) => argument as A),
					shareReplay(1)
				);
				listener.subscribe(); //make it hot
				if (argument.id)
					this.store.dispatch(
						argumentActions.update({ argument, actionId })
					);
				else
					this.store.dispatch(
						argumentActions.add({ argument, actionId })
					);
				return listener;
			})
		);
	}
}
