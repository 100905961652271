import {
	Directive,
	inject,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSettingsShowActionLabels } from '../../store/settings/settings-selectors';

/**
 * Display the component if settings.theme !== 'compact'
 */
@Directive({
	selector: '[dtIfExpanded]',
})
export class IfExpandedDirective {
	private store = inject(Store);
	private templateRef = inject(TemplateRef);
	private viewContainer = inject(ViewContainerRef);
	private hasView = false;

	constructor() {
		const isExpanded$ = this.store.select(selectSettingsShowActionLabels);
		isExpanded$.subscribe((exp) => {
			if (exp && !this.hasView) {
				this.viewContainer.createEmbeddedView(this.templateRef);
				this.hasView = true;
			} else if (!exp && this.hasView) {
				this.viewContainer.clear();
				this.hasView = false;
			}
		});
	}
}
