import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PathService } from '../../services/path.service';
import { map, Observable, switchMap } from 'rxjs';
import { Statement } from '../statements/statement';
import { Params } from '@angular/router';
import { ID } from '../arguments/argument';

@Injectable({ providedIn: 'root' })
@Pipe({
	name: 'pathTo',
	pure: true,
})
export class PathToPipe implements PipeTransform {
	constructor(private routeService: PathService) {}

	transform(
		statementId: string | Observable<string | ID>,
		...tailIds: string[]
	): Observable<string[]> {
		let result: Observable<string[]>;
		if (typeof statementId === 'string')
			result = this.routeService.getPathTo(statementId);
		else
			result = statementId.pipe(
				switchMap((o) =>
					typeof o === 'string'
						? this.routeService.getPathTo(o)
						: this.routeService.getPathTo(o.id)
				)
			);
		// if (excludeLast)
		// 	result = result.pipe(map(r => r.slice(0, -1)));
		return result;
	}
}

@Pipe({
	name: 'queryPathTo',
	pure: true,
})
export class QueryParamPathToPipe implements PipeTransform {
	constructor(private pathToPipe: PathToPipe) {}

	transform(
		statementId: string | Observable<string | Statement>,
		...tailIds: string[]
	): Observable<Params> {
		return this.pathToPipe
			.transform(statementId, ...tailIds)
			.pipe(map((path) => ({ path })));
	}
}
