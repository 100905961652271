import { createReducer, on } from '@ngrx/store';
import { settingsActions } from '../settings/settings-actions';

export type SettingsTheme = 'compact' | 'expanded';

export enum OpenAiModels {
	GPT35_TURBO = 'gpt-3.5-turbo',
	TEXT_DAVINCI = 'text-davinci-003',
	GPT3_ADA = 'ada',
	GPT3_DAVINCI = 'davinci',
	GPT_4 = 'gpt-4',
	TEXT_ADA = 'text-ada-001',
	TEXT_CURIE = 'text-curie-001',
}

export type OpenAiEndpoint = 'completion' | 'chat' | 'fine-tunes';

interface OpenAiModelConfig {
	name: string;
	endpoint: OpenAiEndpoint /*| OpenAiEndpoint[]*/;
}

export const openAiModelConfigs: {
	[key in OpenAiModels]: OpenAiModelConfig;
} = {
	[OpenAiModels.GPT35_TURBO]: { name: 'gpt-3.5-turbo', endpoint: 'chat' },
	[OpenAiModels.TEXT_DAVINCI]: {
		name: 'text-davinci-003',
		endpoint: 'completion',
	},
	[OpenAiModels.GPT3_DAVINCI]: {
		name: 'davinci',
		endpoint: 'fine-tunes',
	},
	[OpenAiModels.GPT3_ADA]: { name: 'ada', endpoint: 'fine-tunes' },
	[OpenAiModels.TEXT_ADA]: {
		name: 'text-ada-001',
		endpoint: 'completion',
	},
	[OpenAiModels.TEXT_CURIE]: {
		name: 'text-curie-001',
		endpoint: 'completion',
	},
	[OpenAiModels.GPT_4]: { name: 'gpt-4', endpoint: 'chat' },
};

export type OpenAiSettings = {
	openAiApiKey: string;
	organization: string;
	defaultPrompt: string;
	defaultResponse: string;
	model: OpenAiModels;
};

export interface SettingsState {
	theme: SettingsTheme;
	autoload?: string;
	autosaveFirestore: boolean;
	/**
	 * @deprecated Use {@link developer.openAi}
	 */
	openAiApiKey?: string;
	developer?: {
		openAi?: OpenAiSettings;
	};
}

export const initialOpenaiState = {
	openAiApiKey: '',
	organization: '',
	defaultResponse: '',
	defaultPrompt: '',
	model: OpenAiModels.GPT35_TURBO,
};
export const settingsInitialState: SettingsState = {
	theme: 'compact',
	autosaveFirestore: false,
	developer: {
		openAi: initialOpenaiState,
	},
};

export const settingsReducer = createReducer(
	settingsInitialState,
	on(
		settingsActions.setAll,
		(state, { settings }): SettingsState => settings
	),
	on(
		settingsActions.set,
		(state, { settings }): SettingsState => ({ ...state, ...settings })
	),
	on(
		settingsActions.setOpenAi,
		(state, { settings }): SettingsState => ({
			...state,
			developer: { ...state.developer, openAi: settings },
		})
	),
	on(
		settingsActions.updateOpenAi,
		(state, { settings }): SettingsState => ({
			...state,
			developer: {
				...state.developer,
				openAi: { ...state.developer?.openAi, ...settings },
			},
		})
	),
	on(
		settingsActions.disableAutoloadFor,
		(state, { id }): SettingsState =>
			state.autoload === id ? { ...state, autoload: undefined } : state
	),

	on(
		settingsActions.loadFromLocalStorageCompleted,
		(state, { settings }) => settings
	)
);
