import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { first, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TOKEN_STATEMENT_ID } from '../../../services/statement-node-registry.service';
import { selectStatement } from '../../../store/statement/statement-selectors';
import { OpenaiService } from '../../../services/openai/openai.service';
import { prompts } from 'src/app/services/openai/openai-prompts';

@Component({
	selector: 'dt-openai-button',
	templateUrl: './openai-button.component.html',
	styleUrls: ['./openai-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenaiButtonComponent {
	prompts = prompts;
	loadingTrigger$ = new Subject();
	private store = inject(Store);
	private statementId = inject(TOKEN_STATEMENT_ID);
	private openAiService = inject(OpenaiService);
	queryAI(promptId: string) {
		this.loadingTrigger$.next(undefined);
		this.store
			.select(selectStatement(this.statementId))
			.pipe(first())
			.subscribe((statement) =>
				this.openAiService.crateArguments(statement, promptId)
			);
	}
}
