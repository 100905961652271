import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserService } from './user-service';
import {
	collection,
	CollectionReference,
	doc,
	DocumentSnapshot,
	Firestore,
	getDoc,
	setDoc,
} from '@angular/fire/firestore';
import {
	FirestoreDebateDocument,
	FirestoreDebateDocumentWithId,
} from '../../model/firestore/firestore';
import {
	catchError,
	first,
	forkJoin,
	from,
	map,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { selectFirestoreDocument } from '../../store/shared/selectors';
import { selectFirestoreDocId } from '../../store/firestore/firestore-selectors';

@Injectable({ providedIn: 'root' })
export class FirestoreService {
	private store = inject(Store);
	private userService = inject(UserService);
	private firestore = inject(Firestore);
	private debateCollection: CollectionReference<FirestoreDebateDocument>;

	constructor() {
		this.debateCollection = collection(
			this.firestore,
			'debates'
		) as CollectionReference<FirestoreDebateDocument>;
	}

	saveDebate(): Observable<{
		document: FirestoreDebateDocumentWithId;
		success: boolean;
		message?: string;
	}> {
		return this.store.select(selectFirestoreDocId).pipe(
			first(),
			switchMap((firestoreDocId) => {
				const docRef = firestoreDocId
					? doc(this.debateCollection, firestoreDocId)
					: doc(this.debateCollection);
				const docId = docRef.id;
				return forkJoin([
					from(getDoc(docRef)),
					this.createFirestoreDebateDocument(),
				]).pipe(
					switchMap(([existingDoc, obj]) => {
						const document = { ...obj, id: docId };
						if (this.canSaveDebateTo(existingDoc)) {
							return from(setDoc(docRef, obj)).pipe(
								map(() => ({ document, success: true })),
								catchError((err) => {
									console.log(err);
									return of({
										document,
										success: false,
										message: err.message,
									});
								})
							);
						} else
							return of({
								document,
								success: false,
								message: `You don't have permissions to save ${docId}`,
							});
					})
				);
			})
		);
	}

	loadDebate(docId: string): Observable<FirestoreDebateDocument> {
		return from(getDoc(doc(this.debateCollection, docId))).pipe(
			map((d) => d.data())
		);
	}

	canSaveDebateTo(doc: DocumentSnapshot<FirestoreDebateDocument>) {
		const user = this.userService.currentUser();
		return (
			user &&
			!user.isAnonymous &&
			(!doc.exists() || doc.data().owner === user?.uid)
		);
	}

	canSaveDebateById(id: string) {
		return id
			? from(getDoc(doc(this.debateCollection, id))).pipe(
					map((doc: DocumentSnapshot<FirestoreDebateDocument>) =>
						this.canSaveDebateTo(doc)
					)
			  )
			: of(true);
	}

	canSaveDebate(): Observable<boolean> {
		return this.store
			.select(selectFirestoreDocId)
			.pipe(switchMap((id) => this.canSaveDebateById(id)));
	}

	createFirestoreDebateDocument() {
		return this.store.select(selectFirestoreDocument).pipe(
			first(),
			map((document) => {
				return {
					...document,
					owner:
						document.owner ?? this.userService.currentUser()?.uid,
				} as FirestoreDebateDocument;
			})
		);
	}
}
