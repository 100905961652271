import { Store } from '@ngrx/store';
import { Statement } from '../../../model/statements/statement';
import { inject, InjectionToken } from '@angular/core';
import { TOKEN_TAGS } from '../../../services/argument-node-registry.service';
import { Utils } from '../../../utils/utils';
import { Tag } from '../../../services/component-registry';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { PathService } from '../../../services/path.service';
import { Router } from '@angular/router';
import { StatementComponentStore } from './statement-component-store';
import { TOKEN_STATEMENT_ID } from '../../../services/statement-node-registry.service';
import { selectStatement } from '../../../store/statement/statement-selectors';
import { StatementComponentConfig } from '../../../services/dynamic-statement.component';

export const TOKEN_COMPONENT_CONFIG =
	new InjectionToken<StatementComponentConfig>('Config');

/**
 * Required providers:
 * {@link StatementComponentStore}
 */
export abstract class AbstractStatementEditComponent<
	S extends Statement,
	STORE extends StatementComponentStore<S> = StatementComponentStore<S>
> {
	protected store: Store = inject(Store);
	protected dtRouter = inject(PathService);
	protected router = inject(Router);
	protected statementId: string = inject(TOKEN_STATEMENT_ID);
	protected tags: Tag[] = inject(TOKEN_TAGS);
	protected config = inject(TOKEN_COMPONENT_CONFIG);
	protected statement$: Observable<S>;
	protected componentStore = inject(StatementComponentStore) as STORE;

	constructor() {
		if (this.statementId)
			this.store
				.select(selectStatement(this.statementId))
				.pipe(first())
				.subscribe((statement: S) =>
					this.componentStore.initStore(statement, 'edit')
				);
		else this.componentStore.initStore(this.createStatement(), 'create');
		this.statement$ = this.componentStore.statement$;
	}
	abstract createStatement(): S;
	isCreate(): boolean {
		return Utils.matchTags(['create'] as Tag[], this.tags);
	}

	isEdit(): boolean {
		return Utils.matchTags(['edit'] as Tag[], this.tags);
	}

	isView(): boolean {
		return Utils.matchTags(['view'] as Tag[], this.tags);
	}

	getStatement$(): Observable<S> {
		return this.statement$;
	}
}
