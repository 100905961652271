import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { Argument } from '../../../model/arguments/argument';
import { OpinionsService } from '../../../services/opinions.service';
import { ConfigService } from '../../../services/config.service';
import { Utils } from '../../../utils/utils';
import {
	OpinionConfig,
	OpinionsEnum,
	OpinionsTag,
} from '../../../model/descriptions/schema/opinion.configs.schema';
import { Observable, shareReplay, switchMap } from 'rxjs';
import { ArgumentNode } from '../../../model/arguments/argument-node';
import { ArgumentNodeRegistryService } from '../../../services/argument-node-registry.service';
import { environment } from '../../../../environments/environment';

/**
 * Looks like this fits opinions both about argument and statement
 */
@Component({
	selector: 'debatt-arg-opinions',
	templateUrl: './opinions-argument.component.html',
	styleUrls: ['./opinions-argument.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpinionsArgumentComponent implements OnInit {
	@Input() argument: Argument;
	opinions: Map<OpinionsEnum, number>;
	impact$;
	private opinionConfigs: OpinionConfig[];
	private node$: Observable<ArgumentNode<any>>;
	private registryService = inject(ArgumentNodeRegistryService);

	constructor(private opinionService: OpinionsService) {}

	ngOnInit(): void {
		this.node$ = this.registryService
			.getOrCreateNode(this.argument.id)
			.pipe(shareReplay(1));
		this.impact$ = this.node$.pipe(
			switchMap((node) =>
				node.calculateImpact(
					undefined,
					undefined,
					environment.argumentOpinionDepth
				)
			)
		);
		this.opinions = this.opinionService.getArgOpinions(this.argument);
		// this.opinions.forEach((value, key) => )
		this.opinionConfigs = ConfigService.instance.getAllOpinionConfigs();
		this.opinionConfigs = this.filter(['argument']);
	}

	/**
	 * Gets all opinions that satisfy at least one set of tags.
	 * @param tagsOr
	 */
	public filterOr(tagsOr: OpinionsTag[][]): OpinionConfig[] {
		return this.opinionConfigs.filter(
			(c) =>
				c.tags && tagsOr.some((tags) => Utils.matchTags(tags, c.tags))
		);
	}

	public filter(tags: OpinionsTag[]): OpinionConfig[] {
		return this.opinionConfigs.filter(
			(c) => c.tags && Utils.matchTags(tags, c.tags)
		);
	}

	public countPro(): number {
		let count = 0;
		this.opinions.forEach((value, key) => {
			if (
				ConfigService.instance
					.getOpinionConfig(key)
					?.tags.includes('pro')
			)
				count += value;
		});
		return count;
	}

	public countCon(): number {
		let count = 0;
		this.opinions.forEach((value, key) => {
			if (
				ConfigService.instance
					.getOpinionConfig(key)
					?.tags.includes('con')
			)
				count += value;
		});
		return count;
	}
}
