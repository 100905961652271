import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	combineLatest,
	debounceTime,
	EMPTY,
	exhaustMap,
	filter,
	first,
	from,
	map,
	of,
	skip,
	switchMap,
	tap,
} from 'rxjs';
import { Router } from '@angular/router';
import {
	collection,
	CollectionReference,
	deleteDoc,
	doc,
	DocumentData,
	Firestore,
} from '@angular/fire/firestore';
import { UserService } from '../../services/firebase/user-service';
import { firestoreActions } from './firestore-actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirestoreService } from '../../services/firebase/firestore-service';
import { routerNavigatedAction } from '@ngrx/router-store';
import { selectFirestoreDocId } from './firestore-selectors';
import { selectSettingsAutosaveFirestore } from '../settings/settings-selectors';
import { selectFirestoreDocumentForAutosave } from '../shared/selectors';
import { URLConst } from '../../core/routes';

@Injectable()
export class FirestoreEffects {
	private firestore: Firestore = inject(Firestore);
	private actions$ = inject(Actions);
	private store = inject(Store);
	private router = inject(Router);
	private userService = inject(UserService);
	private snackBar = inject(MatSnackBar);
	private firestoreService = inject(FirestoreService);
	private debateCollection: CollectionReference<DocumentData>;

	constructor() {
		this.debateCollection = collection(this.firestore, 'debates');
	}

	saveToFirestore$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(firestoreActions.saveToFirestore),
			exhaustMap(() => {
				return this.firestoreService.saveDebate().pipe(
					switchMap(({ document, success, message }) =>
						success
							? of(
									firestoreActions.saveToFirestoreCompleted({
										document,
									})
							  )
							: of(
									firestoreActions.saveToFirestoreFailed({
										message,
									})
							  )
					)
				);
			})
		);
	});

	saveToFirestoreCompleted$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(firestoreActions.saveToFirestoreCompleted),
				tap(({ document: { id } }) => {
					this.snackBar.open(
						`Successfully saved to ${id}`,
						undefined,
						{ duration: 2000 }
					);
				})
			);
		},
		{ dispatch: false }
	);
	saveToFirestoreFailed$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(firestoreActions.saveToFirestoreFailed),
				tap(({ message }) => {
					this.snackBar.open(message, undefined, { duration: 3000 });
				})
			);
		},
		{ dispatch: false }
	);
	delete$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(firestoreActions.deleteFromFirestore),
			exhaustMap(({ docId }) =>
				from(deleteDoc(doc(this.debateCollection, docId))).pipe(
					map(() =>
						firestoreActions.deleteFromFirestoreCompleted({
							docId,
						})
					)
				)
			)
		);
	});
	deleteCompleted$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(firestoreActions.deleteFromFirestoreCompleted),
				tap(({ docId }) => {
					this.snackBar.open(
						`Successfully deleted ${docId}`,
						undefined,
						{ duration: 3000 }
					);
				})
			);
		},
		{ dispatch: false }
	);

	loadFromFirestore$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(firestoreActions.loadFromFirestore),
			exhaustMap(({ docId }) => {
				return this.firestoreService.loadDebate(docId).pipe(
					// tap(() => this.router.navigate([URLConst.DEBATT_ROOT])),
					map((document) =>
						firestoreActions.loadFromFirestoreCompleted({
							document: {
								...document,
								id: docId,
							},
						})
					)
				);
			})
		);
	});

	navigateToDebate$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(routerNavigatedAction),
			filter(
				(action) =>
					action.payload.routerState.root.firstChild?.url[0]?.path ===
					URLConst.FIRESTORE_ROOT
			),
			map(
				(action) =>
					action.payload.routerState.root.firstChild.params[
						URLConst.FIRESTORE_DEBATE
					]
			),
			//do not reload while navigating within the same debate
			// distinctUntilChanged(),
			switchMap((routeId) =>
				this.store.select(selectFirestoreDocId).pipe(
					first(),
					filter((currentId) => routeId !== currentId),
					map(() => routeId)
				)
			),
			map((docId) => firestoreActions.loadFromFirestore({ docId }))
		);
	});

	autosaveFirestore$ = createEffect(
		() => {
			return combineLatest([
				this.store.select(selectSettingsAutosaveFirestore),
				this.store.select(selectFirestoreDocId),
				this.firestoreService.canSaveDebate(),
			]).pipe(
				switchMap(([enabled, docId, canSave]) =>
					enabled && docId && canSave
						? this.store
								.select(selectFirestoreDocumentForAutosave)
								.pipe(skip(1))
						: EMPTY
				),
				debounceTime(2000),
				// tap(doc => console.log(`Updated doc ${doc.title}`)),
				//TODO: replace with dedicated firestoreActions.autosave()
				map((doc) => firestoreActions.saveToFirestore())
			);
		},
		{ dispatch: true }
	);
}
