import { Argument } from './argument';
import { inject } from '@angular/core';
import { DebattAction, TOKEN_ACTIONS } from '../actions/actions';
import { Store } from '@ngrx/store';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import {
	combineLatest,
	combineLatestWith,
	filter,
	map,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { StatementNodeRegistryService } from '../../services/statement-node-registry.service';
import { ExplanationTree, Perspective } from '../statements/statement-node';
import { ConfigService } from '../../services/config.service';
import { selectArgument } from '../../store/argument/argument-selectors';

/**
 * Handles logic and lifecycle, e.g. creation, saving associated with argument.
 *
 */
export interface ArgumentNode<T extends Argument> {
	getArgument$(): Observable<T>;
	/**This can be taken from
	 * * premises field directly
	 * * premise -> [premise]
	 * * ifPart, thenPart -> [ifPart, thenPart]
	 * * ...
	 */
	getPremises$(): Observable<string[]>;

	getActions$(): Observable<DebattAction[]> /* | Observable<Action[]>*/;

	calculateImpact(
		perspective?: Perspective,
		explanations?: ExplanationTree,
		depth?: number
	): Observable<number>;
}

export abstract class AbstractArgumentNode<T extends Argument>
	implements ArgumentNode<T>
{
	protected store: Store = inject(Store);
	private injectedActions = inject(TOKEN_ACTIONS, { optional: true });
	protected statementNodeRegistryService = inject(
		StatementNodeRegistryService
	);
	private configService = inject(ConfigService);
	private id = inject(TOKEN_ARGUMENT_ID);
	protected argument$: Observable<T>;

	constructor() {
		this.argument$ = this.store
			.select(selectArgument<T>(this.id))
			.pipe(filter(Boolean));
	}

	getArgument$(): Observable<T> {
		return this.argument$;
	}

	getPremises$(): Observable<string[]> {
		return of([]);
	}

	getActions$(): Observable<DebattAction[]> {
		return of(this.injectedActions);
	}

	calculateImpact(
		perspective?: Perspective,
		explanations?: ExplanationTree,
		depth = 1
	): Observable<number> {
		const opinions$ = this.getPremises$().pipe(
			switchMap((premises) =>
				combineLatest(
					premises.map((premiseId) =>
						this.statementNodeRegistryService
							.getOrCreateNode(premiseId)
							.pipe(
								switchMap((node) =>
									node.getTruthiness(
										perspective,
										explanations,
										depth - 1
									)
								)
							)
					)
				)
			)
		);

		return this.argument$.pipe(
			combineLatestWith(opinions$),
			map(([arg, opinions]) => {
				if (arg && opinions) {
					const isAttack =
						this.configService.getArgumentConfig(arg.type)
							.relationType === 'attack';
					const sum = opinions.reduce((total, o) => total + o);
					const avg = opinions.length ? sum / opinions.length : 0;
					if (avg >= 0) return isAttack ? -avg : avg;
					else return 0; //
				} else return 0;
			})
		);
	}
}
