import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Statement } from '../../../model/statements/statement';
import { ConfigService } from '../../../services/config.service';
import { ArgumentConfig } from '../../../model/descriptions/schema/argument.configs.schema';

@Component({
	selector: 'list-argument',
	templateUrl: './list-arguments.component.html',
	styleUrls: ['./list-arguments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListArgumentsComponent implements OnInit, OnChanges {
	@Input() statement: Statement;
	argumentTypes: ArgumentConfig[];

	ngOnInit() {
		this.argumentTypes = Object.values(
			ConfigService.instance.getAllArgumentConfigs()
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		// console.log(changes);
	}
}
