import {
	ArgdownApplication,
	ArgdownTypes,
	DataPlugin,
	IArgdownRequest,
	IArgument,
	IEquivalenceClass,
	ModelPlugin,
	ParserPlugin,
	RegroupPlugin,
	RelationMember,
	RelationType,
} from '@argdown/core';
import { Statement } from '../statements/statement';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Argument, ArgumentSimpleAttack } from '../arguments/argument';
import { ArgumentSimpleSupport } from '../arguments/argument-simple-support';
import { argdownActions } from '../../store/argdown/argdown.actions';
import { Utils } from '../../utils/utils';

@Injectable({
	providedIn: 'root',
})
export class ArgdownParser {
	private app: ArgdownApplication;

	constructor(private store: Store) {
		this.app = new ArgdownApplication();

		const parserPlugin = new ParserPlugin();
		this.app.addPlugin(parserPlugin, 'parse-input');

		this.app.addPlugin(new DataPlugin(), 'build-model');

		const modelPlugin = new ModelPlugin();
		this.app.addPlugin(modelPlugin, 'build-model');

		this.app.addPlugin(new RegroupPlugin(), 'build-model');
	}

	public parse(text: string) {
		const request: IArgdownRequest = {
			input: text,
			process: ['parse-input', 'build-model'],
			logLevel: 'verbose',
		};
		const response = this.app.run(request);
		console.log(response);
		/*
		for (const statementArgDown of Object.values(response.statements)) {
			// if(statement.isUsedAsTopLevelStatement)
			const {statement, childStatements, childArgs} = this.parseStatement(statementArgDown);
			this.store.dispatch(argdownActions.load({
				statements: [statement, ...childStatements],
				args: childArgs,
				rootStatementId: statement.id,
			}));
		}
*/
		const statementsByName: { [name: string]: Statement } = {};
		/*	for (const adStatement of Object.values(response.statements)) {
			const descr = this.getCanonicalMemberText(adStatement);
			const statement: Statement = {
				type: 'StatementDefault',
				id: Utils.generateId(),
				title: adStatement.title,
				description: descr,
				args: []
			};
			statementsByName[adStatement.title] = statement;
		}


		// find root statement
		const noparentStatements = new Set(Object.values(statementsByName).map(s => s.id));

		const args: Argument[] = [];
		for (const relation of Object.values(response.relations)) {
			// const argDescr = this.getCanonicalMemberText(premise);
			const argDescr = undefined;

			let argument: ArgumentSimpleSupport|ArgumentSimpleAttack;
			switch (relation.relationType) {
				case RelationType.SUPPORT: {
					// const {statement: premise, childStatements, childArgs} = this.parseStatement(adPremise);
					argument = {
						id: Utils.generateId(),
						type: 'ArgumentSimpleSupport',
						conclusion: statementsByName[relation.to.title].id,
						title: 'Support',
						description: argDescr,
						premise: statementsByName[relation.from.title].id
					} as ArgumentSimpleSupport;
					break;
				}
				case RelationType.ATTACK: {
					// argument = new ArgumentSimpleAttack(undefined, undefined, 'Attack', argDescr);
					argument = {
						id: Utils.generateId(),
						type: 'ArgumentSimpleAttack',
						conclusion: statementsByName[relation.to.title].id,
						title: 'Support',
						description: argDescr,
						premise: statementsByName[relation.from.title].id
					} as ArgumentSimpleAttack;
					break;
				}
				/!*				case RelationType.ENTAILS:
					break;
				case RelationType.CONTRARY:
					break;
				case RelationType.CONTRADICTORY:
					break;
				case RelationType.UNDERCUT:
					break;*!/
				default: {
					console.error(`Argdown: unprocessed statement ${relation.relationType}`);
					argument = {
						id: Utils.generateId(),
						type: 'ArgumentSimpleSupport',
						conclusion: statementsByName[relation.to.title].id,
						title: 'Support',
						description: argDescr,
						premise: statementsByName[relation.from.title].id
					} as ArgumentSimpleSupport;
					break;
				}
			}
			args.push(argument);
			noparentStatements.delete(argument.premise);
		}
*/

		const { statement, childStatements, childArgs } = this.parseStatement(
			Object.values(response.statements)[0],
			statementsByName
		);
		// find root statement
		// const rootStatementId = noparentStatements.values().next()?.value;

		this.store.dispatch(
			argdownActions.load({
				statements: [statement, ...childStatements],
				args: childArgs,
				rootStatementId: statement.id,
			})
		);
	}

	private getCanonicalMemberText(element: RelationMember): string {
		switch (element.type) {
			case ArgdownTypes.ARGUMENT: {
				return IArgument.getCanonicalMemberText(element);
			}
			case ArgdownTypes.EQUIVALENCE_CLASS: {
				// return IEquivalenceClass.getCanonicalMemberText(element);
				return IEquivalenceClass.getCanonicalMember(element).text;
			}
			case ArgdownTypes.INFERENCE:
				return element.argumentTitle;
		}
	}

	private parseStatement(
		statementEq: RelationMember,
		statementsByName: { [p: string]: Statement }
	): {
		statement: Statement;
		childStatements: Statement[];
		childArgs: Argument[];
	} {
		console.log(`parsing ${statementEq.title}`);
		const statements: Statement[] = [];
		const allArgs: Argument[] = [];

		// const descr = this.joinMemberTexts(statementEq);
		const descr = this.getCanonicalMemberText(statementEq);
		const statement: Statement = {
			type: 'StatementDefault',
			id: Utils.generateId(),
			title: statementEq.title,
			description: descr,
			args: [],
		};
		statementsByName[statement.title] = statement;
		for (const relation of statementEq.relations) {
			if (relation.to.title === statementEq.title) {
				/**ArgDown premise*/
				const adPremise = relation.from;
				let premise: Statement,
					childStatements: Statement[],
					childArgs: Argument[];
				const argDescr = undefined;
				// const argDescr = this.getCanonicalMemberText(premise);
				const existingStatement = statementsByName[adPremise.title];
				if (existingStatement) {
					premise = existingStatement;
					childStatements = [];
					childArgs = [];
					console.warn(`ArgDown: reusing ${existingStatement}`);
				} else
					({
						statement: premise,
						childStatements,
						childArgs,
					} = this.parseStatement(adPremise, statementsByName));
				switch (relation.relationType) {
					case RelationType.SUPPORT: {
						const argument = {
							id: Utils.generateId(),
							type: 'ArgumentSimpleSupport',
							conclusion: statement.id,
							title: 'Support',
							description: argDescr,
							premise: premise.id,
						} as ArgumentSimpleSupport;
						statement.args.push(argument.id);
						allArgs.push(argument, ...childArgs);
						statements.push(premise, ...childStatements);
						break;
					}
					case RelationType.ATTACK: {
						// argument = new ArgumentSimpleAttack(undefined, undefined, 'Attack', argDescr);
						const argument = {
							id: Utils.generateId(),
							type: 'ArgumentSimpleAttack',
							conclusion: statement.id,
							title: 'Attack',
							description: argDescr,
							premise: premise.id,
						} as ArgumentSimpleAttack;
						statement.args.push(argument.id);
						allArgs.push(argument, ...childArgs);
						statements.push(premise, ...childStatements);
						break;
					}
					/*				case RelationType.ENTAILS:
						break;
					case RelationType.CONTRARY:
						break;
					case RelationType.CONTRADICTORY:
						break;
					case RelationType.UNDERCUT:
						break;*/
					default: {
						console.error(
							`Argdown: unprocessed statement ${relation.relationType}`
						);
						const argument = {
							id: Utils.generateId(),
							type: 'ArgumentSimpleSupport',
							conclusion: statement.id,
							title: relation.relationType,
							description: argDescr,
							premise: premise.id,
						} as ArgumentSimpleSupport;
						argument.premise = premise.id;
						statement.args.push(argument.id);
						allArgs.push(argument, ...childArgs);
						statements.push(premise, ...childStatements);
						break;
					}
				}
				/*
				if (argument) {
					args.push(argument);
					const premiseStatement = this.parseStatement(premise);
					argument.addPremise(premiseStatement);
				}
*/
			}
		}
		return { statement, childStatements: statements, childArgs: allArgs };
	}

	private static joinMemberTexts(statementEq: IEquivalenceClass) {
		return statementEq.members.map((value) => value.text).join('<br/>');
	}
}
