import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

type ControlConfig<T> = { [key in keyof T]: any[] };

export type FormConfig<T> = {
	controls: ControlConfig<T>;
	options: AbstractControlOptions;
};

export enum InputType {
	color = 'color',
	date = 'date',
	datetime = 'datetime',
	local = 'local',
	email = 'email',
	month = 'month',
	number = 'number',
	password = 'password',
	search = 'search',
	tel = 'tel',
	text = 'text',
	time = 'time',
	url = 'url',
	week = 'week',
}

type InputItem<V> = {
	label: string;
	value?: V;
	type: InputType;
	validators: any[];
	order?: number;
};
export type InputConfig<T> = {
	inputs: {
		[key in keyof T]: InputItem<T[key]>;
	};
	options: AbstractControlOptions;
};

@Component({
	selector: 'dt-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent<T> implements OnInit {
	@Input() input: T;
	@Input() config: InputConfig<T>;
	@Output() formGroup$ = new BehaviorSubject<FormGroup>(null);
	items: (InputItem<any> & { name: keyof T })[];

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.formGroup$.next(
			this.fb.group<ControlConfig<T>>(
				this.toControlConfig(this.config).controls,
				this.toControlConfig(this.config).options
			)
		);
		this.items = (
			Object.entries(this.config.inputs) as Array<
				[keyof T, InputItem<T[keyof T]>]
			>
		)
			.map(([key, item]) => ({ name: key, ...item }))
			.sort((a, b) => a.order ?? 0 - b.order ?? 0);
	}

	private toControlConfig(config: InputConfig<T>): FormConfig<T> {
		const controls = (
			Object.entries(config.inputs) as Array<[keyof T, InputItem<any>]>
		).reduce(
			(m, [key, input]) => ({
				...m,
				[key]: [input.value ?? '', input.validators],
			}),
			{} as FormConfig<T>['controls']
		);
		return {
			controls,
			options: config.options,
		};
	}
}
