import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { OpinionConfig } from '../descriptions/schema/opinion.configs.schema';
import { Utils } from '../../utils/utils';

/**
 * Can be applied to string, then it is the first filtering tag
 * ```
 * 'statements' | filterOpinionsConfigs:'pro'
 * ```
 * or to an array of OpinionConfig, then that array is filitered
 * ```
 * configs | filterOpinionsConfigs:'statements'
 * ```
 * If instead of filtering string an array of strings is provide
 * then those string are considers to be connected by OR.
 * configs | filterOpinionsConfigs: ['deductive', 'inductive'] : 'pro'
 */
@Injectable({ providedIn: 'root' })
@Pipe({
	name: 'filterOpinionsConfigs',
	pure: true,
})
export class FilterOpinionsConfigsPipe implements PipeTransform {
	private configService = inject(ConfigService);
	private readonly allConfigs: OpinionConfig[];

	constructor() {
		this.allConfigs = this.configService.getAllOpinionConfigs();
	}

	transform(
		start: string | OpinionConfig[],
		...tags: (string | string[])[]
	): OpinionConfig[] {
		const andTags: string[] = [];
		const orTags: string[][] = [];
		let configs: OpinionConfig[];
		if (!Array.isArray(start)) {
			andTags.push(start);
			configs = this.allConfigs;
		} else configs = start;
		for (const t of tags) {
			if (Array.isArray(t)) {
				orTags.push(t);
			} else {
				andTags.push(t);
			}
		}
		return configs
			.filter((c) => c.tags && Utils.matchTags(andTags, c.tags))
			.filter(
				(c) =>
					c.tags &&
					orTags.every((or) => Utils.matchTagsSome(or, c.tags))
			);
	}
}
