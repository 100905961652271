import argConfigs from '../model/descriptions/arguments_en.json';
import statementConfigs from '../model/descriptions/statements_en.json';
import opinionConfigs from '../model/descriptions/opinions_en.json';
import {
	ArgumentConfig,
	ArgumentConfigs,
} from '../model/descriptions/schema/argument.configs.schema';
import {
	OpinionConfig,
	OpinionConfigs,
} from '../model/descriptions/schema/opinion.configs.schema';
import {
	StatementConfig,
	StatementConfigs,
} from '../model/descriptions/schema/statement.configs.schema';
import { Injectable } from '@angular/core';

const typesArray = Object.values(argConfigs).map((c) => c.type);
export type ArgumentTypes = (typeof typesArray)[number];

@Injectable({ providedIn: 'root' })
/**
 * Contains meta/structural information about different objects.
 */
export class ConfigService {
	private static _instance: ConfigService = undefined;

	public static get instance(): ConfigService {
		if (!this._instance) this._instance = new ConfigService();
		return this._instance;
	}

	public getAllArgumentConfigs(): ArgumentConfigs {
		return argConfigs as ArgumentConfigs;
	}

	public getArgumentConfig(argType: string): ArgumentConfig {
		return argConfigs[argType] as ArgumentConfig;
	}

	public getAllStatementConfigs(): StatementConfigs {
		return statementConfigs as StatementConfigs;
	}

	public getStatementConfig(type: string): StatementConfig {
		return statementConfigs[type] as StatementConfig;
	}

	public getOpinionConfig(opinion: string): OpinionConfig {
		return opinionConfigs[opinion] as OpinionConfig;
	}

	public getAllOpinionConfigs(): OpinionConfig[] {
		return Object.values(opinionConfigs as OpinionConfigs);
	}
}
