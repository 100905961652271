import { Store } from '@ngrx/store';
import { Argument } from '../../model/arguments/argument';
import { inject, Injector } from '@angular/core';
import {
	TOKEN_ARGUMENT_ID,
	TOKEN_CREATE_ARGUMENT,
	TOKEN_TAGS,
} from '../../services/argument-node-registry.service';
import { Utils } from '../../utils/utils';
import { Tag } from '../../services/component-registry';
import { first, Observable } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { selectArgument } from '../../store/argument/argument-selectors';
import { ArgumentComponentStore } from './store/argument-component-store';

export abstract class ArgumentAbstractEditComponent<
	R extends Argument,
	STORE extends ArgumentComponentStore<R> = ArgumentComponentStore<R>
> {
	protected tags: Tag[] = inject(TOKEN_TAGS);
	protected create = inject(TOKEN_CREATE_ARGUMENT);
	protected injector = inject(Injector);
	protected store: Store = inject(Store);
	private storeActions = inject(Actions);
	protected id? = inject(TOKEN_ARGUMENT_ID);
	protected componentStore = inject(ArgumentComponentStore) as STORE;

	constructor() {
		if (this.id)
			this.store
				.select(selectArgument<R>(this.id))
				.pipe(first())
				.subscribe((argument) =>
					this.componentStore.initStore(argument, 'edit')
				);
		else this.componentStore.initStore(this.createArgument(), 'create');
	}

	abstract createArgument(): R;

	public getArgument$(): Observable<R> {
		return this.componentStore.argument$;
	}

	isCreate(): boolean {
		return Utils.matchTags(['create'] as Tag[], this.tags);
	}

	isEdit(): boolean {
		return Utils.matchTags(['edit'] as Tag[], this.tags);
	}

	isView(): boolean {
		return Utils.matchTags(['view'] as Tag[], this.tags);
	}
}
