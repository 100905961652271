import { Statement } from '../../../model/statements/statement';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { PathService } from '../../../services/path.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	StatementNodeRegistryService,
	TOKEN_STATEMENT_ID,
} from '../../../services/statement-node-registry.service';
import { StatementNode } from '../../../model/statements/statement-node';
import { selectStatement } from '../../../store/statement/statement-selectors';
import { StatementComponentConfig } from '../../../services/dynamic-statement.component';
import { TOKEN_COMPONENT_CONFIG } from './abstract-statement-edit.component';

export abstract class AbstractStatementComponent<S extends Statement> {
	public statementId: string = inject(TOKEN_STATEMENT_ID);
	protected config = inject<StatementComponentConfig>(TOKEN_COMPONENT_CONFIG);
	protected dtRouter = inject(PathService);
	protected router = inject(Router);
	protected store = inject(Store);
	protected registryService = inject(StatementNodeRegistryService);
	protected node$: Observable<StatementNode<S>>;
	protected statement$: Observable<S>;

	constructor() {
		this.node$ = this.registryService.getOrCreateNode(this.statementId);
		this.statement$ = this.store.select(selectStatement(this.statementId));
	}

	getStatement$(): Observable<S> {
		return this.statement$;
	}
}
