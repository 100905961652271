import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Injector,
	Input,
	OnInit,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { ComponentRegistry, Tag } from './component-registry';
import {
	ArgumentComponentConfig,
	CreateArgumentConfig,
	TOKEN_ARGUMENT_COMPONENT_CONFIG,
	TOKEN_ARGUMENT_ID,
	TOKEN_CREATE_ARGUMENT,
	TOKEN_TAGS,
} from './argument-node-registry.service';
import { first, map, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectArgument } from '../store/argument/argument-selectors';

@Component({
	selector: 'dynamic-argument-component',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicArgumentComponent implements OnInit {
	@Input() argumentId: string;
	@Input() create: CreateArgumentConfig;
	@Input() tags: Tag[] = [];
	@Input() config: ArgumentComponentConfig;
	private viewContainerRef = inject(ViewContainerRef);
	private store = inject(Store);

	/**See https://angular.io/guide/dynamic-component-loader
	 *
	 */
	ngOnInit() {
		(this.create?.type
			? of(this.create.type)
			: this.store.select(selectArgument(this.argumentId)).pipe(
					first(),
					map((a) => a.type)
			  )
		).subscribe((type) => {
			const componentClass: Type<Component> =
				ComponentRegistry.getComponent(type, this.tags);

			if (componentClass) {
				const newInjector = Injector.create({
					providers: [
						{
							provide: TOKEN_ARGUMENT_ID,
							useValue: this.argumentId,
						},
						{ provide: TOKEN_TAGS, useValue: this.tags },
						{
							provide: TOKEN_ARGUMENT_COMPONENT_CONFIG,
							useValue: this.config,
						},
						{
							provide: TOKEN_CREATE_ARGUMENT,
							useValue: this.create,
						},
					],
					// parent: this.injector
				});
				const newRef = this.viewContainerRef.createComponent(
					componentClass,
					{
						injector: newInjector,
					}
				);
			} else {
				throw Error(
					`DynamicComponent: cannot find component for "${type}" with tags [${this.tags}]`
				);
			}
		});
	}
}
