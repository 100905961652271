import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { BehaviorSubject, delay, map, Observable } from 'rxjs';
import { ArgumentAbstractEditComponent } from '../argument-template/argument-abstract-edit.component';
import { SelectStatementComponent } from '../../statement/shared/select-statement/select-statement.component';
import { Statement } from 'src/app/model/statements/statement';
import { ArgumentCalculateProbability } from '../../model/arguments/argument-calculate-probability';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ArgumentComponentStore } from '../argument-template/store/argument-component-store';
import { ArgumentSinglePremiseComponentStore } from '../argument-template/store/argument-single-premise-component-store';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentCalculateProbability',
	title: 'Support',
	tags: ['edit', 'create', 'argument', 'attack'],
})
@Component({
	selector: 'dt-argument-calculate-probability-edit',
	templateUrl: 'argument-calculate-probability-edit.component.html',
	styleUrls: ['./argument-calculate-probability.component.scss'],
	providers: [
		{
			provide: ArgumentComponentStore,
			useClass: ArgumentSinglePremiseComponentStore,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentCalculateProbabilityEditComponent
	extends ArgumentAbstractEditComponent<
		ArgumentCalculateProbability,
		ArgumentSinglePremiseComponentStore<ArgumentCalculateProbability>
	>
	implements OnInit, AfterViewInit
{
	@ViewChild('selectStatementComponent')
	selectStatementComponent: SelectStatementComponent;
	private premise$ = new BehaviorSubject<Statement>(undefined);
	isValid$ = new BehaviorSubject<boolean>(false);

	ppv$: Observable<number>;

	override createArgument(): ArgumentCalculateProbability {
		return {
			type: 'ArgumentCalculateProbability',
			conclusion: this.create.conclusion,
			title: 'Calculate probabilities',
			description: '',
			premise: undefined,
		};
	}

	ngOnInit(): void {
		// this.ppv$ = this.formGroup$.pipe(
		// 	switchMap(
		// 		gr =>
		// 			of(gr.value).pipe(mergeWith(gr.valueChanges))),
		// 	switchMap(v => of(this.calculatePPV(v))),
		// );
	}

	ngAfterViewInit() {
		this.premise$
			.pipe(
				untilDestroyed(this),
				delay(0), //to change it after all init hooks
				// tap(premise => this.argument.events[0] = premise?.id),
				map((s) => !!s)
			)
			.subscribe(this.isValid$);
	}

	/*
	onSave() {

		let updateFn: OnStatementIds<ArgumentCalculateProbability> =
			(argument, newStatements) => ({
			...argument,
			premise: newStatements[0].id
		});
		return combineLatest([this.componentStore.argument$, this.premise$]).pipe(
			first(),
			switchMap(
				([argument, premise]) => this.save(argument, updateFn, premise)
			)
		);
	}
*/
	setPremise($event: Statement) {
		this.premise$.next($event);
		this.componentStore.updatePremiseStatement($event);
	}
}
