import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OpenaiState } from './opeai-reducer';
import { Features } from '../shared/reducer';

export const selectOpenaiState = createFeatureSelector<OpenaiState>(
	Features.openai
);

export const selectOpenaiLoading = createSelector(
	selectOpenaiState,
	({ loading }) => loading ?? false
);
