import {
	Argument,
	ArgumentModusPonens,
	ArgumentSimpleAttack,
} from './argument';
import { RegisterArgumentNode } from '../../services/argument-node-registry.service';
import { AbstractArgumentNode } from './argument-node';
import { map, Observable } from 'rxjs';

export class AbstractArgumentNodeWithPremises<
	T extends Argument
> extends AbstractArgumentNode<T> {}

@RegisterArgumentNode()
export class ArgumentModusPonensNode extends AbstractArgumentNode<ArgumentModusPonens> {}

@RegisterArgumentNode()
export class ArgumentSimpleAttackNode extends AbstractArgumentNode<ArgumentSimpleAttack> {
	override getPremises$(): Observable<string[]> {
		return this.argument$.pipe(map((a) => (a?.premise ? [a.premise] : [])));
	}
}
