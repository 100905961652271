import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { Observable, switchMap, map } from 'rxjs';
import {
	ArgumentCalculateProbability,
	ArgumentCalculateProbabilityNode,
} from '../../model/arguments/argument-calculate-probability';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import { StatementTestParameters } from '../../model/statements/statement-test-parameters';
import { selectStatement } from '../../store/statement/statement-selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProbabilityService } from './service/probability-service';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentCalculateProbability',
	title: 'Support',
	tags: ['view', 'argument', 'attack'],
})
@Component({
	selector: 'dt-calculate-probability',
	templateUrl: './argument-calculate-probability.component.html',
	styleUrls: ['./argument-calculate-probability.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ArgumentCalculateProbabilityComponent
	extends ArgumentAbstractComponent<
		ArgumentCalculateProbabilityNode,
		ArgumentCalculateProbability
	>
	implements OnInit
{
	premise$: Observable<StatementTestParameters>;
	ppv$: Observable<number>;

	ngOnInit(): void {
		this.premise$ = this.argument$.pipe(
			switchMap((arg) =>
				this.store.select(
					selectStatement<StatementTestParameters>(arg.premise)
				)
			),
			untilDestroyed(this)
		);
		this.ppv$ = this.premise$.pipe(
			map((pr) =>
				pr ? ProbabilityService.calculatePPV(pr.parameters) : Number.NaN
			)
		);
	}
}

@RegisterDynamicComponent({
	dataType: 'ArgumentCalculateProbability',
	tags: ['label'],
})
@Component({
	selector: 'debatt-argument-simple-attack-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'green'"
			[icon]="'check_circle_outline'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentCalculateProbabilitiesLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
