import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import {
	BehaviorSubject,
	map,
	mergeWith,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { AbstractStatementEditComponent } from '../shared/statement-template/abstract-statement-edit.component';
import {
	InputConfig,
	InputType,
} from '../../argument/argument-calculate-probability/dynamic-form/dynamic-form.component';
import { FormGroup, Validators } from '@angular/forms';
import { StatementComponentStore } from '../shared/statement-template/statement-component-store';
import { StatementProbability } from '../../model/statements/statement-probability';

type TestSensitivity = { tpr: number; tnr: number; prevalence: number };

@RegisterDynamicComponent({
	dataType: 'StatementProbability',
	title: 'Probabilities',
	tags: ['create', 'edit'],
})
@Component({
	selector: 'statement-probabilities-edit',
	templateUrl: './statement-probabilities.edit.component.html',
	styleUrls: ['./statement-probabilities.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [StatementComponentStore],
})
export class StatementProbabilitiesEditComponent
	extends AbstractStatementEditComponent<StatementProbability>
	implements OnInit
{
	protected isValid$: Observable<boolean>;
	input: TestSensitivity;
	controlConfig: InputConfig<TestSensitivity>;

	ppv$: Observable<number>;
	formGroup$ = new BehaviorSubject<FormGroup>(null);

	createStatement(): StatementProbability {
		return {
			type: 'StatementProbability',
			title: '',
			description: '',
			args: [],
		};
	}
	ngOnInit() {
		this.isValid$ = this.statement$.pipe(
			map((st) => Boolean(st?.title || st?.description))
		);
		this.controlConfig = {
			inputs: {
				tpr: {
					label: 'True positive rate',
					value: 0.9,
					type: InputType.number,
					validators: [Validators.max(1), Validators.min(0)],
					order: 1,
				},
				tnr: {
					label: 'True negative rate',
					value: 0.8,
					type: InputType.number,
					validators: [Validators.max(1), Validators.min(0)],
					order: 2,
				},
				prevalence: {
					label: 'Prevalence (Share of true cases in population)',
					value: 0.05,
					type: InputType.number,
					validators: [Validators.max(1), Validators.min(0)],
					order: 3,
				},
			},
			options: {
				// updateOn:
			},
		};
		this.ppv$ = this.formGroup$.pipe(
			switchMap((gr) => of(gr.value).pipe(mergeWith(gr.valueChanges))),
			switchMap((v) => of(this.calculatePPV(v)))
		);
	}

	/**
	 * The Positive predictive value (PPV) of a test
	 * is the proportion of persons who are actually positive
	 * out of all those testing positive
	 */
	// calculatePPV(tpr: number, tnr: number, prevalence: number) {
	calculatePPV({ tpr, tnr, prevalence }: Partial<TestSensitivity>): number {
		const pPositive = tpr * prevalence + (1 - tnr) * (1 - prevalence);
		if (!pPositive) return Number.NaN;
		const p = (tpr * prevalence) / pPositive;
		return p;
	}
}
