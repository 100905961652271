import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stringify',
})
export class StringifyPipe implements PipeTransform {
	/**Accepts objects with circular dependencies as well.*/
	transform(obj: any): string {
		let cache = [];
		const retVal = JSON.stringify(
			obj,
			(key, value) =>
				typeof value === 'object' && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			2
		);
		cache = null;
		return retVal;
	}
}
