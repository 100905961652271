import { Statement } from './statement';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TOKEN_STATEMENT_ID } from '../../services/statement-node-registry.service';
import {
	selectStatement,
	selectStatementArgumentIds,
} from '../../store/statement/statement-selectors';
import { ArgumentNodeRegistryService } from '../../services/argument-node-registry.service';
import { environment } from '../../../environments/environment';
import { selectOpinionVotes } from '../../store/opinions/opinion-selectors';
import { Opinion } from '../../store/opinions/opinion-reducer';
import { ConfigService } from '../../services/config.service';

export interface Perspective {
	name: string;
}

export interface ExplanationTree {}

export interface StatementNode<S extends Statement> {
	getArgumentIds(): Observable<string[]>;
	getStatement$(): Observable<S>;

	/**
	 * <p>
	 *     shareReplay(1) with proper caching (include depth) might be useful here.
	 * </p>
	 * @param perspective
	 * @param [explanations] The tree of explanations for aggregation of votes
	 * @param depth how deep in the tree to look. 0 - to look only the direct evaluation of this node
	 */
	getTruthiness(
		perspective?: Perspective,
		explanations?: ExplanationTree,
		depth?: number
	): Observable<number>;
}

@Injectable()
export class AbstractStatementNode<S extends Statement>
	implements StatementNode<S>
{
	protected store = inject(Store);
	// protected id: string;
	private statementId = inject(TOKEN_STATEMENT_ID);

	/*
	constructor(id: string) {
		this.id = id;
	}
*/

	getStatement$(): Observable<S> {
		return this.store
			.select(selectStatement<S>(this.statementId))
			.pipe(filter(Boolean));
	}

	getArgumentIds(): Observable<string[]> {
		return;
	}

	private argumentNodeRegistryService = inject(ArgumentNodeRegistryService);

	getTruthiness(
		perspective?: Perspective,
		depth: number = environment.statementOpinionDepth
	): Observable<number> {
		return this.getStatement$().pipe(
			switchMap((st) => {
				const opinions$ = this.store.select(selectOpinionVotes(st.id));
				return opinions$.pipe(
					switchMap((opinion) =>
						combineLatest([
							of(this.countPro(opinion)),
							of(this.countCon(opinion)),
							this.countArgumentsImpact(st.id, depth - 1),
						])
					),
					map(([pro, con, argsImpact]) => {
						const total = Math.abs(pro) + Math.abs(con);
						const avg = total > 0 ? (pro - con) / total : 0;
						return avg + argsImpact;
					})
				);
			})
		);
	}

	private countPro(opinions: Opinion['votes']): number {
		return Object.entries(opinions).reduce(
			(sum, [key, value]) =>
				ConfigService.instance
					.getOpinionConfig(key)
					?.tags.includes('pro')
					? sum + value
					: sum,
			0
		);
	}

	private countCon(opinions: Opinion['votes']): number {
		return Object.entries(opinions).reduce(
			(sum, [key, value]) =>
				ConfigService.instance
					.getOpinionConfig(key)
					?.tags.includes('con')
					? sum + value
					: sum,
			0
		);
	}

	private countArgumentsImpact(statementId: string, depth = 0) {
		if (depth >= 0)
			return this.store
				.select(selectStatementArgumentIds(statementId))
				.pipe(
					switchMap((ids: string[]) =>
						ids.length
							? combineLatest(
									ids.map((id: string) =>
										this.argumentNodeRegistryService
											.getOrCreateNode(id)
											.pipe(
												switchMap((node) =>
													node.calculateImpact()
												)
											)
									)
							  )
							: of([])
					),
					map((impacts) => this.aggregateImpacts(impacts))
				);
		else return of(0);
	}

	private aggregateImpacts(impacts: number[]) {
		if (impacts.length > 0)
			return impacts.reduce((sum, v) => sum + v, 0) / impacts.length;
		else return 0;
	}
}
