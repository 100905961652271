import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAdAbsurdum } from '../../model/arguments/argument';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';

@RegisterDynamicComponent({
	dataType: 'ArgumentAdAbsurdum',
	title: 'Ad absurdum',
	tags: ['argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-ad-absurdum',
	templateUrl: './argument-ad-absurdum.component.html',
	styleUrls: ['./argument-ad-absurdum.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentAdAbsurdumComponent extends ArgumentAbstractComponent<
	any,
	ArgumentAdAbsurdum
> {}

@RegisterDynamicComponent({
	dataType: 'ArgumentAdAbsurdum',
	tags: ['label'],
})
@Component({
	selector: 'argument-ad-absurdum-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'red'"
			[icon]="'keyboard_tab'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentAdAbsurdumLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
