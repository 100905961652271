import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {
	BehaviorSubject,
	debounceTime,
	EMPTY,
	first,
	map,
	merge,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { Statement } from '../../../model/statements/statement';
import {
	DynamicStatementComponent,
	StatementComponentConfig,
} from '../../../services/dynamic-statement.component';
import { Store } from '@ngrx/store';
import {
	selectAllStatements,
	selectStatement,
} from '../../../store/statement/statement-selectors';
import { Tag } from '../../../services/component-registry';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { MatInput } from '@angular/material/input';
import { ConfigService } from '../../../services/config.service';
import { Utils } from '../../../utils/utils';
import { StatementConfig } from '../../../model/descriptions/schema/statement.configs.schema';

export interface SelectStatementConfig extends StatementComponentConfig {
	/**
	 * id - display statement by id or select button if no id provided
	 *
	 * edit - start in edit mode
	 *
	 * create - start in create mode of the `createType` statement
	 *
	 * select - start with select statement menu activated
	 */
	start?: 'empty' | 'id' | 'edit' | 'create' | 'select';
	id?: string;
	createType?: string;
}

enum State {
	INIT,
	EMPTY,
	EDIT,
	VIEW,
	CREATE,
}

@UntilDestroy()
@Component({
	selector: 'dt-select-statement',
	templateUrl: './select-statement.component.html',
	styleUrls: ['./select-statement.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStatementComponent implements OnInit, AfterViewInit {
	State = State;
	/**@deprecated*/
	@Input()
	private initStatementId: string | null;
	@Input()
	private tags: Tag[] = [];
	@Input()
	public config: SelectStatementConfig = { embedded: true, start: 'id' };
	@Output()
	statement$ = new EventEmitter<Statement>();
	// statement$: Observable<Statement>;
	@ViewChildren('searchField', { read: MatInput })
	searchFieldRef: QueryList<MatInput>;
	@ViewChild('menu') menuRef: MatMenu;
	@ViewChildren('editStatementComponent')
	private statementComponent$: QueryList<DynamicStatementComponent>;
	public config$ = new BehaviorSubject(this.config);
	private editComponentStatement$ = new BehaviorSubject<Statement>(undefined);
	protected statementId$ = new BehaviorSubject<string>(null);
	existingStatements$: Observable<Statement[]>;
	allStatementConfigs$: Observable<StatementConfig[]>;
	state$ = new BehaviorSubject<State>(State.INIT);
	/**Search text*/
	premiseFilterCtrl = new FormControl();
	private filterString$: Observable<string>;
	private store = inject(Store);
	private configs = inject(ConfigService);

	constructor() {
		this.filterString$ = merge(of(''), this.premiseFilterCtrl.valueChanges);
		const allConfigs = Object.values(this.configs.getAllStatementConfigs());
		this.allStatementConfigs$ = this.filterString$.pipe(
			map((f) =>
				allConfigs.filter(
					(c) =>
						c.title.toLowerCase().match(f.toLowerCase()) &&
						Utils.matchTags(this.tags, c.tags)
				)
			)
		);
		this.existingStatements$ = this.store
			.select(selectAllStatements)
			.pipe(
				switchMap((s) =>
					this.filterString$.pipe(map((f) => this.filter(s, f)))
				)
			);
	}

	ngOnInit(): void {
		const id = this.initStatementId || this.config.id;
		switch (this.config.start) {
			case 'empty':
				this.statementId$.next(undefined);
				this.state$.next(State.EMPTY);
				break;
			case 'select':
			case 'id':
				this.statementId$.next(id);
				this.state$.next(id ? State.VIEW : State.EMPTY);
				break;
			case 'create':
				this.createStatement(this.config.createType);
				break;
			case 'edit':
				this.statementId$.next(id);
				if (id) {
					this.state$.next(State.EDIT);
				} else if (this.config.createType)
					this.createStatement(this.config.createType);
				else {
					this.state$.next(State.EMPTY);
				}
				break;
			default:
				this.statementId$.next(id);
				this.state$.next(id ? State.VIEW : State.EMPTY);
				break;
		}
	}

	/**
	 * statementComponent$ is initialized only before ngAfterViewInit
	 */
	ngAfterViewInit(): void {
		this.statementComponent$.changes
			.pipe(
				switchMap(
					(list: QueryList<DynamicStatementComponent>) =>
						list?.first?.getComponent().getStatement$() ??
						of(undefined)
				)
			)
			.subscribe(this.editComponentStatement$);
		const viewStatement$ = this.statementId$.pipe(
			switchMap((id) => this.store.select(selectStatement(id)))
		);
		this.state$
			.pipe(
				untilDestroyed(this),
				switchMap((value) => {
					switch (value) {
						case State.INIT:
							return EMPTY;
						case State.EMPTY:
							return of(undefined);
						case State.EDIT:
						case State.CREATE:
							return this.editComponentStatement$;
						case State.VIEW:
							return viewStatement$;
					}
				}),
				debounceTime(100)
			)
			.subscribe(this.statement$);
	}

	setStatement(statementId?: string) {
		this.statementId$.next(statementId);
		this.state$.next(statementId ? State.VIEW : State.EMPTY);
	}

	createStatement(type: string) {
		this.statementId$.next(undefined);
		this.config$.next({ ...this.config, createType: type });
		this.state$.next(State.CREATE);
	}

	getStatement$(): Observable<Statement> {
		return this.statement$;
	}

	private filter(statements: Statement[], f: string) {
		const searchString = f.toLowerCase();
		return statements.filter(
			(s) =>
				s.title.toLowerCase().match(searchString.toLowerCase()) &&
				Utils.matchTags(
					this.tags,
					this.configs.getStatementConfig(s.type)?.tags
				)
		);
	}

	menuOpened() {
		this.searchFieldRef.changes.pipe(first()).subscribe((queryList) => {
			queryList.first.focus();
		});
	}
}
