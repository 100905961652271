import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRouteNestedParam } from '../../../store/router/router.selectors';
import { debounceTime, filter, Observable, switchMap, take } from 'rxjs';
import {
	selectFirestoreDescription,
	selectFirestoreLoading,
	selectFirestoreTitle,
} from '../../../store/firestore/firestore-selectors';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firestoreActions } from '../../../store/firestore/firestore-actions';
import { FirestoreService } from '../../../services/firebase/firestore-service';
import { URLConst } from '../../../core/routes';

@UntilDestroy()
@Component({
	selector: 'dt-debate',
	templateUrl: './debate.component.html',
	styleUrls: ['./debate.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebateComponent implements OnInit {
	private store = inject(Store);
	private firestoreService = inject(FirestoreService);
	debateId$: Observable<string>;
	loading$: Observable<boolean>;
	title$: Observable<string>;
	editable$: Observable<boolean>;
	description$: Observable<string>;
	descriptionControl = new FormControl('');
	titleControl = new FormControl('');

	ngOnInit(): void {
		this.debateId$ = this.store.select(
			selectRouteNestedParam(URLConst.FIRESTORE_DEBATE)
		);
		this.loading$ = this.store.select(selectFirestoreLoading);
		this.title$ = this.store.select(selectFirestoreTitle);
		this.description$ = this.store.select(selectFirestoreDescription);
		this.editable$ = this.debateId$.pipe(
			switchMap((docId) =>
				this.firestoreService.canSaveDebateById(docId)
			),
			take(1)
		);

		this.title$
			.pipe(
				untilDestroyed(this),
				filter((title) => title !== this.titleControl.value)
			)
			.subscribe((title) =>
				this.titleControl.setValue(title, { emitEvent: false })
			);
		this.titleControl.valueChanges
			.pipe(debounceTime(1000))
			.subscribe((title) =>
				this.store.dispatch(firestoreActions.updateTitle({ title }))
			);
		this.description$
			.pipe(
				untilDestroyed(this),
				filter((descr) => descr !== this.descriptionControl.value)
			)
			.subscribe((descr) =>
				this.descriptionControl.setValue(descr, { emitEvent: false })
			);
		this.descriptionControl.valueChanges
			.pipe(debounceTime(1000))
			.subscribe((description) =>
				this.store.dispatch(
					firestoreActions.updateDescription({ description })
				)
			);
		// this.debateId$.subscribe(console.log);
	}
}
