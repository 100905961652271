import { Argument } from './argument';
import { RegisterArgumentNode } from '../../services/argument-node-registry.service';
import { AbstractArgumentNode } from './argument-node';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OpinionsService } from '../../services/opinions.service';
import { Statement } from '../statements/statement';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { argumentActions } from '../../store/argument/argument-actions';
import { Utils } from '../../utils/utils';
import { RegisterOpenaiParser } from '../../services/openai/openai.service';
import { OpenAiParser } from '../../services/openai/openai-parsers';

export interface Similarity {
	feature: string;
	valueA?: string;
	valueB?: string;
	statementAId?: string;
	statementBId?: string;
}

/**
 * The structure of the argument from precedent can be generalized as follows:
 *
 * •  P1: Case A and case B are similar in respect to facts f1, f2, ..., fn.
 *
 * •  P2: Case A was decided in favor of party X based on legal rule or principle R.
 *
 * •  C: Therefore, case B should be decided in favor of party X based on legal rule or principle R.
 *
 * This is an inductive argument that uses the similarity between two cases
 * and the outcome of the earlier case to support the outcome of the later case. The strength of the argument depends on the degree and relevance of the similarity, as well as the authority and validity of the precedent. The argument can be challenged by showing that the cases are not similar enough, or that the precedent is not applicable or binding.
 */
export interface ArgumentPrecedent extends Argument {
	type: 'ArgumentPrecedent';
	caseA: string;
	caseB: string;
	decisionA: string;
	similarities: Similarity[];
}

@RegisterArgumentNode()
export class ArgumentPrecedentNode extends AbstractArgumentNode<ArgumentPrecedent> {
	private opinionService = inject(OpinionsService);

	override getPremises$(): Observable<string[]> {
		return this.argument$.pipe(
			map(({ similarities }) =>
				similarities
					.reduce((premises, sim) => {
						premises.push(sim.statementAId, sim.statementBId);
						return premises;
					}, [])
					.filter(Boolean)
			)
		);
	}
}

@RegisterOpenaiParser()
class ArgumentPrecedentParser implements OpenAiParser<Statement> {
	matcher = /^\[ArgumentPrecedent\]/;
	private store = inject(Store);
	private actions = inject(Actions);

	parse(s: string, parentStatement: Statement) {
		console.log(`parsing ${s}`);
		// removing prefix
		const text = s.replace(this.matcher, '').trim();

		const actionId = Utils.generateId();
		const argument: ArgumentPrecedent = JSON.parse(
			text
		) as ArgumentPrecedent;
		argument.conclusion = parentStatement.id;
		/*
		const argument: ArgumentPrecedent = {
			type: 'ArgumentPrecedent',
			conclusion: parentStatement.id,
			caseA: 'AAA',
			caseB: 'BBB',
			similarities: [
				{
					feature: 'Statement was made in public',
					valueA: 'Has more than 20k subscribers',
					valueB: 'Has more than 50k subscribers',
				},
			],
			decisionA: 'In favor of John Doe',
		};
*/

		this.store.dispatch(argumentActions.add({ argument, actionId }));
	}
}
