import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { StoreModule } from '@ngrx/store';
import { statementReducer } from './statement/statement-reducer';
import { argumentReducer } from './argument/argument-reducer';
import { EffectsModule } from '@ngrx/effects';
import { Effects } from './shared/effects';
import { StatementPipe } from './pipes/statement-pipe';
import { ArgumentPipe } from './pipes/argument-pipe';
import { StatementEffects } from './statement/statement-effects';
import { ArgumentEffects } from './argument/argument-effects';
import { FirestoreEffects } from './firestore/firestore.effects';
import { firestoreReducer } from './firestore/firestore-reducer';
import { Features } from './shared/reducer';
import { opinionReducer } from './opinions/opinion-reducer';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects } from './router/router.effects';
import { settingsReducer } from './settings/settings-reducer';
import { SettingsEffects } from './settings/settings.effects';
import { CoreModule } from '../core/core.module';
import { openaiReducer } from './openai/opeai-reducer';

@NgModule({
	declarations: [StatementPipe, ArgumentPipe],
	exports: [StatementPipe, ArgumentPipe],
	imports: [
		CommonModule,
		CoreModule,
		StoreModule.forRoot({}),
		StoreModule.forFeature(Features.firestore, firestoreReducer),
		StoreModule.forFeature(Features.statements, statementReducer),
		StoreModule.forFeature(Features.args, argumentReducer),
		StoreModule.forFeature(Features.opinions, opinionReducer),
		StoreModule.forFeature(Features.router, routerReducer),
		StoreModule.forFeature(Features.settings, settingsReducer),
		StoreModule.forFeature(Features.openai, openaiReducer),
		EffectsModule.forRoot([
			Effects,
			StatementEffects,
			ArgumentEffects,
			FirestoreEffects,
			RouterEffects,
			SettingsEffects,
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
			serialize: {
				options: {
					map: true,
				},
			},
		}),
		StoreRouterConnectingModule.forRoot(),
	],
})
export class DebattStoreModule {}
