import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	HostListener,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { Statement } from '../../../model/statements/statement';
import {
	StatementTemplateActionsDirective,
	StatementTemplateContentDirective,
	StatementTemplateTitleDirective,
} from './statement-template-directives';
import { Router } from '@angular/router';
import { first, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { PathService } from '../../../services/path.service';
import { Store } from '@ngrx/store';
import { Tag } from '../../../services/component-registry';
import { TOKEN_TAGS } from '../../../services/argument-node-registry.service';
import { Utils } from '../../../utils/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { statementActions } from '../../../store/statement/statement-actions';
import { StatementComponentConfig } from '../../../services/dynamic-statement.component';
import { URLConst } from '../../../core/routes';
import { StatementComponentStore } from './statement-component-store';

/**
 * Default component for Statements
 * Imports
 * * Statement-template-title
 * * Statement-template-content
 * * Statement-template-actions
 *
 */
@UntilDestroy()
@Component({
	selector: 'statement-template-edit',
	templateUrl: './statement-template.edit.component.html',
	styleUrls: ['./statement-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTemplateEditComponent implements OnInit {
	@Input() isValid$: Observable<boolean>;
	@Input() config: StatementComponentConfig;
	@ContentChild(StatementTemplateTitleDirective) titleSlot;
	@ContentChild(StatementTemplateActionsDirective) actionsSlot;
	@ContentChild(StatementTemplateContentDirective) contentSlot;
	statement$: Observable<Statement>;
	titleControl = new FormControl('');
	descriptionControl = new FormControl('');
	canDelete$: Observable<boolean>;
	protected tags: Tag[] = inject(TOKEN_TAGS);
	private store = inject(Store);
	private statementComponentStore: StatementComponentStore<Statement> =
		inject(StatementComponentStore);
	private router = inject(Router);
	private dtRouter = inject(PathService);

	ngOnInit() {
		this.statementComponentStore.updateTitle(
			this.titleControl.valueChanges
		);
		this.statementComponentStore.updateDescription(
			this.descriptionControl.valueChanges
		);
		this.statement$ = this.statementComponentStore.statement$;
		this.canDelete$ = this.statementComponentStore.mode$.pipe(
			map((mode) => mode === 'edit')
		);
		this.statementComponentStore.initStatement$
			.pipe(first())
			.subscribe((initStatement) => {
				this.descriptionControl.setValue(initStatement.description);
				this.titleControl.setValue(initStatement.title);
			});
	}

	isEdit(): boolean {
		return Utils.matchTags(['edit'], this.tags);
	}

	async delete() {
		this.statement$.pipe(first()).subscribe(async (statement) => {
			this.store.dispatch(statementActions.remove({ statement }));
			await this.navigateToParent();
		});
	}

	/**Exits edit mode on Esc.*/
	@HostListener('window:keyup.esc')
	async onKeyUp() {
		await this.cancel();
	}

	async cancel() {
		if (this.isEdit()) await this.navigateToView();
		else {
			await this.navigateToParent();
		}
	}

	private async navigateToParent() {
		const path = await firstValueFrom(
			this.statement$.pipe(
				map((st) => st.id),
				switchMap(this.dtRouter.getPathTo)
			)
		);
		const pathToParent = path.slice(0, -1);
		if (pathToParent.length > 0)
			await this.router.navigate(
				[
					URLConst.VIEW_STATEMENT,
					pathToParent[pathToParent.length - 1],
				],
				{ queryParams: { [URLConst.PATH]: pathToParent } }
			);
		else {
			await this.router.navigate([URLConst.DEBATT_ROOT]);
		}
	}

	async navigateToView(s?: Statement) {
		const path = await firstValueFrom(
			s?.id
				? this.dtRouter.getPathTo(s.id)
				: this.statement$.pipe(
						map((st) => st.id),
						switchMap((id) => this.dtRouter.getPathTo(id))
				  )
		);
		await this.router.navigate(
			[URLConst.VIEW_STATEMENT, path[path.length - 1]],
			{
				queryParams: { [URLConst.PATH]: path },
			}
		);
	}

	async onSaveClicked() {
		this.statementComponentStore
			.save()
			.subscribe((st) => this.navigateToView(st));
	}
}
