import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentSimpleSupport } from '../../model/arguments/argument-simple-support';
import { BehaviorSubject, first, map, Observable } from 'rxjs';
import { ArgumentAbstractEditComponent } from '../argument-template/argument-abstract-edit.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ArgumentComponentStore } from '../argument-template/store/argument-component-store';
import { ArgumentSinglePremiseComponentStore } from '../argument-template/store/argument-single-premise-component-store';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentSimpleSupport',
	title: 'Support',
	tags: ['edit', 'create', 'argument', 'support'],
})
@Component({
	selector: 'debatt-argument-simple-support-edit',
	templateUrl: 'argument-simple-support-edit.component.html',
	styleUrls: ['./argument-simple-support.component.scss'],
	providers: [
		{
			provide: ArgumentComponentStore,
			useClass: ArgumentSinglePremiseComponentStore,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentSimpleSupportEditComponent
	extends ArgumentAbstractEditComponent<ArgumentSimpleSupport>
	implements OnInit
{
	// @ViewChild('selectStatementComponent') selectStatementComponent: SelectStatementComponent;
	isValid$: Observable<boolean>;
	initPremiseId$: Observable<string>;
	isAdvanced$ = new BehaviorSubject<boolean>(false);
	override componentStore = inject(
		ArgumentComponentStore
	) as ArgumentSinglePremiseComponentStore<ArgumentSimpleSupport>;

	override createArgument(): ArgumentSimpleSupport {
		const argument: ArgumentSimpleSupport = {
			conclusion: this.create.conclusion,
			// title: 'Support',
			// description: 'This supports the thesis',
			type: 'ArgumentSimpleSupport',
			premise: undefined,
		};
		return argument;
	}

	ngOnInit() {
		this.componentStore.argument$
			.pipe(first())
			.subscribe((arg) =>
				this.isAdvanced$.next(Boolean(arg.title || arg.description))
			);
		this.initPremiseId$ = this.componentStore.initArgument$.pipe(
			first(),
			map((a: ArgumentSimpleSupport) => a.premise)
		);
		this.isValid$ = this.componentStore.premiseStatement$.pipe(
			map((s) => !!s)
		);
	}

	setPremise(premiseSt) {
		this.componentStore.updatePremiseStatement(premiseSt);
	}

	onAdvanced() {
		this.isAdvanced$.next(true);
	}
}
