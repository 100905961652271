import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentModusPonens } from '../../model/arguments/argument';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import { Utils } from '../../utils/utils';

@RegisterDynamicComponent({
	dataType: 'ArgumentModusPonens',
	title: 'Modus ponens',
	tags: ['argument', 'support'],
})
@Component({
	selector: 'debatt-argument-modus-ponens',
	templateUrl: './argument-modus-ponens.component.html',
	styleUrls: ['./argument-modus-ponens.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentModusPonensComponent extends ArgumentAbstractComponent<
	any,
	ArgumentModusPonens
> {
	createArgument(parentStatemenId: string): ArgumentModusPonens {
		const argument = {
			type: 'ArgumentModusPonens',
			id: Utils.generateId(),
			conclusion: parentStatemenId,
			title: 'Modus ponens',
			description: 'Modus ponens inference',
		};
		return argument;
	}
}

@RegisterDynamicComponent({ dataType: 'ArgumentModusPonens', tags: ['label'] })
@Component({
	selector: 'debatt-argument-modus-ponens-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'accent'"
			[icon]="'arrow_right_alt'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentModusPonensLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
