import { createAction, props } from '@ngrx/store';

export const opinionActions = {
	/**
	 */
	voteUp: createAction(
		'[Opinion] Vote Up',
		props<{ statementId: string; opinion: string }>()
	),

	voteDown: createAction(
		'[Opinion] Vote Down',
		props<{ statementId: string; opinion: string }>()
	),
};
