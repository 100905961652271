import { Argument } from './argument';
import { RegisterArgumentNode } from '../../services/argument-node-registry.service';
import { AbstractArgumentNode } from './argument-node';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OpinionsService } from '../../services/opinions.service';

export enum ConditionOperators {
	AND,
	OR,
	NOT,
	XOR,
}

export interface TermPremise {
	term: string;
	premiseId: string;
	displayTermTitle?: string;
	operator?: ConditionOperators;
	group?: string;
}

export interface ArgumentDefinition extends Argument {
	/**The definition text where [text](id "display name") fragments are words/terms/concepts/claims to be supported by premises.
	 *	Example:
	 * <pre>
	 *      Aggression is the action of a state in [violating
	 *      by force](by-force) the rights of another state,
	 *      particularly its [territorial rights](rights).
	 *		https://www.thefreedictionary.com/Agression
	 * </pre>
	 */
	definition: string;
	/**The word or phrase being defined.*/
	term?: string;
	/**A classification or grouping that the term belongs to.*/
	category?: string;
	/**Maps {{terms}} from the definition to supporting premises.*/
	premises: TermPremise[];
}

@RegisterArgumentNode()
export class ArgumentByDefinitionNode extends AbstractArgumentNode<ArgumentDefinition> {
	private opinionService = inject(OpinionsService);

	override getPremises$(): Observable<string[]> {
		return this.argument$.pipe(
			map(({ premises }) => premises.map((p) => p.premiseId))
		);
	}
}
