import { createAction, props } from '@ngrx/store';
import { FirestoreDebateDocumentWithId } from '../../model/firestore/firestore';

export const firestoreActions = {
	// Firestore actions
	saveToFirestore: createAction('[Firestore] Save'),
	saveToFirestoreCompleted: createAction(
		'[Firestore] Save Completed',
		props<{ document: FirestoreDebateDocumentWithId }>()
	),
	saveToFirestoreFailed: createAction(
		'[Firestore] Save Failed',
		props<{ message: string }>()
	),
	loadFromFirestore: createAction(
		'[Firestore] Load',
		props<{ docId: string }>()
	),
	loadFromFirestoreCompleted: createAction(
		'[Firestore] Load Completed',
		props<{
			document: FirestoreDebateDocumentWithId;
		}>()
	),
	deleteFromFirestore: createAction(
		'[Firestore] Delete',
		props<{ docId: string }>()
	),
	deleteFromFirestoreCompleted: createAction(
		'[Firestore] Delete',
		props<{ docId: string }>()
	),

	updateTitle: createAction(
		'[Firestore] Update Title',
		props<{ title: string }>()
	),
	updateDescription: createAction(
		'[Firestore] Update Description',
		props<{ description: string }>()
	),
};
