export const URLConst = {
	PATH: 'path',
	HOME: '/',
	DEBATT_ROOT: 'debatt',
	FIRESTORE_ROOT: 'firestore',
	FIRESTORE_DEBATE: 'debateId',
	LOGIN: 'login',
	DASHBOARD: 'dashboard',
	BROWSE_FIRESTORE: 'browse-firestore',
	BROWSE_LOCAL_STORE: 'browse-local-store',
	BROWSE_EXAMPLES: 'browse-examples',
	VIEW_ARGUMENT: 'view-argument',
	VIEW_ARGUMENT_ID: 'argId',
	EDIT_ARGUMENT: 'edit-argument',
	EDIT_ARGUMENT_ID: 'argId',
	CREATE_ARGUMENT: 'create-argument',
	CREATE_ARGUMENT_STATEMENT_ID: 'statementId',
	CREATE_ARGUMENT_ARGUMENT_TYPE: 'argumentType',
	VIEW_STATEMENT: 'view',
	VIEW_STATEMENT_ID: 'statementId',
	EDIT_STATEMENT: 'edit',
	EDIT_STATEMENT_ID: 'statementId',
	LOAD_FILE: 'load',
	FILE_NAME: 'name',
	CREATE_FROM_TEMPLATE: 'template',
	OPENAI_PLAYGROUND: 'openai',
	DEBATE_MAP: 'map',
};
