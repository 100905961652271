import { Statement } from './statement';
import { RegisterStatementNode } from '../../services/statement-node-registry.service';
import { AbstractStatementNode } from './statement-node';

export interface StatementProbability extends Statement {
	type: 'StatementProbability';
}

@RegisterStatementNode()
export class StatementProbabilityNode extends AbstractStatementNode<StatementProbability> {}
