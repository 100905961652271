import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
	Auth,
	authState,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	User,
} from '@angular/fire/auth';
import { EMPTY, map, Observable, Subscription } from 'rxjs';
import { traceUntilFirst } from '@angular/fire/performance';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'dt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
	private auth = inject(Auth);
	private readonly userDisposable: Subscription | undefined;
	public readonly user$: Observable<User | null> = EMPTY;

	showLoginButton = false;
	showLogoutButton = false;

	constructor() {
		if (this.auth) {
			this.user$ = authState(this.auth);
			this.userDisposable = authState(this.auth)
				.pipe(
					traceUntilFirst('auth'),
					map((u) => !!u),
					untilDestroyed(this)
				)
				.subscribe((isLoggedIn) => {
					this.showLoginButton = !isLoggedIn;
					this.showLogoutButton = isLoggedIn;
				});
		}
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.userDisposable) {
			this.userDisposable.unsubscribe();
		}
	}

	async login() {
		return await signInWithPopup(this.auth, new GoogleAuthProvider());
	}

	async logout() {
		return await signOut(this.auth);
	}
}
