import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Output,
} from '@angular/core';
import { Statement } from '../../model/statements/statement';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { OpinionsService } from '../../services/opinions.service';
import { StatementComponentConfig } from '../../services/dynamic-statement.component';
import { AbstractStatementComponent } from '../shared/statement-template/abstract-statement.component';
import { StatementDefault } from '../../model/statements/statement-default';
import { TOKEN_COMPONENT_CONFIG } from '../shared/statement-template/abstract-statement-edit.component';

@RegisterDynamicComponent({
	dataType: 'StatementDefault',
	title: 'Statement',
	tags: ['view'],
})
@Component({
	selector: 'statement',
	templateUrl: './statement-default.component.html',
	styleUrls: ['./statement-default.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementDefaultComponent extends AbstractStatementComponent<StatementDefault> {
	@Output() editToggle = new EventEmitter();
}
