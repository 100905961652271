import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Argument } from '../../model/arguments/argument';
import { createReducer, on } from '@ngrx/store';
import { argumentActions } from './argument-actions';
import { statementActions } from '../statement/statement-actions';
import { globalActions } from '../shared/actions';
import { argdownActions } from '../argdown/argdown.actions';
import { firestoreActions } from '../firestore/firestore-actions';

export type ArgumentState = EntityState<Argument>;

export const argumentsAdapter: EntityAdapter<Argument> =
	createEntityAdapter<Argument>();
export const argumentsInitialState: ArgumentState =
	argumentsAdapter.getInitialState();

export const argumentReducer = createReducer(
	argumentsInitialState,
	on(
		globalActions.loadFromLocalStorageCompleted,
		globalActions.loadFile,
		(state, { newState }) => {
			return newState.args;
		}
	),

	/*	on(argumentActions.create, (state => argumentsAdapter.addOne({
			type: 'Argument',
			id: Utils.generateId(),
			title: 'Argument',
			description: 'Argument description',
		}, state))),*/
	on(argumentActions.addSuccess, (state, { argument }) =>
		argumentsAdapter.addOne(argument, state)
	),
	on(argumentActions.addMany, (state, { args }) =>
		argumentsAdapter.addMany(args, state)
	),
	on(argumentActions.updateSuccess, (state, { argument }) =>
		argumentsAdapter.updateOne(
			{
				id: argument.id,
				changes: argument,
			},
			state
		)
	),
	on(argumentActions.upsert, (state, { argument }) =>
		argumentsAdapter.upsertOne(argument, state)
	),
	on(argumentActions.remove, (state, { argument }) =>
		argumentsAdapter.removeOne(argument.id, state)
	),
	on(globalActions.reset, () => argumentsInitialState),
	on(statementActions.addArgument, (state, { argument }) =>
		argumentsAdapter.addOne(argument, state)
	),
	on(statementActions.remove, (state, { statement }) =>
		argumentsAdapter.removeMany(statement.args, state)
	),

	//	ArgDown
	on(argdownActions.load2, (state, { args }) =>
		argumentsAdapter.addMany(args, argumentsInitialState)
	),
	on(firestoreActions.loadFromFirestoreCompleted, (state, { document }) => {
		return document.state.args;
	})
);
