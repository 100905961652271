import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	HostListener,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { Argument } from '../../model/arguments/argument';
import {
	ArgumentTemplateActionsDirective,
	ArgumentTemplateContentDirective,
	ArgumentTemplateDescriptionDirective,
	ArgumentTemplateTitleDirective,
} from './argument-template-directives';
import { ActivatedRoute, Router } from '@angular/router';
import { argumentActions } from '../../store/argument/argument-actions';
import {
	BehaviorSubject,
	combineLatest,
	first,
	firstValueFrom,
	map,
	Observable,
	switchMap,
	tap,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { PathService } from '../../services/path.service';
import { Store } from '@ngrx/store';
import { Tag } from '../../services/component-registry';
import { TOKEN_TAGS } from '../../services/argument-node-registry.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { selectSettingsShowActionLabels } from '../../store/settings/settings-selectors';
import { ArgumentComponentStore } from './store/argument-component-store';
import { URLConst } from '../../core/routes';

/**
 *
 * Default component for arguments
 * Imports
 * * argument-template-title
 * * argument-template-content
 * * argument-template-actions
 *
 */
@UntilDestroy()
@Component({
	selector: 'argument-template-edit',
	templateUrl: './argument-template.edit.component.html',
	styleUrls: ['./argument-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentTemplateEditComponent implements OnInit {
	/**Initialize the component. For changes watch title$ and description$
	 * @deprecated use {@link ArgumentComponentStore#save}
	 */
	@Input() onSave: () => Observable<Argument>;
	@Input() isValid: Observable<boolean>;
	@ContentChild(ArgumentTemplateTitleDirective, { static: false }) titleSlot;
	protected descriptionSlot$ = new BehaviorSubject(undefined);
	@ContentChild(ArgumentTemplateDescriptionDirective, { static: false })
	set dSlot(child) {
		this.descriptionSlot$.next(child);
	}
	@ContentChild(ArgumentTemplateActionsDirective, { static: false })
	actionsSlot;
	@ContentChild(ArgumentTemplateContentDirective, { static: false })
	contentSlot;
	descriptionControl = new FormControl('');
	titleControl = new FormControl('');
	canDelete$;
	argument$: Observable<Argument>;
	protected tags: Tag[] = inject(TOKEN_TAGS);
	// private argumentAbstractEditComponent = inject(ArgumentAbstractEditComponent);
	private argumentStore = inject(ArgumentComponentStore);
	private store = inject(Store);
	private rout = inject(ActivatedRoute);
	private router = inject(Router);
	private dtRouter = inject(PathService);

	constructor() {
		this.argumentStore.updateTitle(this.titleControl.valueChanges);
		this.argumentStore.updateDescription(
			this.descriptionControl.valueChanges
		);
		this.argument$ = this.argumentStore.argument$;
	}

	ngOnInit() {
		this.canDelete$ = this.argumentStore.mode$.pipe(
			map((mode) => mode === 'edit')
		);
		this.argumentStore.initArgument$
			.pipe(first())
			.subscribe((initArgument) => {
				this.descriptionControl.setValue(initArgument.description);
				this.titleControl.setValue(initArgument.title);
			});
	}

	delete() {
		this.argument$
			.pipe(first())
			.subscribe((arg) =>
				this.store.dispatch(argumentActions.remove({ argument: arg }))
			);
	}

	/**Exits edit mode on Esc.*/
	@HostListener('window:keyup.esc')
	onKeyUp() {
		this.cancel();
	}

	async cancel() {
		this.argumentStore.cancel();
		combineLatest([this.argumentStore.argument$, this.argumentStore.mode$])
			.pipe(first())
			.subscribe(async ([argument, mode]) => {
				const path = await firstValueFrom(
					this.dtRouter.getPathTo(argument.conclusion)
				);
				if (mode === 'create') {
					this.router.navigate(
						[URLConst.VIEW_STATEMENT, argument.conclusion],
						{ queryParams: { [URLConst.PATH]: path } }
					);
				} else {
					this.router.navigate(
						[URLConst.VIEW_ARGUMENT, argument.id],
						{ queryParams: { [URLConst.PATH]: path } }
					);
				}
			});
	}

	onSaveClicked() {
		this.argumentStore
			.save()
			.pipe(
				switchMap((arg) =>
					this.dtRouter
						.getPathTo(arg.conclusion)
						.pipe(
							tap((path) =>
								this.router.navigate(
									[URLConst.VIEW_ARGUMENT, arg.id],
									{ queryParams: { [URLConst.PATH]: path } }
								)
							)
						)
				)
			)
			.subscribe();
	}
}
