import { Pipe, PipeTransform } from '@angular/core';
import { selectArgument } from '../argument/argument-selectors';
import { Store } from '@ngrx/store';

@Pipe({
	name: 'argumentById',
	pure: true,
})
export class ArgumentPipe implements PipeTransform {
	constructor(private store: Store) {}

	transform(argumentId: string) {
		return this.store.select(selectArgument(argumentId));
	}
}
