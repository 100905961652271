import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, shareReplay, switchMap } from 'rxjs';
import {
	selectRootStatementId,
	selectStatement,
} from '../../store/statement/statement-selectors';
import { PathService } from '../../services/path.service';
import { Statement } from 'src/app/model/statements/statement';
import { Meta } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	selectRouteArgumentId,
	selectRouteStatementId,
} from '../../store/router/router.selectors';

@UntilDestroy()
@Component({
	selector: 'dt-view-statement',
	templateUrl: './view-statement.component.html',
	styleUrls: ['./view-statement.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewStatementComponent {
	statementId$: Observable<string>;
	private store = inject(Store);
	private meta = inject(Meta);

	constructor() {
		this.statementId$ = this.store.select(selectRouteStatementId).pipe(
			untilDestroyed(this),
			switchMap((id) =>
				id ? of(id) : this.store.select(selectRootStatementId)
			),
			shareReplay(1)
		);
		this.statementId$
			.pipe(switchMap((id) => this.store.select(selectStatement(id))))
			.subscribe(
				(st) =>
					st &&
					this.meta.updateTag({
						name: 'description',
						content: `${st.title} ${st.description}`,
					})
			);
	}
}
