import { RegisterArgumentNode } from '../../services/argument-node-registry.service';
import { AbstractArgumentNode } from './argument-node';
import { Argument } from './argument';
import { map, Observable } from 'rxjs';

/**
 * The simplest kind of argument.
 * This says that the premises support thesis.
 */
export interface ArgumentSimpleSupport extends Argument {
	type: 'ArgumentSimpleSupport';
	/**Statement IDs*/
	premise?: string;
}

/**Argument is mutable.*/
@RegisterArgumentNode()
export class ArgumentSimpleSupportNode extends AbstractArgumentNode<ArgumentSimpleSupport> {
	/**
	 * @deprecated
	 */

	/*
		override save() {
			// this.store.dispatch(argumentActions.add({argument: this.argument}));
		}
	*/

	override getPremises$(): Observable<string[]> {
		return this.argument$.pipe(map((a) => (a?.premise ? [a.premise] : [])));
	}
}
