import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { OpinionsService } from '../../../services/opinions.service';
import { ConfigService } from '../../../services/config.service';
import { OpinionConfig } from '../../../model/descriptions/schema/opinion.configs.schema';

@Component({
	selector: 'debatt-opinion-tile',
	templateUrl: './opinion-tile.component.html',
	styleUrls: ['./opinion-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpinionTileComponent implements OnInit {
	@Input() name: string;
	@Input() votes: number;
	@Output() plusOne = new EventEmitter<string>();

	private config: OpinionConfig;

	constructor(private opinionService: OpinionsService) {}

	ngOnInit(): void {
		this.config = ConfigService.instance.getOpinionConfig(this.name);
	}

	private onPlusOne() {
		this.plusOne.emit(this.name);
	}
}
