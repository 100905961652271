import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from './settings-reducer';
import { Features } from '../shared/reducer';

export const selectSettingsState = createFeatureSelector<SettingsState>(
	Features.settings
);

export const selectSettingsValue = <K extends keyof SettingsState>(key: K) =>
	createSelector(selectSettingsState, (state) => state[key]);

export const selectSettingsTheme = createSelector(
	selectSettingsState,
	({ theme }) => theme
);

export const selectSettingsShowActionLabels = createSelector(
	selectSettingsState,
	({ theme }) => theme !== 'compact'
);

export const selectSettingsAutoload = createSelector(
	selectSettingsState,
	({ autoload }) => autoload
);

export const selectSettingsAutosaveFirestore = createSelector(
	selectSettingsState,
	({ autosaveFirestore }) => autosaveFirestore
);

export const selectSettingsDeveloper = createSelector(
	selectSettingsState,
	({ developer }) => developer ?? {}
);
export const selectSettingsOpenAi = createSelector(
	selectSettingsDeveloper,
	({ openAi }) => openAi
);
