export type OpenaiPrompt = {
	instructions: string[];
	displayName: string;
	model: string;
};
export type OpenaiPrompts = {
	[key: string]: OpenaiPrompt;
};
export const prompts: OpenaiPrompts = {
	'3pro3con': {
		displayName: 'Get 3 arguments for and 3 arguments against',
		model: 'gpt-3.5-turbo',
		instructions: [
			'Give 3 arguments to prove the statement.',
			'Each argument is less than 100 words. Each argument starts with [Pro].',
			'Then give 3 arguments to prove it wrong. Each argument starts with [Con].',
			'Separate arguments by two empty lines.',
			'Provide links to sources in markdown style.',
			`Output format:
			[Pro or Con] Some argument [link title](link)

			[Pro or Con] Some argument [link title](link)

			...
			`,
		],
	},

	precedent: {
		displayName: 'Find similar precedent',
		model: 'gpt-3.5-turbo',
		instructions: [
			'Th given case is caseB. It is marked by """',
			'You have to find another case that is  most similar to the caseB. This will be case A',
			// 'Provide links to sources in markdown style.',
			`Output format:
			[ArgumentPrecedent]
			{
				"type": "ArgumentPrecedent",
				"description": "<links to the sources about case A>",
				"caseA": <The title of the case A>,
				"caseB": <The title of the case B>,
				"decisionA": <The decision in the case A>,
				"similarities": [
					{
						"feature": <What is similar between caseA and caseB>,
						"valueA": <What is in caseA>,
						"valueB": <What is in caseB>,
					},
					...
				]
			}
			`,
		],
	},
};

export const availablePrompts = Object.keys(prompts);
