import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	inject,
	Input,
	OnInit,
	Optional,
} from '@angular/core';
import { Argument } from '../../model/arguments/argument';
import {
	ArgumentNodeRegistryService,
	TOKEN_ARGUMENT_ID,
	TOKEN_TAGS,
} from '../../services/argument-node-registry.service';
import { ArgumentNode } from '../../model/arguments/argument-node';
import {
	ArgumentTemplateActionsDirective,
	ArgumentTemplateContentDirective,
	ArgumentTemplateDescriptionDirective,
	ArgumentTemplateTitleDirective,
} from './argument-template-directives';
import { Store } from '@ngrx/store';
import { Statement } from '../../model/statements/statement';
import { Utils } from '../../utils/utils';
import { Tag } from '../../services/component-registry';
import { filter, map, Observable } from 'rxjs';
import { selectSettingsShowActionLabels } from '../../store/settings/settings-selectors';
import { selectArgument } from '../../store/argument/argument-selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

//TODO: swap arguments
export class ArgumentAbstractComponent<
	N extends ArgumentNode<R>,
	R extends Argument
> {
	protected id = inject(TOKEN_ARGUMENT_ID);
	protected tags: Tag[] = inject(TOKEN_TAGS);
	protected node$: Observable<N>;
	protected nodeRegistry = inject(ArgumentNodeRegistryService);
	protected store = inject(Store);
	protected argument$: Observable<R>;

	// constructor(public argument: R, public db: DBService) {
	constructor() {
		this.argument$ = this.store
			.select<R>(selectArgument(this.id))
			.pipe(untilDestroyed(this), filter(Boolean));
		this.node$ = this.nodeRegistry.getOrCreateNode<N>(this.id);
	}

	/**@deprecated*/
	createSimpleStatement(): Statement {
		return {
			type: 'StatementDefault',
			id: Utils.generateId(),
			title: 'unnamed',
			args: [],
		};
	}

	isCreate(): boolean {
		return Utils.matchTags(['create'] as Tag[], this.tags);
	}

	isEdit(): boolean {
		return Utils.matchTags(['edit'] as Tag[], this.tags);
	}

	isView(): boolean {
		return Utils.matchTags(['view'] as Tag[], this.tags);
	}
}

/**
 * Default component for arguments
 * Imports
 * * argument-template-title
 * * argument-template-content
 * * argument-template-actions
 *
 */
@Component({
	selector: 'argument-template',
	templateUrl: './argument-template.component.html',
	styleUrls: ['./argument-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentTemplateComponent implements OnInit {
	@Input() argument: Argument;
	@ContentChild(ArgumentTemplateTitleDirective) titleSlot;
	@ContentChild(ArgumentTemplateDescriptionDirective) descriptionSlot;
	@ContentChild(ArgumentTemplateActionsDirective) actionsSlot;
	@ContentChild(ArgumentTemplateContentDirective) contentSlot;
	private store = inject(Store);
	isExpanded$: Observable<boolean>;

	ngOnInit(): void {
		this.isExpanded$ = this.store.select(selectSettingsShowActionLabels);
	}
}

@UntilDestroy()
/**
 * Simple base label component with an icon.
 */
@Component({
	selector: 'argument-template-label',
	template: `
		<mat-label [ngStyle]="{ color: color }" class="argument-label">
			<mat-icon inline>{{ icon }}</mat-icon>
			<span>{{ title$ | ngrxPush }}</span>
		</mat-label>
	`,
	styleUrls: ['./argument-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultArgumentLabel {
	@Optional() @Input() id: string;
	@Input() color: string;
	@Input() icon: string;
	private argumentId = inject(TOKEN_ARGUMENT_ID);
	private store = inject(Store);
	title$;

	constructor() {
		this.title$ = this.store
			.select(selectArgument(this.id ?? this.argumentId))
			.pipe(
				untilDestroyed(this),
				map((a) => a?.title)
			);
	}
}
