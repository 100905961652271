import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { Statement } from '../../../model/statements/statement';
import {
	StatementTemplateActionsDirective,
	StatementTemplateContentDirective,
	StatementTemplateTitleDirective,
} from './statement-template-directives';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { OpinionsService } from '../../../services/opinions.service';
import { Observable } from 'rxjs';
import { selectSettingsShowActionLabels } from '../../../store/settings/settings-selectors';

import { URLConst } from '../../../core/routes';
import { TOKEN_COMPONENT_CONFIG } from './abstract-statement-edit.component';

/**
 * Default component for Statements
 * Imports
 * * Statement-template-title
 * * Statement-template-content
 * * Statement-template-actions
 *
 */
// @RegisterDynamicComponent(Statement)
@Component({
	selector: 'statement-template',
	templateUrl: './statement-template.component.html',
	styleUrls: ['./statement-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTemplateComponent {
	@Input() statement: Statement;
	config = inject(TOKEN_COMPONENT_CONFIG);
	@ContentChild(StatementTemplateTitleDirective) titleSlot;
	@ContentChild(StatementTemplateActionsDirective) actionsSlot;
	@ContentChild(StatementTemplateContentDirective) contentSlot;
	opinionsService = inject(OpinionsService);
	private store = inject(Store);
	URLConst = URLConst;
}

/**
 * Simple base label component with an icon.
 */
@Component({
	selector: 'Statement-template-label',
	template: `
		<mat-label class="Statement-label" [ngStyle]="{ color: color }">
			<mat-icon inline>{{ icon }}</mat-icon>
			<span>{{ Statement.title }}</span>
		</mat-label>
	`,
	styleUrls: ['./statement-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultStatementLabel {
	@Input() Statement: Statement;
	@Input() color: string;
	@Input() icon: string;
}
