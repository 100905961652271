import { createAction, props } from '@ngrx/store';
import { Argument } from '../../model/arguments/argument';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Statement } from '../../model/statements/statement';

export type OnStatementIds<T extends Argument> = (
	argument: T,
	newStatements: Map<unknown, Statement>
) => T;

export const argumentActions = {
	// create: createAction('[Argument] Create'),	//not used
	/**
	 * @param {boolean} [assignIds=true] invoke backend to assign ids to premises.
	 */
	upsert: createAction(
		'[Argument] Upsert',
		props<{ argument: Argument; newPremises: Statement[] }>()
	),

	/**@see /docs/ngrx-create-argument.action.puml	 */
	add: createAction(
		'[Argument] Add',
		props<{
			argument: Argument;
			newPremises?: Map<unknown, Statement>;
			updateFn?: OnStatementIds<Argument>;
			actionId?: string;
		}>()
	),
	addSuccess: createAction(
		'[Argument] Add Success',
		props<{ argument: Argument; actionId?: string }>()
	),

	/**@see /docs/ngrx-create-argument.action.puml	 */
	update: createAction(
		'[Argument] Update',
		props<{
			argument: Argument;
			newPremises?: Map<unknown, Statement>;
			updateFn?: OnStatementIds<Argument>;
			actionId?: string;
		}>()
	),
	updateSuccess: createAction(
		'[Argument] Update Success',
		props<{ argument: Argument; actionId?: string }>()
	),

	// TODO: replace with id
	remove: createAction('[Argument] Remove', props<{ argument: Argument }>()),
	addMany: createAction('[Argument] Add Many', props<{ args: Argument[] }>()),
};
