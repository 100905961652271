import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, shareReplay, startWith } from 'rxjs';
import * as Sqrl from 'squirrelly';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
	selectAllStatements,
	selectRootStatement,
} from '../../store/statement/statement-selectors';
import { Statement } from '../../model/statements/statement';
import { selectAllArguments } from '../../store/argument/argument-selectors';
import { Argument } from '../../model/arguments/argument';
import { jsPDF } from 'jspdf';
import { Clipboard } from '@angular/cdk/clipboard';
import { marked } from 'marked';

/**
 * @see https://squirrelly.js.org/docs
 * @see https://www.npmjs.com/package/jspdf
 */
@UntilDestroy()
@Component({
	selector: 'dt-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateComponent implements OnInit {
	private initTemplate = `
<div>

<p>Here we will prove that {{it.rootStatement.title}}.</p>
<p>
{{!argId = it.rootStatement.args[0]}}
{{!arg = it.args[0]}}
First we use a definition of [here will be the term] given at [link]:
<cite>{{*arg.definition|markdown}}</cite>
</p>
</div>
\t`;
	templateControl = new FormControl('');
	rendered$;
	private store = inject(Store);
	private clipboard = inject(Clipboard);
	@ViewChild('rendered', { read: ElementRef }) renderedElement;

	ngOnInit() {
		this.configureSqrl();
		this.rendered$ = combineLatest([
			this.templateControl.valueChanges.pipe(
				startWith(this.initTemplate)
			),
			this.store.select(selectRootStatement),
			this.store.select(selectAllStatements),
			this.store.select(selectAllArguments),
		]).pipe(
			map(([template, rootStatement, statements, args]) =>
				this.render(template, { rootStatement, statements, args })
			),
			shareReplay(1)
		);
		this.templateControl.setValue(this.initTemplate);
	}

	private configureSqrl() {
		Sqrl.filters.define('markdown', (text) => text && marked.parse(text));
	}

	private render(
		template: string,
		vars: {
			args: Argument[];
			rootStatement: Statement;
			statements: Statement[];
		}
	): string {
		try {
			const result = Sqrl.render(template, vars);
			// let compiled = Sqrl.compile(template);
			// const result = compiled(vars, Sqrl.getConfig({async: true}));
			return result;
		} catch (e) {
			return e.message;
		}
	}

	public previewPdf() {
		this.generatePdf((doc) => doc.output('dataurlnewwindow'));
	}

	public saveToPdf() {
		this.generatePdf((doc) => doc.save('debate.pdf'));
	}

	public copyToClipboard() {
		this.rendered$
			.pipe(first())
			.subscribe((text) => this.clipboard.copy(text));
	}

	public generatePdf(callback) {
		const textEl = this.renderedElement.nativeElement;
		const doc = new jsPDF({ format: 'A4' });
		doc.html(textEl, {
			callback,
			margin: 0,
			width: 210,
			windowWidth: 500,
		});
	}
}
