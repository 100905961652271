import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { AbstractStatementComponent } from '../shared/statement-template/abstract-statement.component';
import {
	StatementTestParameters,
	TestParameters,
} from '../../model/statements/statement-test-parameters';
import { URLConst } from '../../core/routes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'StatementTestParameters',
	title: 'TestParameters',
	tags: ['view'],
})
@Component({
	selector: 'statement-test-parameters',
	templateUrl: './statement-test-parameters.component.html',
	styleUrls: ['./statement-test-parameters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTestParametersComponent
	extends AbstractStatementComponent<StatementTestParameters>
	implements OnInit
{
	protected readonly URLConst = URLConst;
	dataSource: { name: string; value: number; tooltip?: string }[];
	protected columns = ['name', 'value'];

	ngOnInit(): void {
		this.statement$.pipe(untilDestroyed(this)).subscribe((st) => {
			return (this.dataSource = [
				{
					name: 'True positive rate (TPR)',
					value: st.parameters.tpr,
					tooltip:
						'Sensitivity (true positive rate) is the probability of a positive test result, conditioned on the individual truly being positive.',
				},
				{
					name: 'True negative rate (TNR)',
					value: st.parameters.tnr,
					tooltip:
						'Specificity (true negative rate) is the probability of a negative test result, conditioned on the individual truly being negative.',
				},
				{
					name: 'Prevalence',
					value: st.parameters.prevalence,
					tooltip:
						'Prevalence is the proportion of a particular population found to be affected by a medical condition (typically a disease or a risk factor such as smoking or seatbelt use) at a specific time',
				},
			]);
		});
	}
}
