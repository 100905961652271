import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Statement } from '../../model/statements/statement';
import { createReducer, on } from '@ngrx/store';
import { statementActions } from './statement-actions';
import { globalActions } from '../shared/actions';
import { argumentActions } from '../argument/argument-actions';
import { argdownActions } from '../argdown/argdown.actions';
import { Utils } from '../../utils/utils';
import { firestoreActions } from '../firestore/firestore-actions';
import { doc } from '@angular/fire/firestore';

export interface StatementState extends EntityState<Statement> {
	/**The id of the root statement*/
	rootStatementId?: string;
}

export const statementsAdapter: EntityAdapter<Statement> =
	createEntityAdapter<Statement>();
export const statementInitialState: StatementState =
	statementsAdapter.getInitialState();

export const statementReducer = createReducer(
	statementInitialState,
	//Global actions
	on(globalActions.reset, () => statementInitialState),
	on(
		globalActions.loadFromLocalStorageCompleted,
		globalActions.loadFile,
		(state, { newState }) => {
			return newState.statements;
		}
	),

	//StatementComponent actions

	on(statementActions.setRootStatement, (state, { rootStatementId }) => {
		const newState: StatementState = {
			...state,
			rootStatementId,
		};
		return newState;
	}),

	on(statementActions.addSuccess, (state, { statement }) => {
		const newState = statementsAdapter.addOne(statement, state);
		if (!newState.rootStatementId) newState.rootStatementId = statement.id;
		return newState;
	}),

	on(statementActions.upsertSuccess, (state, { statement }) => {
		const newState = statementsAdapter.upsertOne(statement, state);
		if (!newState.rootStatementId) newState.rootStatementId = statement.id;
		return newState;
	}),

	on(statementActions.addManySuccess, (state, { statements }) =>
		statementsAdapter.addMany([...statements.values()], state)
	),
	on(statementActions.update, (state, { update }) =>
		statementsAdapter.updateOne(update, state)
	),
	on(statementActions.remove, (state, { statement }) => {
		const newState = statementsAdapter.removeOne(statement.id, state);
		if (statement.id === newState.rootStatementId)
			newState.rootStatementId = undefined;
		return newState;
	}),
	on(statementActions.addArgument, (state, { premises, argument }) => {
		state = statementsAdapter.addMany(premises, state);
		const conclusionId = argument.conclusion;
		const conclusion = state.entities[conclusionId];
		return statementsAdapter.updateOne(
			{
				id: conclusion.id,
				changes: {
					args: [...conclusion.args, argument.id],
				},
			},
			state
		);
	}),

	//ArgumentComponent actions
	on(argumentActions.remove, (state, { argument }) => {
		const statement = state.entities[argument.conclusion];
		const args = statement.args.filter((argId) => argId !== argument.id);

		return statementsAdapter.updateOne(
			{
				id: statement.id,
				changes: {
					args: args,
				},
			},
			state
		);
	}),

	/**split into two actions
	 * - add: saves the premises to the backend and receives the ids
	 * - addSuccess
	 * - ? : problem here is that different types of arguments might
	 * store premises differently, so we cannot set the premises ids
	 * in reducer or effect
	 * - profit
	 */
	on(argumentActions.addSuccess, (state, { argument }) => {
		const statement = state.entities[argument.conclusion];

		state = statementsAdapter.updateOne(
			{
				id: argument.conclusion,
				changes: {
					args: [...statement.args, argument.id],
				},
			},
			state
		);
		return state;
	}),
	on(argumentActions.upsert, (state, { argument }) => {
		const statement = state.entities[argument.conclusion];
		if (statement.args.includes(argument.id)) return state;
		else
			return statementsAdapter.updateOne(
				{
					id: statement.id,
					changes: {
						args: [...statement.args, argument.id],
					},
				},
				state
			);
	}),

	//ArgDown
	on(argdownActions.load2, (state, { statements, rootStatementId }) => {
		state = statementsAdapter.addMany(statements, statementInitialState);
		state.rootStatementId = rootStatementId;
		return state;
	}),

	on(firestoreActions.loadFromFirestoreCompleted, (state, { document }) => {
		return document.state.statements;
	})
);
