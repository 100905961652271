import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { selectSettingsState } from '../../store/settings/settings-selectors';
import { first, Observable } from 'rxjs';
import { settingsActions } from '../../store/settings/settings-actions';
import {
	SettingsState,
	SettingsTheme,
} from '../../store/settings/settings-reducer';

@Component({
	selector: 'dt-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
	private store = inject(Store);
	// settings;
	settings$: Observable<SettingsState>;
	theme = new FormControl('');
	autosaveFirestore = new FormControl(false);

	ngOnInit(): void {
		this.settings$ = this.store.select(selectSettingsState);
		this.settings$.pipe(first()).subscribe((settings) => {
			// this.settings = settings;
			this.theme.setValue(settings.theme, { emitEvent: false });
			this.autosaveFirestore.setValue(settings.autosaveFirestore, {
				emitEvent: false,
			});
		});
		this.theme.valueChanges.subscribe((v: SettingsTheme) =>
			this.store.dispatch(settingsActions.set({ settings: { theme: v } }))
		);
		this.autosaveFirestore.valueChanges.subscribe((v: boolean) =>
			this.store.dispatch(
				settingsActions.set({ settings: { autosaveFirestore: v } })
			)
		);
	}

	disableAutoload() {
		this.store.dispatch(
			settingsActions.set({ settings: { autoload: undefined } })
		);
	}
}
