import { createAction, props } from '@ngrx/store';

import { DebattState } from './state';

export const globalActions = {
	reset: createAction('[Global] Reset'),

	saveToLocalStorage: createAction('[Global] Save to localstorage'),
	saveToLocalStorageCompleted: createAction(
		'[Global] Save to localstorage Completed'
	),
	saveToLocalStorageFailed: createAction(
		'[Global] Save to localstorage Failed'
	),
	loadFromLocalStorage: createAction(
		'[Global] Load form localstorage',
		props<{ id: string }>()
	),
	loadFromLocalStorageFailed: createAction(
		'[Global] Load form localstorage Failed'
	),
	loadFromLocalStorageCompleted: createAction(
		'[Global] Load form localstorage Completed',
		props<{ newState: DebattState }>()
	),
	loadFile: createAction(
		'[Global] Load form file',
		props<{ newState: DebattState }>()
	),

	startEdit: createAction('[Global] Start edit'),
	cancelEdit: createAction('[Global] Cancel edit'),
	applyEdit: createAction('[Global] Apply edit'),
	/**Saves the state at the current moment to */
	stash: createAction('[Global] Stash'),
	unstash: createAction('[Global] Unstash'),
};
