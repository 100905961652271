import { inject, Injectable } from '@angular/core';
import { globalActions } from '../store/shared/actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';
import { selectSettingsAutoload } from '../store/settings/settings-selectors';
import { settingsActions } from '../store/settings/settings-actions';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
	private store = inject(Store);
	private debattNames$ = new BehaviorSubject<string[]>([]);
	private readonly LOCAL_STORAGE_CACHE = 'debatt.saves.';

	getDebatts() {
		return this.debattNames$;
	}

	autoloadIfEnabled() {
		this.store
			.select(selectSettingsAutoload)
			.pipe(
				first(),
				filter((autoLoad) => !!autoLoad)
			)
			.subscribe((autoload) =>
				this.store.dispatch(
					globalActions.loadFromLocalStorage({ id: autoload })
				)
			);
	}

	loadFromCache(name: string) {
		this.store.dispatch(globalActions.loadFromLocalStorage({ id: name }));
	}

	disableAutoLoad() {
		this.store.dispatch(
			settingsActions.set({ settings: { autoload: undefined } })
		);
	}

	setAutoLoad(name: string) {
		this.store.dispatch(
			settingsActions.set({ settings: { autoload: name } })
		);
	}

	/**
	 * Looks at {@link LOCAL_STORAGE_CACHE} items for saved debates
	 */
	updateCachedDebates() {
		const cashedDebatts = [];

		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key.startsWith(this.LOCAL_STORAGE_CACHE)) {
				const name = key.slice(this.LOCAL_STORAGE_CACHE.length);
				cashedDebatts.push(name);
			}
		}
		this.debattNames$.next(cashedDebatts);
	}

	deleteCachedDebatt(id: string) {
		localStorage.removeItem(this.LOCAL_STORAGE_CACHE + id);
		this.store.dispatch(settingsActions.disableAutoloadFor({ id }));
		this.updateCachedDebates();
	}
}
