import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentOccamRazor } from '../../model/arguments/argument';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';

@RegisterDynamicComponent({
	dataType: 'ArgumentOccamRazor',
	title: "Occam's razor",
	tags: ['argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-occam-razor',
	templateUrl: './argument-occam-razor.component.html',
	styleUrls: ['./argument-occam-razor.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentOccamRazorComponent extends ArgumentAbstractComponent<
	any,
	ArgumentOccamRazor
> {}

@RegisterDynamicComponent({
	dataType: 'ArgumentOccamRazor',
	tags: ['label'],
})
@Component({
	selector: 'argument-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'teal'"
			[icon]="'vertical_split'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentOccamRazorLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
