import { Directive } from '@angular/core';

@Directive({
	selector: '[statement-template-actions]',
})
export class StatementTemplateActionsDirective {}

@Directive({
	selector: '[statement-template-content]',
})
export class StatementTemplateContentDirective {}

@Directive({
	selector: '[statement-template-title]',
})
export class StatementTemplateTitleDirective {}
