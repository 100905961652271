//otherwise these files are totally ignored
import './model/arguments/argument-simple-support.ts';
import './model/arguments/argument-node.implementations';
import './model/arguments/argument-definition';
import './model/arguments/argument-calculate-probability';
import './model/arguments/argument-precedent';
import './model/statements/statement-default';
import './model/statements/statement-probability';
import './services/openai/openai-parsers';
import './model/statements/statement-test-parameters';

import 'reflect-metadata';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StatementDefaultComponent } from './statement/default/statement-default.component';
import {
	BrowserAnimationsModule,
	NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import {
	ArgumentTemplateComponent,
	DefaultArgumentLabel,
} from './argument/argument-template/argument-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	ArgumentAnalogyComponent,
	ArgumentAnalogyLabel,
} from './argument/argument-analogy/argument-analogy.component';
import { DynamicArgumentComponent } from './services/dynamic-argument.component';
import {
	ArgumentAdAbsurdumComponent,
	ArgumentAdAbsurdumLabel,
} from './argument/argument-ad-absurdum/argument-ad-absurdum.component';
import { HttpClientModule } from '@angular/common/http';
import {
	ArgumentSimpleSupportComponent,
	ArgumentSimpleSupportLabel,
} from './argument/argument-simple-support/argument-simple-support.component';
import {
	ArgumentOccamRazorComponent,
	ArgumentOccamRazorLabel,
} from './argument/argument-occam-razor/argument-occam-razor.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	ArgumentModusPonensComponent,
	ArgumentModusPonensLabel,
} from './argument/argument-modus-ponens/argument-modus-ponens.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import {
	ErrorSnackBarComponent,
	GlobalErrorHandler,
} from './visuals/error-snack-bar/error-snack-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
	ArgumentSimpleAttackComponent,
	ArgumentSimpleAttackLabel,
} from './argument/argument-simple-attack/argument-simple-attack.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ArgumentDescriptionPipe } from './model/pipes/argument-description.pipe';
import { OpinionsArgumentComponent } from './argument/opinions/argument/opinions-argument.component';
import { ConfigPipe } from './model/pipes/config.pipe';
import { OpinionTileComponent } from './argument/opinions/opinion-tile/opinion-tile.component';
import {
	ArgumentExampleComponent,
	ArgumentExampleLabel,
} from './argument/argument-example/argument-example.component';
import { StringifyPipe } from './debug/StringifyPipe';
import {
	ArgumentTemplateActionsDirective,
	ArgumentTemplateContentDirective,
	ArgumentTemplateDescriptionDirective,
	ArgumentTemplateTitleDirective,
} from './argument/argument-template/argument-template-directives';
import { LetModule, PushModule } from '@ngrx/component';
import { DebattStoreModule } from './store/debatt-store.module';
import { environment } from '../environments/environment';

import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { TopViewComponent } from './pages/top-view/top-view.component';
import { EditArgumentComponent } from './pages/edit-argument/edit-argument.component';
import { EditStatementComponent } from './pages/edit-statement/edit-statement.component';
import { ViewArgumentComponent } from './pages/view-argument/view-argument.component';
import { ArgumentTemplateEditComponent } from './argument/argument-template/argument-template.edit.component';
import { ListArgumentsComponent } from './statement/shared/list-arguments/list-arguments.component';
import { ArgumentSimpleSupportEditComponent } from './argument/argument-simple-support/argument-simple-support-edit.component';
import { StatementDefaultEditComponent } from './statement/default/statement-default.edit.component';
import { DynamicStatementComponent } from './services/dynamic-statement.component';
import { SelectStatementComponent } from './statement/shared/select-statement/select-statement.component';
import { ViewStatementComponent } from './pages/edit-statement/view-statement.component';
import { PathToPipe, QueryParamPathToPipe } from './model/pipes/path-to.pipe';
import { ArgumentBarComponent } from './statement/shared/argument-bar/argument-bar.component';
import { IdPipe } from './model/pipes/id.pipe';
import { IsConclusionForPipe } from './model/pipes/is-conclusion-for.pipe';
import { ArgumentSimpleAttackEditComponent } from './argument/argument-simple-attack/argument-simple-attack-edit.component';
import { StatementProbabilitiesComponent } from './statement/probabilities/statement-probabilities.component';
import { StatementProbabilitiesEditComponent } from './statement/probabilities/statement-probabilities.edit.component';
import {
	ArgumentCalculateProbabilitiesLabel,
	ArgumentCalculateProbabilityComponent,
} from './argument/argument-calculate-probability/argument-calculate-probability.component';
import { ArgumentCalculateProbabilityEditComponent } from './argument/argument-calculate-probability/argument-calculate-probability-edit.component';
import { DynamicFormComponent } from './argument/argument-calculate-probability/dynamic-form/dynamic-form.component';
import {
	StatementTemplateActionsDirective,
	StatementTemplateContentDirective,
	StatementTemplateTitleDirective,
} from './statement/shared/statement-template/statement-template-directives';
import { StatementTemplateEditComponent } from './statement/shared/statement-template/statement-template.edit.component';
import { StatementTemplateComponent } from './statement/shared/statement-template/statement-template.component';
import { AboutComponent } from './pages/about/about.component';
import { LoadFileComponent } from './pages/load-file.component';
import { MarkdownModule } from 'ngx-markdown';
import {
	ArgumentDefinitionComponent,
	ArgumentDefinitionLabelComponent,
} from './argument/argument-definition/argument-definition.component';
import { ArgumentDefinitionEditComponent } from './argument/argument-definition/argument-definition-edit.component';
import { MatSelectModule } from '@angular/material/select';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { LoginComponent } from './pages/login/login.component';
import { BrowseDebatesComponent } from './pages/dashboard/browse-debates/browse-debates.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowseLocalstorageComponent } from './pages/dashboard/browse-localstorage/browse-localstorage.component';
import { MatTableModule } from '@angular/material/table';
import { BrowseExamplesComponent } from './pages/dashboard/browse-examples/browse-examples.component';
import { MatListModule } from '@angular/material/list';
import { OpinionsStatementComponent } from './statement/shared/opinions/opinions-statement.component';
import { FilterOpinionsConfigsPipe } from './model/pipes/filter-opinions.pipe';
import { DebateComponent } from './pages/firestore/debate/debate.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsComponent } from './pages/settings/settings.component';
import { TemplateComponent } from './pages/template/template.component';
import { CoreModule } from './core/core.module';
import { IfExpandedDirective } from './visuals/directives/if-expanded.directive';
import { OpenAiPlayComponent } from './pages/open-ai-play/open-ai-play.component';
import { OpenAiSettingsComponent } from './pages/open-ai-play/open-ai-settings/open-ai-settings.component';
import { OpenaiIconComponent } from './visuals/openai-icon/openai-icon.component';
import { LoadingRotateDirective } from './visuals/directives/loading-rotate.directive';
import { DebateMapComponent } from './pages/debate-map/debate-map.component';
import { DebateMapNodeComponent } from './pages/debate-map/debate-map-node/debate-map-node.component';
import {
	ArgumentPrecedentComponent,
	ArgumentPrecedentLabelComponent,
} from './argument/argument-precedent/argument-precedent.component';
import { ArgumentPrecedentEditComponent } from './argument/argument-precedent/argument-precedent-edit.component';
import { OpenaiButtonComponent } from './statement/shared/openai-button/openai-button.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProfileMenuComponent } from './visuals/profile-menu/profile-menu.component';
import { NgOptimizedImage } from '@angular/common';
import { StatementTestParametersComponent } from './statement/statement-test-parameters/statement-test-parameters.component';
import { StatementTestParametersEditComponent } from './statement/statement-test-parameters/statement-test-parameters-edit.component';

@NgModule({
	declarations: [
		AppComponent,
		LoadFileComponent,
		DefaultArgumentLabel,
		StatementTemplateEditComponent,
		StatementTemplateComponent,
		StatementTemplateActionsDirective,
		StatementTemplateTitleDirective,
		StatementTemplateContentDirective,
		StatementDefaultComponent,
		StatementDefaultEditComponent,
		ListArgumentsComponent,
		ArgumentAnalogyComponent,
		ArgumentAnalogyLabel,
		DynamicArgumentComponent,
		DynamicStatementComponent,
		ArgumentAdAbsurdumComponent,
		ArgumentAdAbsurdumLabel,
		ArgumentSimpleSupportComponent,
		ArgumentSimpleSupportLabel,
		ArgumentSimpleSupportEditComponent,
		ArgumentSimpleAttackComponent,
		ArgumentSimpleAttackLabel,
		ArgumentSimpleAttackEditComponent,
		ArgumentOccamRazorComponent,
		ArgumentOccamRazorLabel,
		ArgumentModusPonensComponent,
		ArgumentModusPonensLabel,
		ArgumentExampleComponent,
		ArgumentExampleLabel,
		ErrorSnackBarComponent,
		// Pipes
		ArgumentDescriptionPipe,
		ConfigPipe,
		PathToPipe,
		QueryParamPathToPipe,
		IdPipe,
		IsConclusionForPipe,
		StringifyPipe,
		FilterOpinionsConfigsPipe,

		OpinionsArgumentComponent,
		OpinionsStatementComponent,
		OpinionTileComponent,
		ArgumentTemplateComponent,
		ArgumentTemplateEditComponent,
		ArgumentTemplateTitleDirective,
		ArgumentTemplateDescriptionDirective,
		ArgumentTemplateContentDirective,
		ArgumentTemplateActionsDirective,
		TopViewComponent,
		ViewArgumentComponent,
		EditArgumentComponent,
		ViewStatementComponent,
		EditStatementComponent,
		SelectStatementComponent,
		StatementProbabilitiesComponent,
		StatementProbabilitiesEditComponent,
		ArgumentCalculateProbabilityComponent,
		ArgumentCalculateProbabilityEditComponent,
		ArgumentCalculateProbabilitiesLabel,
		ArgumentDefinitionComponent,
		ArgumentDefinitionEditComponent,
		ArgumentDefinitionLabelComponent,
		ArgumentPrecedentComponent,
		ArgumentPrecedentEditComponent,
		ArgumentPrecedentLabelComponent,
		ArgumentBarComponent,
		DynamicFormComponent,
		AboutComponent,
		LoginComponent,
		BrowseDebatesComponent,
		DashboardComponent,
		BrowseLocalstorageComponent,
		BrowseExamplesComponent,
		DebateComponent,
		SettingsComponent,
		TemplateComponent,
		IfExpandedDirective,
		OpenAiPlayComponent,
		OpenAiSettingsComponent,
		OpenaiIconComponent,
		LoadingRotateDirective,
		DebateMapComponent,
		DebateMapNodeComponent,
		OpenaiButtonComponent,
		ProfileMenuComponent,
		StatementTestParametersComponent,
		StatementTestParametersEditComponent,
	],
	imports: [
		BrowserModule,
		NoopAnimationsModule,
		BrowserAnimationsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatButtonToggleModule,
		MatMenuModule,
		MatInputModule,
		MatGridListModule,
		MatDialogModule,
		ReactiveFormsModule,
		MatCardModule,
		MatTabsModule,
		MatExpansionModule,
		MatIconModule,
		MatTooltipModule,
		MatDividerModule,
		MatSnackBarModule,
		// import HttpClientModule after BrowserModule.
		HttpClientModule,
		MatToolbarModule,
		MatSlideToggleModule,
		MatSelectModule,
		LetModule,
		PushModule,
		MatTableModule,
		MatListModule,
		MatProgressBarModule,
		MarkdownModule.forRoot(),

		CoreModule,
		RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),

		DebattStoreModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAuth(() => getAuth()),
		provideFirestore(() =>
			initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
		), // Not working
		provideFunctions(() => getFunctions()),
		FormsModule,
		MatSidenavModule,
		NgOptimizedImage,
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
	],
})
export class AppModule {}
