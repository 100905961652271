import { createAction, props } from '@ngrx/store';
import { Statement } from '../../model/statements/statement';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Argument } from '../../model/arguments/argument';

export const statementActions = {
	/**
	 * @param {(Statement) => string} getId - a function to get temporary id for each statement.
	 * @param actionId an ID for this action to distinguish its result from other requests.
	 */
	assingId: createAction(
		'[Statement] Assign ID',
		props<{
			statements: Statement[];
			actionId: string;
			getId?: (Statement) => string;
		}>()
	),

	/**@param {Map<string, string>} map mapping from temporary ids to final
	 * @param actionId the same actionId as passed to {@link assingId}
	 */
	assingIdSuccess: createAction(
		'[Statement] Assign ID Success',
		props<{
			statements: Statement[];
			map: Map<string, string>;
			actionId: string;
		}>()
	),

	setRootStatement: createAction(
		'[Statement] SetRoot',
		props<{ rootStatementId: string }>()
	),

	update: createAction(
		'[Statement] Update',
		props<{ update: UpdateStr<Statement> }>()
	),
	upsert: createAction(
		'[Statement] Upsert',
		props<{ statement: Statement; actionId?: string }>()
	),

	upsertSuccess: createAction(
		'[Statement] Upsert Success',
		props<{ statement: Statement; actionId?: string }>()
	),

	add: createAction(
		'[Statement] Add',
		props<{ statement: Statement; actionId?: string }>()
	),

	addSuccess: createAction(
		'[Statement] Add Success',
		props<{ statement: Statement; actionId?: string }>()
	),

	remove: createAction(
		'[Statement] Remove',
		props<{ statement: Statement }>()
	),

	addMany: createAction(
		'[Statement] Add Many',
		props<{ statements: Map<unknown, Statement>; actionId: string }>()
	),

	addManySuccess: createAction(
		'[Statement] Add Many Success',
		props<{ statements: Map<unknown, Statement>; actionId: string }>()
	),

	/**Unclear what it does*/
	addArgument: createAction(
		'[Statement] Add Argument',
		props<{ premises: Statement[]; argument: Argument }>()
	),
};
