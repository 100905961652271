import { statementsAdapter, StatementState } from './statement-reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Features } from '../shared/reducer';
import { Statement } from '../../model/statements/statement';

// export const reducers: ActionReducerMap<DebattState> = {
// 	statements: statementReducer,
// };

export const selectStatementState = createFeatureSelector<StatementState>(
	Features.statements
);

export const selectStatementsIds = createSelector(
	selectStatementState,
	statementsAdapter.getSelectors().selectIds
	// statementsAdapter.selectIds
);
export const selectStatementEntities = createSelector(
	selectStatementState,
	statementsAdapter.getSelectors().selectEntities
);
export const selectStatement = <S extends Statement>(id: string) =>
	createSelector(
		selectStatementEntities,
		(statementEntities) => id && (statementEntities[id] as S)
	);

export const selectAllStatements = createSelector(
	selectStatementState,
	statementsAdapter.getSelectors().selectAll
);
export const selectStatementTotal = createSelector(
	selectStatementState,
	statementsAdapter.getSelectors().selectTotal
);
export const selectRootStatementId = createSelector(
	selectStatementState,
	(state: StatementState) => state.rootStatementId
);

export const selectRootStatement = createSelector(
	selectStatementEntities,
	selectRootStatementId,
	(statementEntities, rootId) => rootId && statementEntities[rootId]
);

export const selectStatementArgumentIds = (id: string) =>
	createSelector(selectStatement(id), (statement) =>
		statement ? statement.args : []
	);
