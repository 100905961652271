import { TestParameters } from '../../../model/statements/statement-test-parameters';

export class ProbabilityService {
	/**
	 * The Positive predictive value (PPV) of a test
	 * is the proportion of persons who are actually positive
	 * out of all those testing positive
	 */
	// calculatePPV(tpr: number, tnr: number, prevalence: number) {
	public static calculatePPV({
		tpr,
		tnr,
		prevalence,
	}: TestParameters): number {
		const pPositive = tpr * prevalence + (1 - tnr) * (1 - prevalence);
		if (!pPositive) return Number.NaN;
		const p = (tpr * prevalence) / pPositive;
		return p;
	}
}
