import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { map, Observable } from 'rxjs';
import { ArgumentSimpleAttackNode } from '../../model/arguments/argument-node.implementations';
import { ArgumentSimpleAttack } from '../../model/arguments/argument';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentSimpleAttack',
	title: 'Support',
	tags: ['view', 'argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-simple-attack-view',
	templateUrl: './argument-simple-attack.component.html',
	styleUrls: ['./argument-simple-attack.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ArgumentSimpleAttackComponent
	extends ArgumentAbstractComponent<
		ArgumentSimpleAttackNode,
		ArgumentSimpleAttack
	>
	implements OnInit
{
	premiseId$: Observable<string>;

	ngOnInit(): void {
		this.premiseId$ = this.argument$.pipe(
			map((a: ArgumentSimpleAttack) => a?.premise)
		);
	}
}

@RegisterDynamicComponent({ dataType: 'ArgumentSimpleAttack', tags: ['label'] })
@Component({
	selector: 'debatt-argument-simple-attack-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'red'"
			[icon]="'check_circle_outline'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentSimpleAttackLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
