import { ChangeDetectionStrategy, Component } from '@angular/core';

import { URLConst } from '../../../core/routes';

@Component({
	selector: 'dt-browse-examples',
	templateUrl: './browse-examples.component.html',
	styleUrls: ['./browse-examples.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseExamplesComponent {
	protected readonly URLConst = URLConst;
}
