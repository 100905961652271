import { marked } from 'marked';
import { Injectable } from '@angular/core';
import Token = marked.Token;
import Tokens = marked.Tokens;

/**
 * Renders links
 * ```
 * [text](link "title")
 * ```
 * as
 *
 * ```
 * <abbr title="title || link || text">text</abbr>
 * ```
 */
@Injectable({
	providedIn: 'root',
})
export class MarkedService {
	constructor() {
		const renderer = new marked.Renderer();
		const originalLink = renderer.link.bind(renderer);
		marked.use({
			renderer: {
				link(
					href: string | null,
					title: string | null,
					text: string
				): string {
					if (href?.match(/^https?:\/\//))
						return originalLink(href, title, text);
					else
						return `<abbr title="${title || href || text}" id="${
							href || text
						}">${text}</abbr>`;
				},
			},
			/*			tokenizer: {
							link(src: string): marked.Tokens.Image | marked.Tokens.Link {

								const match = src.match(/^\$+([^\$\n]+?)\$+/);
								if (match) {
									return {
										type: 'codespan',
										raw: match[0],
										text: match[1].trim()
									};
								}
								return false;
							}
						}*/
		});
	}

	public filterLinkTokens(tokens: Token[]): Tokens.Link[] {
		return tokens.reduce((a: Tokens.Link[], token: Token) => {
			if (token.type === 'link') {
				if (!token.href?.startsWith('http://')) a.push(token);
			}
			// there are also other types of tokens,
			// e.g. table, that could have different nesting structure
			else if (token['tokens'])
				a.push(...this.filterLinkTokens(token['tokens']));
			return a;
		}, []);
	}

	public parse(text: string): Token[] {
		if (text) {
			const tokens: Token[] = marked.lexer(text);
			return tokens;
		} else return [];
	}

	public renderTokens(tokens: Token[]): string {
		return marked.parser(tokens);
	}

	public render(text: string): string {
		return marked.parse(text);
	}
}
