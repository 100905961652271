import { TypedObject } from '../arguments/argument';
import { InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface ActionState {
	/**Should be greyed out if not enabled*/
	isEnabled: boolean;
	/**Should be completely removed*/
	isVisible: boolean;
}
const defaultState = { isEnabled: true, isVisible: true };

export interface DebattAction extends TypedObject {
	getState$(): Observable<ActionState>;
}

export const TOKEN_ACTIONS = new InjectionToken<DebattAction[]>('actions');

export abstract class ActionAbstract implements DebattAction {
	type: string;

	constructor(type: string) {
		this.type = type;
	}

	getState$(): Observable<ActionState> {
		return of(defaultState);
	}
}

export abstract class ActionAbstractVolatile implements DebattAction {
	type: string;
	protected state$: BehaviorSubject<ActionState>;

	constructor(type: string, state$?: BehaviorSubject<ActionState>) {
		this.type = type;
		this.state$ = state$ ?? new BehaviorSubject<ActionState>(defaultState);
	}

	getState$(): Observable<ActionState> {
		return this.state$;
	}
}
