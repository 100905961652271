import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { first, firstValueFrom, from, map, Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectRootStatementId } from '../store/statement/statement-selectors';
import { Statement } from '../model/statements/statement';
import { URLConst } from '../core/routes';

@Injectable({ providedIn: 'root' })
export class PathService {
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store
	) {}

	getPathArray$(): Observable<string[]> {
		return this.route.queryParamMap.pipe(
			map((qm) => qm.getAll(URLConst.PATH))
			// map(p => p ? p.split(',') : [])
		);
	}

	getPathTo(target: string | Statement): Observable<string[]> {
		return this.getPathArray$().pipe(
			map(async (p) => {
				let result: string[];
				const id = typeof target === 'string' ? target : target.id;
				if (p.length === 0) {
					const rootId = await firstValueFrom(
						this.store.select(selectRootStatementId)
					);
					if (!rootId) result = [id];
					else if (id === rootId) {
						result = [rootId];
					} else result = [rootId, id];
				} else {
					const index = p.indexOf(id);
					if (index < 0) result = [...p, id];
					else result = p.slice(0, index + 1);
				}
				return result;
			}),
			switchMap((a) => from(a)),
			first()
		);
	}

	getCurrentStatementId() {
		return this.route.paramMap.pipe(
			map((p) => p[URLConst.VIEW_STATEMENT_ID])
		);
	}
}
