import { createReducer, on } from '@ngrx/store';
import { firestoreActions } from '../firestore/firestore-actions';
import { globalActions } from '../shared/actions';
import { argdownActions } from '../argdown/argdown.actions';
import {
	FirestoreDocumentAccess,
	FirestoreDocumentMeta,
} from '../../model/firestore/firestore';

export interface FirestoreState
	extends FirestoreDocumentMeta,
		FirestoreDocumentAccess {
	/**The current firestore debate being edited.*/
	id?: string;
	loading: boolean;
}

export const firestoreInitialState: FirestoreState = {
	id: undefined,
	owner: undefined,
	access: 'private',
	loading: false,
};

export const firestoreReducer = createReducer(
	firestoreInitialState,
	on(firestoreActions.updateTitle, (state, { title }) => ({
		...state,
		title,
	})),
	on(firestoreActions.updateDescription, (state, { description }) => ({
		...state,
		description,
	})),

	on(
		firestoreActions.saveToFirestoreCompleted,
		(st, { document: { state, ...fsDoc } }) => {
			return {
				...fsDoc,
				loading: false,
			};
		}
	),
	on(firestoreActions.loadFromFirestore, (state) => {
		return { ...state, loading: true };
	}),
	on(
		firestoreActions.loadFromFirestoreCompleted,
		(st, { document: { state, ...doc } }) => {
			return { ...st, ...doc, loading: false };
		}
	),
	on(
		globalActions.loadFromLocalStorage,
		globalActions.reset,
		argdownActions.load,
		(state): FirestoreState => {
			return firestoreInitialState;
		}
	),
	on(
		firestoreActions.deleteFromFirestoreCompleted,
		(state, { docId }): FirestoreState => {
			return state.id === docId ? firestoreInitialState : state;
		}
	)
);
