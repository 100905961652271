import { Injectable } from '@angular/core';
import { Argument } from '../model/arguments/argument';
import { OpinionArgument } from '../model/opinions/opinionArgument';
import { OpinionsEnum } from '../model/descriptions/schema/opinion.configs.schema';

/**
 * Collects votes and opinions for statements and arguments.
 */
@Injectable({
	providedIn: 'root',
})
export class OpinionsService {
	private static iconMap = {
		true: 'thumb_up_alt',
		false: 'thumb_down_alt',
		support: 'plus_one',
		invalid: 'error_outline',
	};

	constructor() {}

	public type2icon(type: string): string {
		return OpinionsService.iconMap[type];
	}

	/**Calculates all present types of votes for the given id
	 * (Statement or Argument)
	 */
	getCounts(id: string): Map<string, number> {
		return new Map<string, number>([
			['true', 2],
			['false', 4],
			['invalid', 1],
		]);
	}

	// public getArgOpinions(argumentId: string){
	public getArgOpinions(argument: Argument): Map<OpinionsEnum, number> {
		// new Descriptions().getArgumentDescription(argument.type).
		return new OpinionArgument(argument.type).votes;
	}

	public addVote(opinion: string) {}

	asArray(id: string) {
		return Array.from(this.getCounts(id).entries());
	}
}
