import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	EMPTY,
	exhaustMap,
	first,
	mergeMap,
	of,
	switchMap,
	tap,
	throttleTime,
} from 'rxjs';
import { globalActions } from './actions';
import { Router } from '@angular/router';
import { argdownActions } from '../argdown/argdown.actions';
import { argumentActions } from '../argument/argument-actions';
import { StatementNodeRegistryService } from '../../services/statement-node-registry.service';
import { DebattState } from './state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { URLConst } from '../../core/routes';

@Injectable()
export class Effects {
	private readonly LOCAL_STORAGE_CACHE = 'debatt.saves.';
	private actions$ = inject(Actions);
	private store = inject(Store);
	private router = inject(Router);
	private snackBar = inject(MatSnackBar);
	private statementNodeRegistryService = inject(StatementNodeRegistryService);

	constructor() {}

	reset$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(globalActions.reset),
				mergeMap(async (action) => {
					this.statementNodeRegistryService.resetExisting();
					await this.router.navigate([URLConst.DEBATT_ROOT]);
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);

	saveToLocalStorage = createEffect(() => {
		return this.actions$.pipe(
			ofType(globalActions.saveToLocalStorage),
			exhaustMap((action) => this.store.select((state) => state)),
			first(),
			exhaustMap((state: DebattState) => {
				const rootId = state.statements.rootStatementId;
				if (rootId && Object.keys(state.statements.entities).length) {
					const title = state.statements.entities[rootId].title;
					localStorage.setItem(
						this.LOCAL_STORAGE_CACHE + title,
						JSON.stringify(state, null, '\t')
					);
					return of(globalActions.saveToLocalStorageCompleted());
				} else return of(globalActions.saveToLocalStorageFailed());
			})
		);
	});
	loadFromLocalStorage = createEffect(() => {
		return this.actions$.pipe(
			ofType(globalActions.loadFromLocalStorage),
			switchMap(async (action) => {
				// if (!this.settingsService.value.autoLoad)
				await this.router.navigate([URLConst.DEBATT_ROOT]);
				const data = localStorage.getItem(
					this.LOCAL_STORAGE_CACHE + action.id
				);
				try {
					if (data) {
						const newState =
							typeof data === 'string' ? JSON.parse(data) : data;
						return globalActions.loadFromLocalStorageCompleted({
							newState,
						});
					} else return globalActions.loadFromLocalStorageFailed();
				} catch (e) {
					console.log(e);
					return globalActions.loadFromLocalStorageFailed();
				}
			})
		);
	});

	loadFromLocalStorageCompleted$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(globalActions.loadFromLocalStorageCompleted),
				throttleTime(2000),
				tap(() => {
					this.snackBar.open(
						'Successfully loaded from the browser local storage',
						undefined,
						{ duration: 2000 }
					);
				})
			);
		},
		{ dispatch: false }
	);

	loadArgdown = createEffect(() => {
		return this.actions$.pipe(
			ofType(argdownActions.load),
			mergeMap(async (action) => {
				await this.router.navigate([URLConst.DEBATT_ROOT]);
				return argdownActions.load2(action);
			})
		);
	});

	navigateOnRemoveArgument = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(argumentActions.remove),
				mergeMap(async (action) => {
					await this.router.navigate([
						URLConst.VIEW_STATEMENT,
						action.argument.conclusion,
					]);
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);
}
