import { Pipe, PipeTransform } from '@angular/core';
import { Argument } from '../arguments/argument';
import { ConfigService } from '../../services/config.service';
import { ArgumentConfig } from '../descriptions/schema/argument.configs.schema';
import { StatementConfig } from '../descriptions/schema/statement.configs.schema';
import { OpinionConfig } from '../descriptions/schema/opinion.configs.schema';
import { StatementDefault } from '../statements/statement-default';

// see https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/

/*
// type ExtractConfigType<SOURCE, CLASS, CONFIG> = SOURCE extends CLASS ? CONFIG : never;
type ExtractConfigType<SOURCE> = ExtractArgumentConfig<SOURCE> | ExtractStatementConfig<SOURCE>;

// type ExtractArgumentType<S extends Argument> = ArgumentConfig;
type ExtractArgumentConfig<S> = S extends Argument ? ArgumentConfig : never;
type ExtractStatementConfig<S> = S extends Statement ? StatementConfig : never;
*/

/**
 * <p>Usage example:
 * ```
 * matTooltip="{{(argument | config)?.title}}"
 * ```
 * </p>
 */
@Pipe({
	name: 'config',
})
export class ConfigPipe implements PipeTransform {
	transform(obj: Argument): ArgumentConfig;
	transform(obj: StatementDefault): StatementConfig;

	transform(obj) {
		if (typeof obj === 'string') {
			//Argument
			const config = ConfigService.instance.getOpinionConfig(obj);
			return (
				config ??
				({
					title: obj,
					titleCasual: obj,
					description: `A description for ${obj}`,
					icon: 'edit',
				} as OpinionConfig)
			);
		} else if ('conclusion' in obj) {
			//Argument
			const arg = obj as Argument;
			const config = ConfigService.instance.getArgumentConfig(arg.type);
			return (
				config ??
				({
					title: arg.title,
					titleCasual: arg.title,
					description: arg.description,
					fallacies: [],
				} as ArgumentConfig)
			);
		} else if ('args' in obj)
			return { title: 'titleSt' } as StatementConfig;
	}

	/*
		transform(obj: Statement): StatementConfig {
			return {} as StatementConfig;
		}
	*/

	/*
		transform<T extends Argument | Statement>(obj: T): ExtractConfigType<T> {
			if(obj instanceof Argument){
				return {} as ArgumentConfig;
			}

		}

	*/
}
