import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentExample } from '../../model/arguments/argument';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { statementActions } from '../../store/statement/statement-actions';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';

@RegisterDynamicComponent({
	dataType: 'ArgumentExample',
	title: 'Example',
	tags: ['argument', 'example'],
})
@Component({
	selector: 'debatt-argument-example',
	templateUrl: './argument-example.component.html',
	styleUrls: ['./argument-example.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// encapsulation: ViewEncapsulation.None
})
export class ArgumentExampleComponent extends ArgumentAbstractComponent<
	any,
	ArgumentExample
> {
	// @Type(() => Boolean)

	addSubStatement() {
		const statement = this.createSimpleStatement();
		this.store.dispatch(
			statementActions.add({
				statement,
			})
		);

		// this.argument.premises.push(newStatement.id);
	}
}

@RegisterDynamicComponent({ dataType: 'ArgumentExample', tags: ['label'] })
@Component({
	selector: 'debatt-argument-example-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'orange'"
			[icon]="'check_circle_outline'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentExampleLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
