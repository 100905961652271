import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	inject,
	Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ArgumentNodeRegistryService } from '../../../services/argument-node-registry.service';
import { selectStatement } from '../../../store/statement/statement-selectors';
import {
	combineLatest,
	combineLatestWith,
	filter,
	map,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { selectArgumentsForStatementId } from '../../../store/shared/selectors';
import { RelationType } from '../../../model/descriptions/schema/argument.configs.schema';
import { ConfigService } from '../../../services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface ArgNode {
	id: string;
	relation: RelationType;
	title: string;
	premises: string[];
	level?: number;
}

interface Node {
	id: string;
	title: string;
	pros: ArgNode[];
	cons: ArgNode[];
	level?: number;
}

@UntilDestroy()
@Component({
	selector: 'dt-debate-map-node',
	templateUrl: './debate-map-node.component.html',
	styleUrls: ['./debate-map-node.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebateMapNodeComponent {
	@Input() statementId: string;
	private DEFAULT_DEPTH = 5;
	@Input() depth: number = this.DEFAULT_DEPTH;
	private store = inject(Store);
	private argumentNodeRegistryService = inject(ArgumentNodeRegistryService);
	protected node$: Observable<Node>;
	@HostBinding('style.font-size')
	fontSize;

	ngOnInit() {
		this.fontSize = `${(16 * this.depth) / this.DEFAULT_DEPTH + 8}px`;
		const st$ = this.store.select(selectStatement(this.statementId));
		const argNodes$ = this.store
			.select(selectArgumentsForStatementId(this.statementId))
			.pipe(
				filter((args) => args !== undefined),
				switchMap((args) => {
					if (args.length > 0)
						return combineLatest(
							args.map((arg) =>
								this.argumentNodeRegistryService
									.getOrCreateNode(arg.id)
									.pipe(
										switchMap((node) =>
											node.getPremises$()
										),
										map(
											(premises): ArgNode =>
												({
													id: arg.id,
													// title: `${arg.title} ${arg.description}`,
													title: [
														arg.title,
														arg.description,
													]
														.filter(Boolean)
														.join(' - '),
													relation:
														ConfigService.instance.getArgumentConfig(
															arg.type
														)?.relationType,
													premises,
												} as ArgNode)
										)
									)
							)
						);
					else return of([]);
				})
			);
		this.node$ = st$.pipe(
			untilDestroyed(this),
			combineLatestWith(argNodes$),
			map(([st, argNodes]) => {
				if (st)
					return {
						id: st.id,
						title: st.title || st.description,
						pros: argNodes.filter(
							(arg) => arg.relation === 'support'
						),
						cons: argNodes.filter(
							(arg) => arg.relation === 'attack'
						),
					} as Node;
				else return undefined;
			})
		);
	}
}
