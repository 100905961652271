import { Component, Injectable, Type } from '@angular/core';
import { Utils } from '../utils/utils';

/**The decorated component type will be returned
 * then the data object has class <code>forClass</code>.
 * */
// export function RegisterDinamicComponent(forClass: any, tags: string[] = []) {
//   /**constructor is */
//   return function (constructor) {
//     ComponentRegistry.register(forClass,
//       new ComponentDescription(forClass, tags, constructor));
//   };
//
// }

export function RegisterDynamicComponent(config: ComponentConfig) {
	/**constructor is a Component*/
	return function (constructor): void {
		config.component = constructor;
		if (!config.title) config.title = config.dataType;
		ComponentRegistry.register(config);
	};
}

/*export enum BasisTags{
	label="label",
	argument= 'argument',
	support= 'support'
}*/
export type BasicTags =
	| 'argument'
	| 'support'
	| 'attack'
	| 'example'
	| 'probability';
export type ModeTags = 'view' | 'create' | 'edit' | 'label';

export type PlusLabel<T> = `+${string & T}`;
export type MinusLabel<T> = `-${string & T}`;

export type Tag =
	| BasicTags
	| PlusLabel<BasicTags>
	| MinusLabel<BasicTags>
	| ModeTags;

export class ComponentConfig {
	public dataType: string;
	public tags: Tag[] = [];
	/**Creates the instance of data object.*/
	public component?: Type<Component>;
	public title?: string;
	public actions?: Component[] = [];
}

@Injectable({ providedIn: 'root' })
export class ComponentRegistry {
	public static components: Map<string, ComponentConfig[]> = new Map();

	/*
	  static registerComponent(dataType: Type<any>, tags: string[], component: Type<Component>) {
		ComponentRegistry.register(dataType,
		  new ComponentDescription(dataType.constructor,tags, component));
	  }

	  static register(dataType: Type<any>, desciption: ComponentDescription) {
		const array = ComponentRegistry.components.get(dataType);
		if (array) {
		  array.push(desciption);
		} else {
		  ComponentRegistry.components.set(dataType, [desciption]);
		}
	  }
	*/

	static register(config: ComponentConfig): void {
		const array = ComponentRegistry.components.get(config.dataType);
		if (array) {
			array.push(config);
		} else {
			ComponentRegistry.components.set(config.dataType, [config]);
		}
	}

	static getComponent(dataType: string, tags: Tag[] = []): Type<Component> {
		const config = ComponentRegistry.getMeta(dataType, tags);
		return config?.component;
	}

	static getMeta(
		dataClass: string,
		tags: Tag[] = []
	): ComponentConfig | undefined {
		const descriptions = ComponentRegistry.components.get(dataClass);
		return descriptions?.find((descr) => Utils.matchTags(tags, descr.tags));
	}

	public getMap() {
		return ComponentRegistry.components;
	}

	public getAll() {
		return Array.from(ComponentRegistry.components.values()).reduce<
			ComponentConfig[]
		>((accum, value) => accum.concat(value), []);
	}

	/*
	  public asArray(): (Type<any> | Type<Component>)[][] {
		return Array.from(ComponentRegistry.components)
		  .map((value, index, array) => [value[0], value[1].component]);
	  }
	*/
}
