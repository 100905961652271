import { Argument } from './argument';
import { RegisterArgumentNode } from '../../services/argument-node-registry.service';
import { AbstractArgumentNode } from './argument-node';

/**
 * @see https://en.wikipedia.org/wiki/Bayes%27_theorem#Drug_testing
 * @see https://en.wikipedia.org/wiki/Positive_and_negative_predictive_values
 */
export interface ArgumentCalculateProbability extends Argument {
	type: 'ArgumentCalculateProbability';
	/**{@link StatementProbability} ids*/
	// events: string[];
	premise: string;
}

@RegisterArgumentNode()
export class ArgumentCalculateProbabilityNode extends AbstractArgumentNode<ArgumentCalculateProbability> {}
