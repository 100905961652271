import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectStatement } from '../statement/statement-selectors';
import { Observable, switchMap } from 'rxjs';

@Pipe({
	name: 'statementById',
	pure: true,
})
export class StatementPipe implements PipeTransform {
	constructor(private store: Store) {}

	transform(statementId: string | Observable<string>) {
		if (typeof statementId === 'string')
			return this.store.select(selectStatement(statementId));
		else
			return statementId.pipe(
				switchMap((id) => this.store.select(selectStatement(id)))
			);
	}
}
