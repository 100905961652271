import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, first, map } from 'rxjs';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { SelectStatementComponent } from '../../statement/shared/select-statement/select-statement.component';
import { ArgumentAbstractEditComponent } from '../argument-template/argument-abstract-edit.component';
import {
	ArgumentPrecedent,
	Similarity,
} from '../../model/arguments/argument-precedent';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
	ArgumentComponentState,
	ArgumentComponentStore,
} from '../argument-template/store/argument-component-store';

type SimFormGroup = FormGroup<{
	[k in keyof Required<Similarity>]: FormControl<Similarity[k]>;
}>;

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentPrecedent',
	title: 'Argument from precedent',
	tags: ['edit', 'create', 'argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-precedent-edit',
	templateUrl: 'argument-precedent-edit.component.html',
	styleUrls: ['./argument-precedent.component.scss'],
	providers: [
		{
			provide: ArgumentComponentStore,
			useClass: ArgumentComponentStore,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentPrecedentEditComponent
	extends ArgumentAbstractEditComponent<ArgumentPrecedent>
	implements OnInit
{
	@ViewChild('selectStatementComponent')
	selectStatementComponent: SelectStatementComponent;
	@ViewChild('rendered') private rendered;
	public isValid$ = new BehaviorSubject<boolean>(true);
	override componentStore = inject(
		ArgumentComponentStore
	) as ArgumentComponentStore<
		ArgumentPrecedent,
		ArgumentComponentState<ArgumentPrecedent>
	>;
	private formBuilder = inject(FormBuilder);
	protected simControlArray = this.formBuilder.array([] as SimFormGroup[]);
	protected formGroup = this.formBuilder.group({
		id: [undefined],
		type: [undefined],
		conclusion: [undefined],
		decisionA: [undefined],
		caseA: [''],
		caseB: [''],
		similarities: this.simControlArray,
	});

	override createArgument(): ArgumentPrecedent {
		return {
			type: 'ArgumentPrecedent',
			conclusion: this.create.conclusion,
			caseA: 'Case A',
			caseB: 'Case B',
			similarities: [{ feature: '' }],
			decisionA: 'Decision in favor of X',
		};
	}

	ngOnInit() {
		this.componentStore.initArgument$
			.pipe(first())
			.subscribe((initArgument) => {
				this.formGroup.patchValue(initArgument);
				initArgument.similarities.forEach((sim) =>
					this.simControlArray.push(this.toSimGroup(sim))
				);
				console.log(this.formGroup);
			});
		this.componentStore.updateArgument(
			this.formGroup.valueChanges.pipe(
				map(
					(value) =>
						({
							...value,
						} as ArgumentPrecedent)
				)
			)
		);
	}

	private toSimGroup(sim) {
		return this.formBuilder.group({
			valueA: [sim.valueA],
			valueB: [sim.valueB],
			feature: [sim.feature],
			statementAId: [sim.statementAId],
			statementBId: [sim.statementBId],
		});
	}

	addRow(i: number) {
		this.simControlArray.insert(i + 1, this.toSimGroup({}));
	}

	deleteRow(i: number) {
		this.simControlArray.removeAt(i);
	}
}
