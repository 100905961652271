// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		projectId: 'debatt-ng',
		appId: '1:377592441124:web:b0c6b6af7ada8b3645ebb1',
		databaseURL: 'https://debatt-ng.firebaseio.com',
		storageBucket: 'debatt-ng.appspot.com',
		locationId: 'us-central',
		apiKey: 'AIzaSyAKrWdAHT6eVUY7ApX11dNiw6bmKCkU0x8',
		authDomain: 'debatt-ng.firebaseapp.com',
		messagingSenderId: '377592441124',
	},
	nanoid: {
		alphabet: '0123456789abcdefghijklmnopqrstuvwxyz',
		size: 3,
	},
	argumentOpinionDepth: 4,
	statementOpinionDepth: 4,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
