import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'dt-argument-bar',
	templateUrl: './argument-bar.component.html',
	styleUrls: ['./argument-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentBarComponent implements OnInit {
	@Input() statementId: string;
	@Input() childId?: string;
	@Input() argumentId?: string;

	constructor() {}

	ngOnInit(): void {}
}
