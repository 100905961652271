import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Statement } from '../../model/statements/statement';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { AbstractStatementComponent } from '../shared/statement-template/abstract-statement.component';
import { StatementProbability } from '../../model/statements/statement-probability';

@RegisterDynamicComponent({
	dataType: <StatementProbability['type']>'StatementProbability',
	title: 'Statement',
	tags: ['view'],
})
@Component({
	selector: 'statement',
	templateUrl: './statement-probabilities.component.html',
	styleUrls: ['./statement-probabilities.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// encapsulation: ViewEncapsulation.None
})
export class StatementProbabilitiesComponent
	extends AbstractStatementComponent<StatementProbability>
	implements OnInit
{
	ngOnInit(): void {}
}
