import { Argument } from '../../../model/arguments/argument';
import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
	filter,
	first,
	map,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModeTags } from '../../../services/component-registry';
import { Utils } from '../../../utils/utils';
import { argumentActions } from '../../../store/argument/argument-actions';

export interface ArgumentComponentState<T extends Argument> {
	initArgument?: T;
	argument?: T;
	mode?: ModeTags;
}

export const initialState: ArgumentComponentState<any> = {};

@UntilDestroy()
@Injectable()
export class ArgumentComponentStore<
	A extends Argument,
	S extends ArgumentComponentState<A> = ArgumentComponentState<A>
> extends ComponentStore<S> {
	protected store: Store = inject(Store);
	protected storeActions = inject(Actions);
	private saveImpl: () => Observable<A>;

	setSaveImpl(impl: () => Observable<A>) {
		this.saveImpl = impl;
	}

	//**** Selectors

	readonly initArgument$ = this.select(({ initArgument }) => initArgument);
	readonly argument$ = this.select<A>(({ argument }) => argument);
	readonly title$ = this.select(this.argument$, ({ title }) => title);
	readonly description$ = this.select(
		this.argument$,
		({ description }) => description
	);
	readonly mode$ = this.select(({ mode }) => mode);

	//**** Updaters

	initStore(argument: A, mode: ModeTags) {
		this.setState({
			initArgument: argument,
			argument: argument,
			mode: mode,
		} as S);
	}

	readonly reset = this.updater((state) => ({
		...state,
		argument: state.initArgument,
	}));

	readonly updateArgument = this.updater((state, argument: A) => ({
		...state,
		argument,
	}));

	readonly updateTitle = this.updater((state, title: string) => ({
		...state,
		argument: { ...state.argument, title },
	}));

	readonly updateDescription = this.updater((state, description: string) => ({
		...state,
		argument: { ...state.argument, description },
	}));

	//**** Effects
	readonly cancel = this.effect((trigger$) =>
		trigger$.pipe(tap(() => this.reset()))
	);

	/**
	 * Saves the argument and navigates to the view.
	 */
	save(): Observable<A> {
		if (this.saveImpl) return this.saveImpl();
		const actionId = Utils.generateId();
		return this.state$.pipe(
			first(),
			switchMap((state) => {
				// create listener before dispatching the action
				const ret = this.storeActions.pipe(
					untilDestroyed(this),
					ofType(
						argumentActions.addSuccess,
						argumentActions.updateSuccess
					),
					filter((action) => action.actionId == actionId),
					map(({ argument }) => argument as A),
					shareReplay(1)
				);
				ret.subscribe();
				const actionProps = {
					argument: state.argument,
					actionId,
				};
				this.store.dispatch(
					state.mode === 'create'
						? argumentActions.add(actionProps)
						: argumentActions.update(actionProps)
				);
				return ret;
			})
		);
	}
}
