import { AbstractStatementNode } from './statement-node';
import { Statement } from './statement';
import { RegisterStatementNode } from '../../services/statement-node-registry.service';

export type TestParameters = { tpr: number; tnr: number; prevalence: number };

export interface StatementTestParameters extends Statement {
	type: 'StatementTestParameters';
	parameters: TestParameters;
}

@RegisterStatementNode()
export class StatementTestParametersNode extends AbstractStatementNode<StatementTestParameters> {}
