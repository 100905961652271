import { AbstractStatementNode } from './statement-node';
import { Statement } from './statement';
import { RegisterStatementNode } from '../../services/statement-node-registry.service';

export interface StatementDefault extends Statement {
	type: 'StatementDefault';
}

class Perspective {}

@RegisterStatementNode()
export class StatementDefaultNode extends AbstractStatementNode<StatementDefault> {}
