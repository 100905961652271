import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { filter, first, map, Observable, shareReplay, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { statementActions } from '../../../store/statement/statement-actions';
import { Utils } from '../../../utils/utils';
import { ModeTags } from '../../../services/component-registry';
import { Statement } from '../../../model/statements/statement';

export interface StatementComponentState<T extends Statement> {
	initStatement?: T;
	statement?: T;
	mode?: ModeTags;
}

export const initialState: StatementComponentState<any> = {};

@UntilDestroy()
@Injectable()
export class StatementComponentStore<
	S extends Statement,
	T extends StatementComponentState<S> = StatementComponentState<S>
> extends ComponentStore<T> {
	protected store: Store = inject(Store);
	protected storeActions = inject(Actions);
	//**** Selectors

	readonly initStatement$ = this.select(({ initStatement }) => initStatement);
	readonly statement$ = this.select(({ statement }) => statement);
	readonly title$ = this.select(this.statement$, ({ title }) => title);
	readonly description$ = this.select(
		this.statement$,
		({ description }) => description
	);
	readonly mode$ = this.select(({ mode }) => mode);

	//**** Updaters

	initStore(statement: S, mode: ModeTags) {
		this.setState({
			initStatement: statement,
			statement: statement,
			mode: mode,
		} as T);
	}

	readonly reset = this.updater((state) => ({
		...state,
		statement: state.initStatement,
	}));

	readonly updateTitle = this.updater((state, title: string) => ({
		...state,
		statement: { ...state.statement, title },
	}));

	readonly updateStatement = this.updater(
		(state: T, statement: Partial<S>) => ({
			...state,
			statement: { ...state.statement, ...statement },
		})
	);

	readonly updateDescription = this.updater((state, description: string) => ({
		...state,
		statement: { ...state.statement, description },
	}));

	//**** Effects
	readonly cancel = this.effect((trigger$) =>
		trigger$.pipe(tap(() => this.reset()))
	);

	/**
	 * Saves the statement and navigates to the view.
	 */
	save(): Observable<S> {
		const actionId = Utils.generateId();
		const ret$ = this.storeActions.pipe(
			ofType(statementActions.upsertSuccess),
			filter((action) => action.actionId === actionId),
			map(({ statement }) => statement as S),
			tap((st) => this.updateStatement(st)),
			shareReplay(1)
		);
		ret$.subscribe();
		this.state$
			.pipe(first())
			.subscribe(({ statement, mode }) =>
				this.store.dispatch(
					statementActions.upsert({ statement, actionId })
				)
			);
		return ret$;
	}
}
