import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
	collection,
	collectionData,
	CollectionReference,
	DocumentData,
	Firestore,
} from '@angular/fire/firestore';
import { map, Observable } from 'rxjs';
import {
	FirestoreDebateDocument,
	FirestoreDebateDocumentWithId,
} from '../../../model/firestore/firestore';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Store } from '@ngrx/store';
import { firestoreActions } from '../../../store/firestore/firestore-actions';
import { UserService } from '../../../services/firebase/user-service';

import { URLConst } from '../../../core/routes';

class DebattDataSource extends DataSource<FirestoreDebateDocument> {
	constructor(
		private debates$: Observable<FirestoreDebateDocument[]>,
		private userId: string
	) {
		super();
	}

	connect(
		collectionViewer: CollectionViewer
	): Observable<(FirestoreDebateDocument & { isMe: boolean })[]> {
		return this.debates$.pipe(
			map((ds) =>
				ds.map((d) => {
					const isMe = d.owner == this.userId;
					return {
						...d,
						isMe,
					};
				})
			)
		);
	}

	disconnect(collectionViewer: CollectionViewer): void {}
}

@Component({
	selector: 'dt-browse-debates',
	templateUrl: './browse-debates.component.html',
	styleUrls: ['./browse-debates.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseDebatesComponent {
	private firestore = inject(Firestore);
	private store = inject(Store);
	private userService = inject(UserService);
	private debateCollectionRef: CollectionReference<DocumentData>;
	private debates$: Observable<FirestoreDebateDocumentWithId[]>;
	private docs$;
	URLConst = URLConst;
	columns = [
		'title',
		'owner',
		// 'updated',
		'actions',
	];
	dataSource: DebattDataSource;

	constructor() {
		this.debateCollectionRef = collection(this.firestore, 'debates');
		this.debates$ = collectionData(this.debateCollectionRef, {
			idField: 'id',
		}) as Observable<FirestoreDebateDocumentWithId[]>;
		// let queryLyt = query(this.debateCollectionRef, where('statements.rootStatementId', '==', 'lyt'));
		this.dataSource = new DebattDataSource(
			this.debates$,
			this.userService.currentUser()?.uid
		);
	}

	open(debatt: FirestoreDebateDocumentWithId) {
		this.store.dispatch(
			firestoreActions.loadFromFirestore({ docId: debatt.id })
		);
	}

	delete(debatt: FirestoreDebateDocumentWithId) {
		if (confirm('Are you sure to delete ' + debatt.title)) {
			this.store.dispatch(
				firestoreActions.deleteFromFirestore({ docId: debatt.id })
			);
		}
	}
}
