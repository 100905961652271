import { createFeatureSelector, createSelector } from '@ngrx/store';
import { opinionAdapter, OpinionState } from './opinion-reducer';

import { Features } from '../shared/reducer';
import { OpinionsEnum } from '../../model/descriptions/schema/opinion.configs.schema';

export const selectOpinionState = createFeatureSelector<OpinionState>(
	Features.opinions
);

export const selectOpinionsIds = createSelector(
	selectOpinionState,
	opinionAdapter.getSelectors().selectIds
);
export const selectOpinionEntities = createSelector(
	selectOpinionState,
	opinionAdapter.getSelectors().selectEntities
);
export const selectOpinion = (id: string) =>
	createSelector(
		selectOpinionEntities,
		(opinionEntities) => id && opinionEntities[id]
	);

export const selectOpinions = (ids: string[]) =>
	createSelector(selectOpinionEntities, (opinionEntities) =>
		ids.map((id) => opinionEntities[id])
	);
export const selectOpinionVotes = (id: string) =>
	createSelector(
		selectOpinionEntities,
		(opinionEntities) => opinionEntities[id]?.votes ?? {}
	);

export const selectOpinionVote = (id: string, type: OpinionsEnum) =>
	createSelector(
		selectOpinionEntities,
		(opinionEntities) => id && opinionEntities[id]?.votes[type]
	);

export const selectAllOpinions = createSelector(
	selectOpinionState,
	opinionAdapter.getSelectors().selectAll
);
