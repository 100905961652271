import { RegisterOpenaiParser } from './openai.service';
import { inject } from '@angular/core';
import { Utils } from '../../utils/utils';
import { StatementDefault } from '../../model/statements/statement-default';
import { Actions, ofType } from '@ngrx/effects';
import { argumentActions } from '../../store/argument/argument-actions';
import { filter, first, map } from 'rxjs';
import { Statement } from '../../model/statements/statement';
import { Store } from '@ngrx/store';
import { Argument } from '../../model/arguments/argument';

/**
 * @example
 * \@RegisterOpenaiParser()
 * class ArgumentXParser implements OpenAiParser<Statement> {
 * 	matcher = /^\[Con\]/;
 * 	parse(s: string, parentStatement: Statement) {
 * 		//parsed message s, creates arument(s), add them to parentStatement.args
 *  	}
 *  }
 */
export interface OpenAiParser<P extends Statement> {
	matcher: RegExp;
	/**
	 *
	 * @param s the Openai message
	 * @param parentStatement
	 * //@returns an array of newly created argument ids
	 */
	parse: (s: string, parentStatement: P) => void /*string[]*/;
}

abstract class SimpleParser implements OpenAiParser<Statement> {
	abstract argumentType: string;
	abstract matcher: RegExp;
	private store = inject(Store);
	private actions = inject(Actions);

	parse(s: string, parentStatement: Statement) {
		const actionId = Utils.generateId();

		const description = s.replace(this.matcher, '').trim();
		const argument: Argument = {
			conclusion: parentStatement.id,
			type: this.argumentType,
		};
		const premise: StatementDefault = {
			type: 'StatementDefault',
			description,
			args: [],
		};
		this.store.dispatch(
			argumentActions.add({
				actionId,
				argument,
				newPremises: new Map([['premise', premise]]),
				updateFn: (arg, newStatements) => ({
					...arg,
					premise: newStatements.get('premise').id,
				}),
			})
		);
	}
}

@RegisterOpenaiParser()
class SimpleSupportParser extends SimpleParser {
	override argumentType = 'ArgumentSimpleSupport';
	override matcher = /^\[Pro\]/;
}

@RegisterOpenaiParser()
class SimpleAttackParser extends SimpleParser {
	override argumentType = 'ArgumentSimpleAttack';
	override matcher = /^\[Con\]/;
}
