import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
	Routes,
} from '@angular/router';
import { TopViewComponent } from './pages/top-view/top-view.component';
import { EditArgumentComponent } from './pages/edit-argument/edit-argument.component';
import { ViewArgumentComponent } from './pages/view-argument/view-argument.component';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectArgument } from './store/argument/argument-selectors';
import { Injectable } from '@angular/core';
import { EditStatementComponent } from './pages/edit-statement/edit-statement.component';
import { ViewStatementComponent } from './pages/edit-statement/view-statement.component';
import { AboutComponent } from './pages/about/about.component';
import { LoadFileComponent } from './pages/load-file.component';
import { LoginComponent } from './pages/login/login.component';
import { BrowseDebatesComponent } from './pages/dashboard/browse-debates/browse-debates.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowseLocalstorageComponent } from './pages/dashboard/browse-localstorage/browse-localstorage.component';
import { BrowseExamplesComponent } from './pages/dashboard/browse-examples/browse-examples.component';
import { DebateComponent } from './pages/firestore/debate/debate.component';
import { RedirectToFirestore } from './store/router/router-guards';
import { TemplateComponent } from './pages/template/template.component';
import { URLConst } from './core/routes';
import { OpenAiPlayComponent } from './pages/open-ai-play/open-ai-play.component';
import { DebateMapComponent } from './pages/debate-map/debate-map.component';

@Injectable({ providedIn: 'root' })
class ViewArgumentTitleResolver implements Resolve<string> {
	constructor(private store: Store) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<string> | Promise<string> | string {
		const id = route.paramMap.get(URLConst.VIEW_ARGUMENT_ID);
		return this.store
			.select(selectArgument(id))
			.pipe(map((s) => `Dt - ${s?.title}`));
	}
}

@Injectable({ providedIn: 'root' })
class EditArgumentTitleResolver implements Resolve<string> {
	constructor(private store: Store) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<string> | Promise<string> | string {
		const id = route.paramMap.get(URLConst.EDIT_ARGUMENT_ID);
		return this.store
			.select(selectArgument(id))
			.pipe(map((s) => `Dt - *${s.title}`));
	}
}

const viewEditPaths = [
	{
		path: `${URLConst.VIEW_ARGUMENT}/:${URLConst.VIEW_ARGUMENT_ID}`,
		component: ViewArgumentComponent,
		title: ViewArgumentTitleResolver,
	},
	{
		path: `${URLConst.EDIT_ARGUMENT}/:${URLConst.EDIT_ARGUMENT_ID}`,
		component: EditArgumentComponent,
		title: EditArgumentTitleResolver,
	},
	{
		path: `${URLConst.CREATE_ARGUMENT}/:${URLConst.CREATE_ARGUMENT_STATEMENT_ID}/:${URLConst.CREATE_ARGUMENT_ARGUMENT_TYPE}`,
		component: EditArgumentComponent,
		title: 'Dt - Create argument',
	},
	{
		path: `${URLConst.VIEW_STATEMENT}/:${URLConst.VIEW_STATEMENT_ID}`,
		component: ViewStatementComponent,
		title: 'View statement',
		// runGuardsAndResolvers: 'always'
	},
	{
		path: `${URLConst.EDIT_STATEMENT}/:${URLConst.EDIT_STATEMENT_ID}`,
		component: EditStatementComponent,
		title: 'Edit statement',
	},
];
export const appRoutes: Routes = [
	{
		path: '', //URLConst.HOME
		component: AboutComponent,
		title: 'Debatt',
	},
	{
		path: URLConst.DEBATT_ROOT,
		component: TopViewComponent,
		title: 'Debatt',
		canActivate: [RedirectToFirestore],
	},
	{
		path: URLConst.FIRESTORE_ROOT,
		component: DebateComponent,
		title: 'Debatt(FS)',
		/*
				children: [
					{
						path: `:${URLConst.FIRESTORE_DEBATE}`,
						component: AboutComponent,
						title: 'id',
						children: [
							{
								path: 'view',
								pathMatch: 'full',
								component: AboutComponent
							},
							{
								path: '',
								pathMatch: 'full',
								component: AboutComponent
							},
						]
					},
				]
		*/
	},
	{
		path: `${URLConst.FIRESTORE_ROOT}/:${URLConst.FIRESTORE_DEBATE}`,
		component: DebateComponent,
		title: 'id',
	},
	{
		path: `${URLConst.FIRESTORE_ROOT}/:${URLConst.FIRESTORE_DEBATE}/view`,
		pathMatch: 'prefix',
		component: DebateComponent,
		title: 'id:any',
	},
	{
		path: URLConst.LOGIN,
		component: LoginComponent,
		title: 'Login',
	},
	{
		path: URLConst.DASHBOARD,
		component: DashboardComponent,
		children: [
			{
				path: '',
				redirectTo: URLConst.BROWSE_FIRESTORE,
				pathMatch: 'full',
			},
			{
				path: URLConst.BROWSE_FIRESTORE,
				component: BrowseDebatesComponent,
				title: 'Firestore',
			},
			{
				path: URLConst.BROWSE_LOCAL_STORE,
				component: BrowseLocalstorageComponent,
				title: 'Local Storage',
			},
			{
				path: URLConst.BROWSE_EXAMPLES,
				component: BrowseExamplesComponent,
				title: 'Examples',
			},
		],
	},
	{
		path: URLConst.LOAD_FILE,
		pathMatch: 'prefix',
		component: LoadFileComponent,
	},
	...viewEditPaths,
	{
		path: URLConst.CREATE_FROM_TEMPLATE,
		component: TemplateComponent,
		title: 'Create a document from a template',
	},
	{
		path: URLConst.OPENAI_PLAYGROUND,
		component: OpenAiPlayComponent,
		title: 'OpenAI playground',
	},
	{
		path: URLConst.DEBATE_MAP,
		component: DebateMapComponent,
		title: 'Map overview',
	},
];
