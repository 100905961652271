import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { Argument } from 'src/app/model/arguments/argument';
import { selectArgument } from '../../store/argument/argument-selectors';

import { URLConst } from '../../core/routes';

@Component({
	selector: 'debatt-view-argument',
	templateUrl: './view-argument.component.html',
	styleUrls: ['../edit-argument/edit-argument.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewArgumentComponent implements OnInit {
	argument$: Observable<Argument>;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private store: Store
	) {}

	ngOnInit(): void {
		this.argument$ = this.route.paramMap.pipe(
			switchMap((params) =>
				this.store.select(
					selectArgument(params.get(URLConst.VIEW_ARGUMENT_ID))
				)
			)
		);
	}
}
