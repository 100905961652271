import {
	inject,
	Injectable,
	InjectionToken,
	Injector,
	StaticProvider,
	Type,
} from '@angular/core';
import { Statement } from '../model/statements/statement';
import { StatementNode } from '../model/statements/statement-node';
import { Tag } from './component-registry';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { selectStatement } from '../store/statement/statement-selectors';

export const TOKEN_STATEMENT_ID = new InjectionToken<string>('StatementId');
export const TOKEN_TAGS = new InjectionToken<Tag[]>('Tags');

/*
export interface CreateStatementConfig {
	type: StatementTypes;
	/!**the ID of the conclusion*!/
	conclusion: string;
}
*/

/**
 * Create Node for by the type of the Statement.
 */
@Injectable({
	providedIn: 'root',
})
export class StatementNodeRegistryService {
	public static registry: Record<string, Type<any>> = {};
	public registryExisting: Record<string, StatementNode<any>> = {};
	private injector = inject(Injector);
	private store = inject(Store);

	resetExisting() {
		this.registryExisting = {};
	}

	/**
	 * Registers the <code>node</code> as a node for the statementType
	 * If <code>statementType</code> is not provided
	 * then it will take the node class name e.g. StatementSimpleAttackNode, truncate
	 * the ending 'Node' and register it to the type StatementSimpleAttack
	 */
	public static registerNode(
		node: Type<StatementNode<any>>,
		statementType?: string
	) {
		StatementNodeRegistryService.registry[
			statementType ?? node.name.replace(/Node$/, '')
		] = node;
	}

	public createNewNode<T extends StatementNode<any>>(
		type: string,
		parent?: Injector
	): T {
		const tokenNode = StatementNodeRegistryService.registry[type];
		const statementNode: StatementNode<any> = Injector.create({
			providers: [{ provide: tokenNode }],
			parent: parent ?? this.injector,
		}).get(tokenNode);
		return statementNode as T;
	}

	public getOrCreateNode<T extends StatementNode<any>>(
		id
	): Observable<StatementNode<any>> {
		return this.store.select(selectStatement(id)).pipe(
			map((st) => this.registryExisting[id] ?? this.createNode(st)),
			first()
		);
	}

	public createNode<S extends Statement, T extends StatementNode<S>>(
		statement: S,
		parent?: Injector
	): T {
		console.log(`Creating node for ${statement.id}`);
		const tokenNode = StatementNodeRegistryService.registry[statement.type];
		if (!tokenNode)
			throw new Error(`Cannot find Node for ${statement.type}`);
		// what if this can work?
		// const tokenNode = new InjectionToken(arg.type);
		const statementProvider: StaticProvider = {
			provide: TOKEN_STATEMENT_ID,
			useValue: statement.id,
		};
		const injector = Injector.create({
			providers: [statementProvider, { provide: tokenNode }],
			parent: parent ?? this.injector,
			name: 'StatementNodeRegistryInjector',
		});

		const statementNode: StatementNode<any> = injector.get(tokenNode);
		if (statement.id) this.registryExisting[statement.id] = statementNode;
		return statementNode as T;
	}
}

/**
 * Also applies @Injectable decorator
 * @see StatementNodeRegistryService#registerNode
 */
export function RegisterStatementNode(statementType?: string) {
	/**constructor is a Component*/
	return function (constructor) {
		console.info(`Registering node ${constructor.name}`);
		StatementNodeRegistryService.registerNode(constructor, statementType);
		Injectable()(constructor);
	};
}
