import { createReducer, on } from '@ngrx/store';
import { openaiActions } from './openai-actions';

export interface OpenaiState {
	loading: boolean;
}

export const openaiInitialState: OpenaiState = {
	loading: false,
};

export const openaiReducer = createReducer(
	openaiInitialState,
	on(openaiActions.loadFromOpenai, (state) => ({
		...state,
		loading: true,
	})),
	on(openaiActions.loadFromOpenaiCompleted, (state) => ({
		...state,
		loading: false,
	}))
);
