import {
	ChangeDetectorRef,
	Directive,
	ElementRef,
	HostBinding,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, skipWhile, switchMap, take, tap } from 'rxjs';
import { selectLoading } from '../../store/shared/selectors';

/**
 * Usage:
 * ```
 * <dt-openai-icon [dtLoadingRotate]="loadingTrigger$" />
 * ```
 * loadingTrigger$ is needed so that it will not react on all loading events.
 * Only one loading true -> false transition after loadingTrigger$ emits anything
 * will be processed.
 */
@Directive({
	selector: '[dtLoadingRotate]',
})
export class LoadingRotateDirective implements OnInit {
	private store = inject(Store);
	/**Trigger*/
	@Input()
	private dtLoadingRotate: Observable<unknown>;
	private elementRef: ElementRef = inject(ElementRef);
	private changeDetectorRef = inject(ChangeDetectorRef);

	@HostBinding('class.rotating')
	rotating = false;

	ngOnInit(): void {
		(this.dtLoadingRotate ?? of(true)).subscribe(() => {
			this.store
				.select(selectLoading)
				.pipe(
					skipWhile((loading) => !loading),
					take(1),
					tap(() => {
						this.rotating = true;
						this.changeDetectorRef.markForCheck();
					}),
					switchMap(() => this.store.select(selectLoading)),
					skipWhile(Boolean),
					take(1)
				)
				.subscribe(() => {
					this.rotating = false;
					this.changeDetectorRef.markForCheck();
				});
		});
	}
}
