import { customAlphabet, nanoid } from 'nanoid';
import { environment } from '../../environments/environment';

const nanoidFn = environment.nanoid
	? customAlphabet(environment.nanoid.alphabet, environment.nanoid.size)
	: nanoid;

export class Utils {
	static generateId(): string {
		return nanoidFn();
	}

	/**
	 * Tags might be prefixed by '+' or '-'.
	 * @param tags the desired tags
	 * @param elTags the element tags
	 */
	public static matchTags(tags: string[], elTags: string[]): boolean {
		{
			const match = tags.every((tag) => {
				if (tag.startsWith('-')) {
					return !elTags?.includes(tag.substring(1));
				} else if (tag.startsWith('+')) {
					return elTags?.includes(tag.substring(1));
				} else {
					return elTags?.includes(tag);
				}
			});
			return match;
		}
	}
	public static matchTagsSome(tags: string[], elTags: string[]): boolean {
		{
			const match = tags.some((tag) => {
				if (tag.startsWith('-')) {
					return !elTags?.includes(tag.substring(1));
				} else if (tag.startsWith('+')) {
					return elTags?.includes(tag.substring(1));
				} else {
					return elTags?.includes(tag);
				}
			});
			return match;
		}
	}

	public static async sleep(ms) {
		return new Promise((r) => setTimeout(r, ms));
	}
}
