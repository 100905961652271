import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Inject,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { map, of } from 'rxjs';
import {
	ArgumentPrecedent,
	ArgumentPrecedentNode,
} from '../../model/arguments/argument-precedent';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MarkedService } from '../../services/marked-service';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentPrecedent',
	title: 'Argument from precedent',
	tags: ['view', 'argument', 'support'],
})
@Component({
	selector: 'debatt-argument-precedent-view',
	templateUrl: './argument-precedent.component.html',
	styleUrls: ['./argument-precedent.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ArgumentPrecedentComponent
	extends ArgumentAbstractComponent<ArgumentPrecedentNode, ArgumentPrecedent>
	implements OnInit
{
	@ViewChild('rendered') private rendered;
	public markdown$;
	private markedService = inject(MarkedService);

	ngOnInit(): void {
		this.argument$.subscribe((arg) => {
			this.createSummary(arg);
		});
	}

	createSummary(arg: ArgumentPrecedent) {
		this.markdown$ = of(
			`Case *${arg.caseA}* and case *${arg.caseB}* are similar
			in respect to facts *${arg.similarities.map((s) => s.feature).join(', ')}*.
			The decision in the case *${arg.caseA}* was: **${
				arg.decisionA
			}** based on legal rule or principle R.
			Therefore case *${arg.caseB}* should be decided similarly.

			`
		).pipe(map(this.markedService.render));
	}
}

@RegisterDynamicComponent({ dataType: 'ArgumentPrecedent', tags: ['label'] })
@Component({
	selector: 'debatt-argument-precedent-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'green'"
			[icon]="'gavel'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentPrecedentLabelComponent {
	constructor(@Inject(TOKEN_ARGUMENT_ID) public id: string) {}
}
