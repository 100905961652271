import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, from, mergeMap, of, reduce, switchMap, take } from 'rxjs';
import { statementActions } from './statement-actions';
import { Utils } from '../../utils/utils';
import { selectStatement } from './statement-selectors';
import { Statement } from '../../model/statements/statement';

@Injectable()
export class StatementEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);

	add$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(statementActions.add),
			mergeMap(({ statement, actionId }) => {
				if (statement.id)
					throw new Error(
						'Adding a statement with already assigned ID'
					);
				return of(
					statementActions.addSuccess({
						statement: { ...statement, id: Utils.generateId() },
						actionId,
					})
				);
			})
		);
	});

	upsert$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(statementActions.upsert),
			mergeMap(({ statement, actionId }) => {
				if (!statement.id)
					return of(
						statementActions.upsertSuccess({
							statement: { ...statement, id: Utils.generateId() },
							actionId,
						})
					);
				else {
					//nothing changed
					return of(
						statementActions.upsertSuccess({
							statement,
							actionId,
						})
					);
				}
			})
		);
	});

	addMany$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(statementActions.addMany),
			mergeMap(({ statements, actionId }) => {
				const assignId = (st) => ({ ...st, id: Utils.generateId() });
				const updated = new Map(
					Array.from(statements, ([key, st]) => [key, assignId(st)])
				);
				return of(
					statementActions.addManySuccess({
						statements: updated, //TODO: replace with backend save
						actionId,
					})
				);
			})
		);
	});

	assignId$ = createEffect(
		() => {
			interface R {
				statement: Statement;
				newId: string;
			}

			return this.actions$.pipe(
				ofType(statementActions.assingId),
				mergeMap(({ statements, getId, actionId }) => {
					// let map = new Map<string, string>();
					return from(statements).pipe(
						concatMap((st) => {
							return this.store
								.select(selectStatement(st.id))
								.pipe(
									take(1),
									switchMap((selected) => {
										return selected
											? of<R>({
													statement: st,
													newId: selected.id,
											  })
											: of<R>({
													statement: st,
													newId: Utils.generateId(),
											  });
									})
								);
						}),

						reduce((map, value: R) => {
							map.set(value.statement.id, value.newId);
							if (value.statement.id !== value.newId)
								value.statement.id = value.newId;
							// const newStatement = {...value.statement, id: value.newId};
							// statements.push(newStatement);
							return map;
						}, new Map<string, string>()),
						switchMap((map) => {
							return of(
								statementActions.assingIdSuccess({
									statements,
									map,
									actionId,
								})
							);
						})
					);
				})
			);
		},
		{ dispatch: true }
	);
}
