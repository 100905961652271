import { inject, Injectable } from '@angular/core';
import {
	Auth,
	authState,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	User,
} from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
	private auth = inject(Auth);
	public readonly user$: Observable<User | null>;
	private userSubject$ = new BehaviorSubject<User>(null);

	constructor() {
		this.user$ = authState(this.auth);
		this.user$.subscribe(this.userSubject$);
	}

	currentUser() {
		return this.userSubject$.value;
	}

	async login() {
		return await signInWithPopup(this.auth, new GoogleAuthProvider());
	}
	async logout() {
		return await signOut(this.auth);
	}
}
