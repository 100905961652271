import {
	inject,
	Injectable,
	InjectionToken,
	Injector,
	StaticProvider,
	Type,
} from '@angular/core';
import { Argument } from '../model/arguments/argument';
import { ArgumentNode } from '../model/arguments/argument-node';
import { Tag } from './component-registry';
import { ArgumentTypes } from './config.service';
import { first, map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectArgument } from '../store/argument/argument-selectors';

// export const TOKEN_ARGUMENT = new InjectionToken<Argument>('Argument');
export const TOKEN_ARGUMENT_ID = new InjectionToken<string>('ArgumentId');
export const TOKEN_ARGUMENT_COMPONENT_CONFIG =
	new InjectionToken<ArgumentComponentConfig>('Config');
export const TOKEN_TAGS = new InjectionToken<Tag[]>('Tags');
export const TOKEN_CREATE_ARGUMENT = new InjectionToken<CreateArgumentConfig>(
	'CreateArgument'
);
export class ArgumentComponentConfig {}
export interface CreateArgumentConfig {
	type: ArgumentTypes;
	/**the ID of the conclusion*/
	conclusion: string;
}

/**
 * Create Node for by the type of the Argument.
 */
@Injectable({
	providedIn: 'root',
})
export class ArgumentNodeRegistryService {
	// private registry: Record<string, InjectionToken<ArgumentNode<any>>>;
	public static registry: Record<string, Type<ArgumentNode<any>>> = {};
	private registryExisting: Record<string, ArgumentNode<any>> = {};
	private store = inject(Store);
	private injector = inject(Injector);

	/**
	 * Registers the <code>node</code> as a node for the argumentType
	 * If <code>argumentType</code> is not provided
	 * then it will take the node class name e.g. ArgumentSimpleAttackNode, truncate
	 * the ending 'Node' and register it to the type ArgumentSimpleAttack
	 */
	public static registerNode(
		node: Type<ArgumentNode<any>>,
		argumentType?: string
	) {
		ArgumentNodeRegistryService.registry[
			argumentType ?? node.name.replace(/Node$/, '')
		] = node;
	}

	/*
	public createNodeAndArgument(argType: string) {
		const constuctor = this.registry[argType];
		return constuctor ? new constuctor() : undefined;
	}
*/

	/**
	 * Emits one value and completes
	 */
	public getOrCreateNode<T extends ArgumentNode<any>>(id): Observable<T> {
		if (this.registryExisting[id])
			return of(this.registryExisting[id] as T);
		else return this.createNode(id);
	}

	/*
	public createNewNode<T extends ArgumentNode<any>>(type: string, parent?: Injector): T {
		const tokenNode = ArgumentNodeRegistryService.registry[type];
		const argumentNode: ArgumentNode<any> = Injector.create({
			providers: [{provide: tokenNode}],
			parent: parent ?? this.injector,
		}).get(tokenNode);
		return argumentNode as T;

	}
*/

	public createNode<S extends Argument, T extends ArgumentNode<S>>(
		argId: string,
		parent?: Injector
	): Observable<T> {
		return this.store.select(selectArgument(argId)).pipe(
			first(),
			map((arg) => {
				const tokenNode =
					ArgumentNodeRegistryService.registry[arg.type];
				if (!tokenNode)
					throw new Error(`Cannot find Node for ${arg.type}`);
				// what if this can work?
				// const tokenNode = new InjectionToken(arg.type);
				const argumentProvider: StaticProvider = {
					provide: TOKEN_ARGUMENT_ID,
					useValue: argId,
				};
				const injector = Injector.create({
					providers: [argumentProvider, { provide: tokenNode }],
					parent: parent ?? this.injector,
					name: 'ArgumentNodeRegistryInjector',
				});

				const argumentNode = injector.get(tokenNode);
				return argumentNode as T;
			})
		);
	}
}

/**
 * Also applies @Injectable decorator
 * @see ArgumentNodeRegistryService#registerNode
 */
export function RegisterArgumentNode(argumentType?: string) {
	/**constructor is a Component*/
	return function (constructor) {
		console.info(`Registering node ${constructor.name}`);
		ArgumentNodeRegistryService.registerNode(constructor, argumentType);
		Injectable()(constructor);
	};
}
