import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { Utils } from '../../../utils/utils';
import {
	OpinionConfig,
	OpinionsTag,
} from '../../../model/descriptions/schema/opinion.configs.schema';
import { Store } from '@ngrx/store';
import { selectOpinionVotes } from '../../../store/opinions/opinion-selectors';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Opinion } from '../../../store/opinions/opinion-reducer';
import { opinionActions } from '../../../store/opinions/opinion-actions';
import { FilterOpinionsConfigsPipe } from '../../../model/pipes/filter-opinions.pipe';
import { StatementNodeRegistryService } from '../../../services/statement-node-registry.service';
import { Statement } from 'src/app/model/statements/statement';
import { StatementNode } from 'src/app/model/statements/statement-node';

/**
 * Looks like this fits opinions both about argument and statement
 */
@Component({
	selector: 'debatt-statement-opinions',
	templateUrl: './opinions-statement.component.html',
	styleUrls: ['./opinions-statement.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpinionsStatementComponent implements OnInit, AfterViewInit {
	@Input() statementId: string;
	private opinionConfigs: OpinionConfig[];
	private store = inject(Store);
	private filterConfig = inject(FilterOpinionsConfigsPipe);
	private registryService = inject(StatementNodeRegistryService);
	opinions$: Observable<Opinion['votes']>;
	countPro$: Observable<number>;
	countCon$: Observable<number>;
	truthiness$: Observable<number>;
	expanded$ = new BehaviorSubject(false);
	@ViewChild('button') private templateRef: TemplateRef<unknown>;
	/**The template reference for the button with the totals
	 * that can toggle expansion panel with
	 */
	@Output() private totalButtonRef = new EventEmitter(undefined);
	private node$: Observable<StatementNode<Statement>>;

	constructor() {}

	ngOnInit(): void {
		this.node$ = this.registryService
			.getOrCreateNode(this.statementId)
			.pipe(shareReplay(1));
		this.truthiness$ = this.node$.pipe(
			switchMap((node) => node.getTruthiness())
		);

		this.opinions$ = this.store.select(
			selectOpinionVotes(this.statementId)
		);
		// this.opinions.forEach((value, key) => )
		this.opinionConfigs = this.filterConfig.transform('statements');

		this.countPro$ = this.countPro();
		this.countCon$ = this.countCon();
	}

	ngAfterViewInit(): void {
		this.totalButtonRef.emit(this.templateRef);
	}

	toggleOpinions(event: string[]) {
		this.expanded$.next(event.includes('expand-opinions'));
	}

	/**
	 * Gets all opinions that satisfy at least one set of tags.
	 * @param tagsOr
	 */
	public filterOr(tagsOr: OpinionsTag[][]): OpinionConfig[] {
		return this.opinionConfigs.filter(
			(c) =>
				c.tags && tagsOr.some((tags) => Utils.matchTags(tags, c.tags))
		);
	}

	/**now it is called multiple types*/
	public filter(tags: OpinionsTag[]): OpinionConfig[] {
		return this.opinionConfigs.filter(
			(c) => c.tags && Utils.matchTags(tags, c.tags)
		);
	}

	public countPro(): Observable<number> {
		return this.opinions$.pipe(
			map((opinions) => {
				return Object.entries(opinions).reduce(
					(sum, [key, value]) =>
						ConfigService.instance
							.getOpinionConfig(key)
							?.tags.includes('pro')
							? sum + value
							: sum,
					0
				);
			})
		);
	}

	public countCon(): Observable<number> {
		return this.opinions$.pipe(
			map((opinions) => {
				return Object.entries(opinions).reduce(
					(sum, [key, value]) =>
						ConfigService.instance
							.getOpinionConfig(key)
							?.tags.includes('con')
							? sum + value
							: sum,
					0
				);
			})
		);
	}

	plusOne($event: string) {
		this.store.dispatch(
			opinionActions.voteUp({
				statementId: this.statementId,
				opinion: $event,
			})
		);
	}
}
