import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArgumentState } from './argument-reducer';
import { argumentsAdapter } from '../argument/argument-reducer';

import { Features } from '../shared/reducer';
import { Argument } from '../../model/arguments/argument';

export const selectArgumentState = createFeatureSelector<ArgumentState>(
	Features.args
);

export const selectArgumentsIds = createSelector(
	selectArgumentState,
	argumentsAdapter.getSelectors().selectIds
	// argumentsAdapter.selectIds
);
export const selectArgumentEntities = createSelector(
	selectArgumentState,
	argumentsAdapter.getSelectors().selectEntities
);
export const selectArgument = <A extends Argument>(id: string) =>
	createSelector(
		selectArgumentEntities,
		(argumentEntities) => id && (argumentEntities[id] as A)
	);
export const selectAllArguments = createSelector(
	selectArgumentState,
	argumentsAdapter.getSelectors().selectAll
);
export const selectArgumentTotal = createSelector(
	selectArgumentState,
	argumentsAdapter.getSelectors().selectTotal
);
