import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import {
	ArgumentSimpleSupport,
	ArgumentSimpleSupportNode,
} from '../../model/arguments/argument-simple-support';
import { map, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentSimpleSupport',
	title: 'Support',
	tags: ['view', 'argument', 'support'],
})
@Component({
	selector: 'debatt-argument-simple-support-view',
	templateUrl: './argument-simple-support.component.html',
	styleUrls: ['./argument-simple-support.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentSimpleSupportComponent
	extends ArgumentAbstractComponent<
		ArgumentSimpleSupportNode,
		ArgumentSimpleSupport
	>
	implements OnInit
{
	premiseId$: Observable<string>;

	ngOnInit(): void {
		this.premiseId$ = this.argument$.pipe(
			map((a: ArgumentSimpleSupport) => a?.premise)
		);
	}
}

@RegisterDynamicComponent({
	dataType: 'ArgumentSimpleSupport',
	tags: ['label'],
})
@Component({
	selector: 'debatt-argument-simple-support-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'green'"
			[icon]="'check_circle_outline'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentSimpleSupportLabel {
	private id = inject(TOKEN_ARGUMENT_ID);
}
