import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ArgdownParser } from '../model/parser/argdownParser';
import { globalActions } from '../store/shared/actions';
import { StatementState } from '../store/statement/statement-reducer';

@UntilDestroy()
@Component({
	selector: 'selector-name',
	template: '',
})
export class LoadFileComponent implements OnInit {
	constructor(
		activeRoute: ActivatedRoute,
		private http: HttpClient,
		private store: Store<StatementState>,
		private argdownParser: ArgdownParser
	) {
		activeRoute.fragment.subscribe((f) => this.loadFile(f));
	}

	ngOnInit() {}

	public loadJSON(json: string | object) {
		const newState = typeof json === 'string' ? JSON.parse(json) : json;
		this.store.dispatch(globalActions.reset());
		this.store.dispatch(globalActions.loadFile({ newState }));
	}

	public loadFile(fileUrl?: string) {
		// this.clear();

		//get file extension
		const re = /(?:\.([^.]+))?$/;
		const ext = re.exec(fileUrl)[1];
		this.http.get(fileUrl, { responseType: 'text' }).subscribe((data) => {
			switch (ext) {
				case 'argdown': {
					this.argdownParser.parse(data);
					break;
				}
				case 'json': {
					this.loadJSON(data);
					break;
				}
				default:
					throw Error(`Unknown file extension ${ext}`);
			}
		});
	}
}
