import { createReducer } from '@ngrx/store';
import { firestoreInitialState } from '../firestore/firestore-reducer';
import { statementInitialState } from '../statement/statement-reducer';
import { argumentsInitialState } from '../argument/argument-reducer';
import { opinionInitialState } from '../opinions/opinion-reducer';
import { DebattState } from './state';
import { settingsInitialState } from '../settings/settings-reducer';
import { openaiInitialState } from '../openai/opeai-reducer';

export const Features = {
	statements: 'statements',
	args: 'args',
	firestore: 'firestore',
	opinions: 'opinions',
	router: 'router',
	settings: 'settings',
	openai: 'openai',
};

export const initialState: DebattState = {
	statements: statementInitialState,
	args: argumentsInitialState,
	firestore: firestoreInitialState,
	opinions: opinionInitialState,
	settings: settingsInitialState,
	openai: openaiInitialState,
	router: undefined,
};

/**@deprecated*/
export const reducer = createReducer(initialState);
