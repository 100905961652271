import { createAction, props } from '@ngrx/store';
import { OpenAiSettings, SettingsState } from './settings-reducer';

export const settingsActions = {
	setAll: createAction(
		'[Settings] Set All',
		props<{ settings: SettingsState }>()
	),
	set: createAction(
		'[Settings] Set',
		props<{ settings: Partial<SettingsState> }>()
	),
	setOpenAi: createAction(
		'[Settings] Set OpenAI settings',
		props<{ settings: OpenAiSettings }>()
	),
	updateOpenAi: createAction(
		'[Settings] Update OpenAI settings',
		props<{ settings: Partial<OpenAiSettings> }>()
	),
	saveToLocalStorage: createAction('[Settings] Save'),
	saveToLocalStorageFailed: createAction(
		'[Settings] Save Failed',
		props<{ message: string }>()
	),
	loadFromLocalStorage: createAction('[Settings] Load'),
	loadFromLocalStorageCompleted: createAction(
		'[Settings] Load Completed',
		props<{ settings: SettingsState }>()
	),
	loadFromLocalStorageFailed: createAction(
		'[Settings] Load Failed',
		props<{ message: string }>()
	),
	disableAutoloadFor: createAction(
		'[Settings] Disable Autoload For',
		props<{ id: string }>()
	),
};
