import { createAction, props } from '@ngrx/store';
import { Statement } from '../../model/statements/statement';
import { Argument } from '../../model/arguments/argument';

export const argdownActions = {
	load: createAction(
		'[ArgDown] Navigate to Root and Load',
		props<{
			statements: Statement[];
			args: Argument[];
			rootStatementId: string;
		}>()
	),
	load2: createAction(
		'[ArgDown] Load',
		props<{
			statements: Statement[];
			args: Argument[];
			rootStatementId: string;
		}>()
	),
};
