import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	inject,
	Inject,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractComponent } from '../argument-template/argument-template.component';
import { BehaviorSubject, filter, map } from 'rxjs';
import {
	ArgumentByDefinitionNode,
	ArgumentDefinition,
} from '../../model/arguments/argument-definition';
import { MarkedService } from '../../services/marked-service';
import { TOKEN_ARGUMENT_ID } from '../../services/argument-node-registry.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentByDefinition',
	title: 'Support',
	tags: ['view', 'argument', 'support'],
})
@Component({
	selector: 'debatt-argument-definition-view',
	templateUrl: './argument-definition.component.html',
	styleUrls: ['./argument-definition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ArgumentDefinitionComponent
	extends ArgumentAbstractComponent<
		ArgumentByDefinitionNode,
		ArgumentDefinition
	>
	implements OnInit, AfterViewInit
{
	@ViewChild('rendered') private rendered;
	public markdown$;
	public premises$;
	public hoveredAbbr$ = new BehaviorSubject<string>(undefined);
	private markedService = inject(MarkedService);

	constructor() {
		super();
		this.premises$ = this.argument$.pipe(map((a) => a?.premises));
		this.markdown$ = this.argument$.pipe(
			filter(Boolean),
			map((a) => a.definition),
			map((def) => this.markedService.render(def))
		);
	}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.addHoverListener();
	}

	private addHoverListener() {
		this.rendered.nativeElement.addEventListener('mouseover', (event) => {
			const target = event.target as HTMLElement;
			if (target.tagName === 'ABBR') {
				this.hoveredAbbr$.next(target.id);
			} else this.hoveredAbbr$.next(undefined);
		});
	}

	renderedMouseOut() {
		this.hoveredAbbr$.next(undefined);
	}

	trackByPremises(index, item) {
		return item.link;
	}
}

@RegisterDynamicComponent({ dataType: 'ArgumentByDefinition', tags: ['label'] })
@Component({
	selector: 'debatt-argument-definition-label',
	template: `
		<argument-template-label
			[id]="id"
			[color]="'green'"
			[icon]="'check_circle_outline'"
		></argument-template-label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentDefinitionLabelComponent {
	constructor(@Inject(TOKEN_ARGUMENT_ID) public id: string) {}
}
