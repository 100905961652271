import { Pipe, PipeTransform } from '@angular/core';
import { PathService } from '../../services/path.service';
import { Observable, pluck } from 'rxjs';
import { ID } from '../arguments/argument';

/**
 * Extracts id from the observable of objects with "id" field.
 */
@Pipe({
	name: 'id',
	pure: true,
})
export class IdPipe implements PipeTransform {
	constructor(private routeService: PathService) {}

	transform(obj$: Observable<ID>): Observable<string> {
		// return obj$.pipe(switchMap(o => o.id));
		return obj$.pipe(pluck('id'));
	}
}
