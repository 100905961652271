import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	BehaviorSubject,
	combineLatestWith,
	first,
	map,
	Observable,
} from 'rxjs';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { SelectStatementComponent } from '../../statement/shared/select-statement/select-statement.component';
import { ArgumentAbstractEditComponent } from '../argument-template/argument-abstract-edit.component';
import {
	ArgumentDefinition,
	TermPremise,
} from '../../model/arguments/argument-definition';
import { MarkedService } from '../../services/marked-service';
import { Statement } from '../../model/statements/statement';
import { FormControl } from '@angular/forms';
import { ArgumentComponentStore } from '../argument-template/store/argument-component-store';
import {
	ArgumentDefinitionComponentStore,
	TableTermItem,
} from './argument-definition-component-store';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentByDefinition',
	title: 'Support',
	tags: ['edit', 'create', 'argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-definition-edit',
	templateUrl: 'argument-definition-edit.component.html',
	styleUrls: ['./argument-definition.component.scss'],
	providers: [
		{
			provide: ArgumentComponentStore,
			useClass: ArgumentDefinitionComponentStore,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArgumentDefinitionEditComponent
	extends ArgumentAbstractEditComponent<ArgumentDefinition>
	implements OnInit, AfterViewInit
{
	@ViewChild('selectStatementComponent')
	selectStatementComponent: SelectStatementComponent;
	@ViewChild('rendered') private rendered;
	public isValid$ = new BehaviorSubject<boolean>(false);
	public markdown$: Observable<string>;
	/**Array from premisesMap including unmapped links from the definition.*/
	public premises$: Observable<TermPremise[]>;
	public hoveredAbbr$ = new BehaviorSubject<string>(undefined);
	public definitionControl = new FormControl('');
	private markedService = inject(MarkedService);
	override componentStore = inject(
		ArgumentComponentStore
	) as ArgumentDefinitionComponentStore;

	override createArgument(): ArgumentDefinition {
		return {
			type: 'ArgumentByDefinition',
			conclusion: this.create.conclusion,
			// title: 'By definition',
			// description: 'By definition',
			definition: '',
			premises: [],
		};
	}

	ngOnInit() {
		this.componentStore.updateDefinition(
			this.definitionControl.valueChanges
		);
		this.premises$ = this.componentStore.premises$;
		this.componentStore.initArgument$
			.pipe(first())
			.subscribe((initArgument) => {
				// this.componentStore.setPremisesAll(initArgument.premises.map(item => ({...item})));
				this.definitionControl.setValue(initArgument.definition);
			});
		this.componentStore.definition$
			.pipe(map((value) => !!value))
			.subscribe(this.isValid$);
		const tokens$ = this.componentStore.definition$.pipe(
			map(this.markedService.parse)
		);
		const links$ = tokens$.pipe(
			map((t) => this.markedService.filterLinkTokens(t))
		);
		this.markdown$ = tokens$.pipe(map(this.markedService.renderTokens));
		links$
			.pipe(
				untilDestroyed(this),
				combineLatestWith(this.componentStore.premises$)
			)
			.subscribe(([links, premises]) => {
				const toDelete = premises
					.filter(
						({ term, premiseId, updatedStatement }) =>
							!premiseId &&
							!updatedStatement &&
							!links.some(
								({ href, text }) => term === (href || text)
							)
					)
					.map(({ term }) => term);
				this.componentStore.removePremiseMany(toDelete);
				const toAdd = links
					.filter(
						(link) =>
							!premises.some(
								(p) => p.term === (link.href || link.text)
							)
					)
					.map((link) => ({
						term: link.href || link.text,
						premiseId: undefined,
						displayTermTitle: link.title || link.href || link.text,
					}));
				this.componentStore.addPremisesMany(toAdd);
			});
	}

	private addHoverListener() {
		this.rendered.nativeElement.addEventListener('mouseover', (event) => {
			const target = event.target as HTMLElement;
			if (target.tagName === 'ABBR') {
				this.hoveredAbbr$.next(target.title || target.textContent);
			} else this.hoveredAbbr$.next(undefined);
		});
	}

	ngAfterViewInit() {
		this.addHoverListener();

		/*
				this.premise$ = this.selectStatementComponent.getStatement$().pipe(
					untilDestroyed(this));
				this.premise$.pipe(
					delay(0),	//to change it after all init hooks
					tap(premise => this.argument.premise = premise?.id),
					map(s => !!s),
				).subscribe(this.isValid$);
		*/
	}

	renderedMouseOut() {
		this.hoveredAbbr$.next(undefined);
	}

	trackByPremises(index, item: TableTermItem) {
		return item.term;
	}

	setPremise(link: string, st: Statement) {
		this.componentStore.updatePremise({
			id: link,
			changes: { updatedStatement: st },
		});
	}
}
