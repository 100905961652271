import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'dt-openai-icon',
	templateUrl: './openai-icon.component.html',
	styleUrls: ['./openai-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenaiIconComponent {
	@HostBinding('style.display')
	display = 'inline-block';
	// @HostBinding('style.border')
	// border = ' 2px solid red';
}
