import { Directive } from '@angular/core';

@Directive({
	selector: '[argument-template-actions]',
})
export class ArgumentTemplateActionsDirective {}

@Directive({
	selector: '[argument-template-description]',
})
export class ArgumentTemplateDescriptionDirective {}

@Directive({
	selector: '[argument-template-content]',
})
export class ArgumentTemplateContentDirective {}

@Directive({
	selector: '[argument-template-title]',
})
export class ArgumentTemplateTitleDirective {}
