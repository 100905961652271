import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { debounceTime, first, map, Observable, shareReplay } from 'rxjs';
import {
	initialOpenaiState,
	openAiModelConfigs,
	OpenAiModels,
	OpenAiSettings,
} from '../../../store/settings/settings-reducer';
import { selectSettingsOpenAi } from '../../../store/settings/settings-selectors';
import { settingsActions } from '../../../store/settings/settings-actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'dt-open-ai-settings',
	templateUrl: './open-ai-settings.component.html',
	styleUrls: ['./open-ai-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenAiSettingsComponent implements OnInit {
	openAiModelConfigs = openAiModelConfigs;
	private store = inject(Store);
	private fb = inject(FormBuilder);
	settings$: Observable<OpenAiSettings>;
	openAiGroup: FormGroup<{
		openAiApiKey: FormControl<string>;
		organization: FormControl<string>;
		defaultPrompt: FormControl<string>;
		defaultResponse: FormControl<string>;
		model: FormControl<OpenAiModels>;
	}>;
	modelConfig$;
	// endpointControl = new FormControl<OpenAiEndpoint>('chat');

	ngOnInit(): void {
		this.openAiGroup = this.fb.group({
			openAiApiKey: [''],
			organization: [''],
			defaultPrompt: [''],
			defaultResponse: [''],
			model: [undefined],
		});
		this.settings$ = this.store.select(selectSettingsOpenAi);
		this.modelConfig$ = this.openAiGroup.valueChanges.pipe(
			untilDestroyed(this),
			map((st) => this.openAiModelConfigs[st.model]),
			shareReplay(1)
		);
		this.modelConfig$.subscribe();
		// this.openAiGroup.valueChanges.subscribe(console.log);
		this.settings$.pipe(first()).subscribe((settings) => {
			this.openAiGroup.setValue(
				{
					...initialOpenaiState,
					...settings,
				},
				{ emitEvent: true, onlySelf: true }
			);
		});
		this.openAiGroup.valueChanges
			.pipe(untilDestroyed(this), debounceTime(2000))
			.subscribe((settings) =>
				this.store.dispatch(settingsActions.updateOpenAi({ settings }))
			);
	}

	clearDefaultResponse() {
		this.openAiGroup.get('defaultResponse').setValue('');
	}
}
