import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { AbstractStatementEditComponent } from '../shared/statement-template/abstract-statement-edit.component';
import { first, map, Observable } from 'rxjs';
import {
	StatementTestParameters,
	TestParameters,
} from '../../model/statements/statement-test-parameters';
import { StatementComponentStore } from '../shared/statement-template/statement-component-store';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';

class TestParametersStore extends StatementComponentStore<StatementTestParameters> {
	readonly updateParameters = this.updater(
		(state, parameters: Partial<TestParameters>) => ({
			...state,
			statement: {
				...state.statement,
				parameters: {
					...state.statement.parameters,
					...parameters,
				},
			},
		})
	);
}

@RegisterDynamicComponent({
	dataType: 'StatementTestParameters',
	title: 'TestParameters',
	tags: ['create', 'edit'],
})
@Component({
	selector: 'statement-test-parameters-edit',
	templateUrl: './statement-test-parameters-edit.component.html',
	styleUrls: ['./statement-test-parameters.component.scss'],
	providers: [
		{ provide: StatementComponentStore, useClass: TestParametersStore },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTestParametersEditComponent
	extends AbstractStatementEditComponent<
		StatementTestParameters,
		TestParametersStore
	>
	implements OnInit
{
	protected isValid$: Observable<boolean>;
	protected formGroup: FormGroup<{
		tpr: FormControl<number>;
		tnr: FormControl<number>;
		prevalence: FormControl<number>;
	}>;
	private formBuilder = inject(FormBuilder);

	override createStatement(): StatementTestParameters {
		return {
			type: 'StatementTestParameters',
			title: '',
			description: '',
			args: [],
			parameters: {
				tpr: 0.95,
				tnr: 0.9,
				prevalence: 0.01,
			},
		};
	}

	ngOnInit() {
		this.formGroup = this.formBuilder.nonNullable.group({
			tpr: [0, [Validators.max(1), Validators.min(0)]],
			tnr: [0, [Validators.max(1), Validators.min(0)]],
			prevalence: [0, [Validators.max(1), Validators.min(0)]],
		});
		this.isValid$ = this.statement$.pipe(
			map((st) => Boolean(st?.title || st?.description))
		);
		this.statement$
			.pipe(first())
			.subscribe((st) => this.formGroup.setValue(st.parameters));
		this.formGroup.valueChanges.subscribe((parameters) =>
			this.componentStore.updateParameters(parameters)
		);
	}
}
