import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Argument } from '../arguments/argument';

/**
 * <p>Usage example:
 * ```
 * matTooltip="{{argumentType | argDescr : 'all'}}"
 * ```
 * This will create a tooltip with the argument description.
 * </p>
 * <p>
 *   output:
 *   <ul>
 *     <li>description - get the argument description only</li>
 *     <li>title - get the argument title only</li>
 *     <li>all - get the argument title, id and description</li>
 *     <li>debug - get the argument id, premises and conclusion</li>
 *   </ul>
 * </p>
 */
@Pipe({
	name: 'argDescr',
})
export class ArgumentDescriptionPipe implements PipeTransform {
	transform(
		arg: string | Argument,
		output:
			| 'description'
			| 'title'
			| 'titleCasual'
			| 'all'
			| 'debug' = 'description'
	): string {
		const argType: string = typeof arg === 'string' ? arg : arg.type;
		const descr = ConfigService.instance.getArgumentConfig(argType);
		if (descr) {
			switch (output) {
				/*
						case 'descr':
						  return descr;
						case 'title':
						  return descr.title;
				*/
				case 'all':
					return `${descr.title} (${argType})\n${descr.description}`;
				case 'debug':
					if (typeof arg === 'string')
						throw new Error(
							'debug otput is applicable only to Argrument input'
						);
					return `${arg.id}(${argType})\n${arg['premise']} -> ${arg.conclusion}`;
				default:
					if (descr[output]) return descr[output];
					else
						throw new Error(
							`The argument description for ${argType} does not have property ${output}`
						);
			}
		} else {
			return argType;
		}
	}
}
