import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../services/local-storage-service';
import { Store } from '@ngrx/store';
import { selectSettingsAutoload } from '../../../store/settings/settings-selectors';

@Component({
	selector: 'dt-browse-localstorage',
	templateUrl: './browse-localstorage.component.html',
	styleUrls: ['./browse-localstorage.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseLocalstorageComponent {
	names$: Observable<string[]>;
	autoload$: Observable<string>;
	private readonly LOCAL_STORAGE_CACHE = 'debatt.saves.';
	private localStorageService = inject(LocalStorageService);
	private store = inject(Store);

	constructor() {
		this.names$ = this.localStorageService.getDebatts();
		this.autoload$ = this.store.select(selectSettingsAutoload);
	}

	ngOnInit(): void {
		this.localStorageService.updateCachedDebates();
	}

	loadFromCache(name: string) {
		this.localStorageService.loadFromCache(name);
	}

	disableAutoLoad() {
		this.localStorageService.disableAutoLoad();
	}

	/**
	 * Looks at {@link LOCAL_STORAGE_CACHE} items for saved debates
	 */
	updateCachedDebates() {
		this.localStorageService.updateCachedDebates();
	}

	deleteCachedDebatt(id: string) {
		this.localStorageService.deleteCachedDebatt(id);
	}

	onPin(name) {
		// name.stopPropagation();
		console.log(name);
		this.localStorageService.setAutoLoad(name);
	}
}
