import { ChangeDetectionStrategy, Component } from '@angular/core';

import { URLConst } from '../../core/routes';

@Component({
	selector: 'dt-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
	public URLConst = URLConst;
}
