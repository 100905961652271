import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFirestoreDocId } from '../firestore/firestore-selectors';
import { map } from 'rxjs';

import { URLConst } from '../../core/routes';

@Injectable({
	providedIn: 'root',
})
export class RedirectToFirestore implements CanActivate {
	private router = inject(Router);
	private store = inject(Store);

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.store.select(selectFirestoreDocId).pipe(
			map((docId) => {
				if (docId)
					return this.router.createUrlTree([
						URLConst.FIRESTORE_ROOT,
						docId,
					]);
				else return true;
			})
		);
	}
}
