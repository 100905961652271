/**
 * The information about users' opinions regarding this argument
 */
import { ConfigService } from '../../services/config.service';
import { OpinionsEnum } from '../descriptions/schema/opinion.configs.schema';

export class OpinionArgument {
	// private _votes: Map<string, number> = new Map<string, number>([['true', 2], ['invalid', 4]]);
	private _votes: Map<OpinionsEnum, number>;

	constructor(argType: string) {
		let opinions =
			ConfigService.instance.getArgumentConfig(argType)?.opinions;
		if (opinions === undefined) opinions = [];
		const array: [OpinionsEnum, number][] = opinions.map((id) => [
			id,
			Math.round(Math.random() * 10),
		]);
		this._votes = new Map<OpinionsEnum, number>(array);
		// this._votes = new Map<string, number>([['true', 2], ['invalid', 4]]);
	}

	public get votes(): Map<OpinionsEnum, number> {
		return this._votes;
	}
}
