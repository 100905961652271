import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, first, map, Observable } from 'rxjs';
import { ArgumentSimpleAttack } from '../../model/arguments/argument';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { ArgumentAbstractEditComponent } from '../argument-template/argument-abstract-edit.component';
import { ArgumentComponentStore } from '../argument-template/store/argument-component-store';
import { ArgumentSinglePremiseComponentStore } from '../argument-template/store/argument-single-premise-component-store';

@UntilDestroy()
@RegisterDynamicComponent({
	dataType: 'ArgumentSimpleAttack',
	title: 'Support',
	tags: ['edit', 'create', 'argument', 'attack'],
})
@Component({
	selector: 'debatt-argument-simple-attack-edit',
	templateUrl: 'argument-simple-attack-edit.component.html',
	styleUrls: ['./argument-simple-attack.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ArgumentComponentStore,
			useClass: ArgumentSinglePremiseComponentStore,
		},
	],
})
export class ArgumentSimpleAttackEditComponent
	extends ArgumentAbstractEditComponent<
		ArgumentSimpleAttack,
		ArgumentSinglePremiseComponentStore<ArgumentSimpleAttack>
	>
	implements OnInit
{
	isValid$: Observable<boolean>;
	initPremiseId$: Observable<string>;
	isAdvanced$ = new BehaviorSubject<boolean>(false);

	override createArgument(): ArgumentSimpleAttack {
		const argument: ArgumentSimpleAttack = {
			conclusion: this.create.conclusion,
			// title: 'Attack',
			// description: 'This attacks the thesis',
			type: 'ArgumentSimpleAttack',
			premise: undefined,
		};
		return argument;
	}

	ngOnInit() {
		this.componentStore.argument$
			.pipe(first())
			.subscribe((arg) =>
				this.isAdvanced$.next(Boolean(arg.title || arg.description))
			);
		this.initPremiseId$ = this.componentStore.initArgument$.pipe(
			first(),
			map((a) => a.premise)
		);
		this.isValid$ = this.componentStore.premiseStatement$.pipe(
			map((s) => !!s)
		);
	}

	setPremise(premiseSt) {
		this.componentStore.updatePremiseStatement(premiseSt);
	}

	onAdvanced() {
		this.isAdvanced$.next(true);
	}
}
