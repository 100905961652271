import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of, skip, switchMap, tap } from 'rxjs';
import { settingsActions } from './settings-actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Config } from '../../config/config';
import { selectSettingsState } from './settings-selectors';

@Injectable()
export class SettingsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private snackBar = inject(MatSnackBar);

	loadSettings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(settingsActions.loadFromLocalStorage),
			switchMap(() => {
				const settingsStorage = localStorage.getItem(
					Config.LOCAL_STORAGE_SETTINGS
				);
				const settings = JSON.parse(settingsStorage);
				if (settingsStorage)
					return of(
						settingsActions.loadFromLocalStorageCompleted({
							settings,
						})
					);
				else return EMPTY;
				/*
						else
							return settingsActions.loadFromLocalStorageFailed({
								message: `Failed to parse ${settingsStorage}`
							});
*/
			})
		);
	});

	saveToLocalStorage$ = createEffect(
		() => {
			return () => {
				return this.store.select(selectSettingsState).pipe(
					skip(1),
					switchMap((settings) => {
						try {
							localStorage.setItem(
								Config.LOCAL_STORAGE_SETTINGS,
								JSON.stringify(settings)
							);
							return EMPTY;
						} catch (e) {
							return of(
								settingsActions.saveToLocalStorageFailed(
									e.message
								)
							);
						}
					})
				);
			};
		},
		{ dispatch: false }
	);

	saveLoadFailed$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(
					settingsActions.saveToLocalStorageFailed,
					settingsActions.loadFromLocalStorageFailed
				),
				tap(({ message }) => {
					this.snackBar.open(message, undefined, { duration: 3000 });
				})
			);
		},
		{ dispatch: false }
	);
}
