import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RegisterDynamicComponent } from '../../services/component-registry';
import { AbstractStatementEditComponent } from '../shared/statement-template/abstract-statement-edit.component';
import { map, Observable } from 'rxjs';
import { StatementDefault } from '../../model/statements/statement-default';
import { StatementComponentStore } from '../shared/statement-template/statement-component-store';

@RegisterDynamicComponent({
	dataType: 'StatementDefault',
	title: 'Statement',
	tags: ['create', 'edit'],
})
@Component({
	selector: 'statement-default-edit',
	templateUrl: './statement-default.edit.component.html',
	styleUrls: ['./statement-default.component.scss'],
	providers: [StatementComponentStore],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementDefaultEditComponent
	extends AbstractStatementEditComponent<StatementDefault>
	implements OnInit
{
	protected isValid$: Observable<boolean>;

	override createStatement(): StatementDefault {
		return {
			type: 'StatementDefault',
			title: '',
			description: '',
			args: [],
		};
	}

	ngOnInit() {
		this.isValid$ = this.statement$.pipe(
			map((st) => Boolean(st?.title || st?.description))
		);
	}
}
