import { Pipe, PipeTransform } from '@angular/core';
import { Argument } from '../arguments/argument';

@Pipe({
	name: 'isConclusionFor',
})
export class IsConclusionForPipe implements PipeTransform {
	//TODO: will need a proper logic for getting list of premises
	transform(
		argument: Argument,
		conclusion: string,
		premise?: string
	): unknown {
		return (
			argument.conclusion === conclusion &&
			premise &&
			(argument['premise'] === premise ||
				argument['premises']?.includes(premise))
		);
	}
}
