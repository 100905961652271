import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRootStatementId } from '../../store/statement/statement-selectors';
import { ArgumentNodeRegistryService } from '../../services/argument-node-registry.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'dt-debate-map',
	templateUrl: './debate-map.component.html',
	styleUrls: ['./debate-map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebateMapComponent implements OnInit {
	private store = inject(Store);
	private argumentNodeRegistryService = inject(ArgumentNodeRegistryService);
	protected rootNode$: Observable<Node>;
	protected rootId$: Observable<string>;
	ngOnInit() {
		this.rootId$ = this.store.select(selectRootStatementId);
	}
}
