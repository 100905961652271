import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { Argument } from '../../model/arguments/argument';
import { Tag } from '../../services/component-registry';
import { selectArgument } from '../../store/argument/argument-selectors';
import { CreateArgumentConfig } from '../../services/argument-node-registry.service';
import { URLConst } from '../../core/routes';

/**
 * Page
 */
@UntilDestroy()
@Component({
	selector: 'debatt-edit-argument',
	templateUrl: './edit-argument.component.html',
	styleUrls: ['./edit-argument.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditArgumentComponent implements OnInit {
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private store = inject(Store);

	argument$: Observable<Argument>;
	argumentComponentMode$: Observable<Tag>;
	create$: Observable<CreateArgumentConfig>;
	conclusion$: Observable<string>;

	ngOnInit(): void {
		this.argumentComponentMode$ = this.route.url.pipe(
			untilDestroyed(this),
			switchMap((url) =>
				url[0].path === URLConst.CREATE_ARGUMENT
					? of('create' as Tag)
					: of('edit' as Tag)
			)
		);
		this.argument$ = this.route.paramMap.pipe(
			untilDestroyed(this),
			switchMap((params) => {
				const id = params.get(URLConst.EDIT_ARGUMENT_ID);
				return id
					? this.store.select(selectArgument(id))
					: of(undefined);
			})
		);
		this.create$ = this.route.paramMap.pipe(
			untilDestroyed(this),
			switchMap((params) =>
				of({
					type: params.get(URLConst.CREATE_ARGUMENT_ARGUMENT_TYPE),
					conclusion: params.get(
						URLConst.CREATE_ARGUMENT_STATEMENT_ID
					),
				})
			)
		);
		this.conclusion$ = this.argument$.pipe(
			switchMap((arg) =>
				arg
					? of(arg.conclusion)
					: this.create$.pipe(switchMap((cr) => of(cr.conclusion)))
			)
		);
	}

	/*
		createArgumentStub(conclusion: string, type: string): Observable<Argument> {
			return of({conclusion, type} as Argument);
		}
	*/
}
