import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, first, mergeMap, of, switchMap } from 'rxjs';
import { Utils } from '../../utils/utils';
import { argumentActions } from './argument-actions';
import { statementActions } from '../statement/statement-actions';

@Injectable()
export class ArgumentEffects {
	private actions$ = inject(Actions);

	add$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(argumentActions.add),
			mergeMap(({ argument, newPremises, updateFn, actionId }) => {
				if (newPremises && newPremises.size > 0 /* && actionId*/) {
					if (!actionId)
						throw new Error(
							'Must provide actionId when providing newPremises'
						);
					return of(
						statementActions.addMany({
							statements: newPremises,
							actionId,
						})
					);
				} else
					return of(
						argumentActions.addSuccess({
							argument: { ...argument, id: Utils.generateId() }, //add backend save here
							actionId,
						})
					);
			})
		);
	});

	/** listens for argumentActions.add and corresponding statementActions.addManySuccess
	 */
	addNewPremisesSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(argumentActions.add),
			filter(({ newPremises, actionId }) =>
				Boolean(newPremises && newPremises.size > 0 && actionId)
			),
			mergeMap((argAction) =>
				this.actions$.pipe(
					ofType(statementActions.addManySuccess),
					filter(({ actionId }) => actionId == argAction.actionId),
					first(),
					switchMap(({ statements, actionId }) =>
						of(
							argumentActions.addSuccess({
								argument: {
									...argAction.updateFn(
										argAction.argument,
										statements
									),
									id: Utils.generateId(), //add backend save here
								},
								actionId,
							})
						)
					)
				)
			)
		);
	});

	update$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(argumentActions.update),
			mergeMap(({ argument, newPremises, updateFn, actionId }) => {
				if (newPremises && newPremises.size > 0 /* && actionId*/) {
					if (!actionId)
						throw new Error(
							'Must provide actionId when providing newPremises'
						);
					return of(
						statementActions.addMany({
							statements: newPremises,
							actionId,
						})
					);
				} else
					return of(
						argumentActions.updateSuccess({
							argument, //add backend save here
							actionId,
						})
					);
			})
		);
	});

	/** listens for argumentActions.update and corresponding statementActions.addManySuccess
	 */
	updateAddNewPremisesSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(argumentActions.update),
			filter(({ newPremises, actionId }) =>
				Boolean(newPremises && newPremises.size > 0 && actionId)
			),
			mergeMap((argAction) =>
				this.actions$.pipe(
					ofType(statementActions.addManySuccess),
					filter(({ actionId }) => actionId == argAction.actionId),
					first(),
					switchMap(({ statements, actionId }) =>
						of(
							argumentActions.updateSuccess({
								argument: argAction.updateFn(
									argAction.argument,
									statements
								),
								actionId,
							})
						)
					)
				)
			)
		);
	});
}
