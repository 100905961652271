import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FirestoreState } from './firestore-reducer';
import { Features } from '../shared/reducer';

export const selectFirestoreState = createFeatureSelector<FirestoreState>(
	Features.firestore
);

export const selectFirestoreDocId = createSelector(
	selectFirestoreState,
	({ id }) => id
);

export const selectFirestoreDocIsActive = createSelector(
	selectFirestoreState,
	({ id }) => !!id
);
export const selectFirestoreLoading = createSelector(
	selectFirestoreState,
	({ loading }) => loading
);
export const selectFirestoreTitle = createSelector(
	selectFirestoreState,
	({ title }) => title
);
export const selectFirestoreDescription = createSelector(
	selectFirestoreState,
	({ description }) => description
);
