import {
	inject,
	Injectable,
	Injector,
	Type,
	TypeDecorator,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Configuration, OpenAIApi } from 'openai';
import { selectSettingsOpenAi } from '../../store/settings/settings-selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, ReplaySubject } from 'rxjs';
import {
	openAiModelConfigs,
	OpenAiSettings,
} from '../../store/settings/settings-reducer';
import { Statement } from '../../model/statements/statement';
import { Actions } from '@ngrx/effects';
import { openaiActions } from '../../store/openai/openai-actions';
import { OpenaiPrompt, prompts } from './openai-prompts';
import { OpenAiParser } from './openai-parsers';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class OpenaiService {
	private readonly MAX_TOKENS = 500;
	private readonly MAX_WORDS = 50;
	private store = inject(Store);
	private actions = inject(Actions);
	private openAIApi?: OpenAIApi;
	private configuration?: Configuration;
	private settings?: OpenAiSettings;
	static readonly parsers$ = new ReplaySubject<
		Type<OpenAiParser<Statement>>
	>();
	readonly parsers: OpenAiParser<Statement>[] = [];
	private injector = inject(Injector);

	constructor() {
		this.store
			.select(selectSettingsOpenAi)
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe((settings) => {
				this.settings = settings;
				this.openAIApi = undefined;
			});
		OpenaiService.parsers$.subscribe((parser) => {
			const parserInjector = Injector.create({
				providers: [{ provide: parser }],
				parent: this.injector,
			});
			console.log(`Registering OpenAI parser ${parser.name}`);
			this.parsers.push(parserInjector.get(parser));
		});
	}

	async crateArguments(st: Statement, promptId = '3pro3con') {
		this.store.dispatch(openaiActions.loadFromOpenai());
		const prompt = `${st.description}  ${st.title}`;
		const response = await this.query(prompt, promptId);
		const items = response
			.split('\n\n')
			.map((s) => s.trim())
			.filter(Boolean);
		console.log(items);
		if (items.length === 0) {
			this.store.dispatch(openaiActions.loadFromOpenaiCompleted());
			// TODO: replace with an action that pops up normal MatSnackBar
			throw new Error(
				`OpenAI cannot find arguments for the statement ${prompt}`
			);
		}

		for (const item of items) {
			this.parsers.forEach((parser) => {
				if (item.match(parser.matcher)) parser.parse(item, st);
			});
		}
		this.store.dispatch(openaiActions.loadFromOpenaiCompleted());
	}

	getApi() {
		if (this.openAIApi) return this.openAIApi;
		else if (this.settings?.openAiApiKey && this.settings?.organization) {
			const configuration = new Configuration({
				apiKey: this.settings.openAiApiKey,
				organization: this.settings.organization,
				baseOptions: {},
			});
			this.openAIApi = new OpenAIApi(configuration);
			return this.openAIApi;
		} else return undefined;
	}

	query(statement: string, promptId?: string) {
		const promptConfig: OpenaiPrompt = promptId
			? prompts[promptId]
			: {
					...prompts['3pro3con'],
			  };
		if (promptConfig.model) {
			switch (openAiModelConfigs[promptConfig.model]?.endpoint) {
				case 'completion':
					return this.createCompletion(statement);
				case 'chat':
					return this.createChatCompletion(
						...promptConfig.instructions,
						`"""${statement}"""`
					);
				default:
					throw new Error(
						`${
							openAiModelConfigs[promptConfig.model]?.endpoint
						} endpoint is not supported`
					);
			}
		} else {
			throw new Error('No OpenAI model selected');
		}
	}
	async createCompletion(prompt: string) {
		if (this.settings?.defaultResponse)
			return this.settings?.defaultResponse;
		const api = this.getApi();
		if (api) {
			const response = await api.createCompletion({
				model: this.settings.model,
				prompt,
				max_tokens: this.MAX_TOKENS,
				temperature: 0,
			});
			return response.data.choices[0].text;
		} else throw new Error('OpenAI API is not ready.');
	}

	async createChatCompletion(...prompts: string[]): Promise<string> {
		if (this.settings?.defaultResponse)
			return this.settings?.defaultResponse;
		const api = this.getApi();
		if (api) {
			const response = await api.createChatCompletion({
				model: this.settings.model,
				messages: prompts.map((content) => ({ role: 'user', content })),
				max_tokens: this.MAX_TOKENS,
				temperature: 0,
			});
			return response.data.choices[0].message.content;
		} else throw new Error('OpenAI API is not ready.');
	}
}

/**Decorator to register parsers
 * <p>Applies @Injectable</p>
 */
export function RegisterOpenaiParser(): TypeDecorator {
	return (parser: Type<OpenAiParser<Statement>>) => {
		OpenaiService.parsers$.next(parser);
		return Injectable()(parser);
	};
}
